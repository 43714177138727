import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ARTICLE_PROGRESS_HISTORY = 'getArticleProgressHistory'; //
export const GET_ARTICLE_PROGRESS_HISTORIES = 'getArticleProgressHistories'; //
export const STORE_ARTICLE_PROGRESS_HISTORY = 'storeArticleProgressHistory'; //
export const UPDATE_ARTICLE_PROGRESS_HISTORY = 'updateArticleProgressHistory'; //
// [COPY MUTATIONS]
export const SET_ARTICLE_PROGRESS_HISTORY = 'setArticleProgressHistory'; //
export const SET_ARTICLE_PROGRESS_HISTORIES = 'setArticleProgressHistories'; //
export const SET_ARTICLE_PROGRESS_HISTORIES_PAGE = 'setArticleProgressHistoriesPage'; //
export const SET_ARTICLE_PROGRESS_HISTORY_ERROR = 'setArticleProgressHistoryError'; //

const state = {
  articleProgressHistory: {},
  articleProgressHistories: [],
  articleProgressHistoriesMeta: {},
  articleProgressHistoriesPage: 1,
  articleProgressHistoryError: null
};

const mutations = {
  [SET_ARTICLE_PROGRESS_HISTORY_ERROR](state, error){
    state.articleProgressHistoryError = error;
  },
  [SET_ARTICLE_PROGRESS_HISTORY](state, payload){
    state.articleProgressHistory = payload;
  },
  [SET_ARTICLE_PROGRESS_HISTORIES](state, payload){
    state.articleProgressHistories = payload.data;
    state.articleProgressHistoriesMeta = payload.meta;
  },
  [SET_ARTICLE_PROGRESS_HISTORIES_PAGE](state, payload){
    state.articleProgressHistoriesPage = payload;
  },
};

const actions = {
  [GET_ARTICLE_PROGRESS_HISTORY](context, id){
    return GetAction("manager/article-progresses", `${id}`, context, SET_ARTICLE_PROGRESS_HISTORY_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_PROGRESS_HISTORY, response.data );
    }});
  },
  [STORE_ARTICLE_PROGRESS_HISTORY](context, { article_id, formData }){
    return PostAction(`manager/${article_id}/article-progresses`, formData, context, SET_ARTICLE_PROGRESS_HISTORY_ERROR, { credentials: true } )
  },
  [UPDATE_ARTICLE_PROGRESS_HISTORY](context, { id, formData }){
    return PutAction(`manager/article-progresses/${id}`, formData, context, SET_ARTICLE_PROGRESS_HISTORY_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_PROGRESS_HISTORY, response.data );
    }});
  },
  [GET_ARTICLE_PROGRESS_HISTORIES](context, { article_id, page = 1 } ){
    return GetAction( `manager/${article_id}/article-progresses?page=${page}`,
    ``,
    context,
    SET_ARTICLE_PROGRESS_HISTORY_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_PROGRESS_HISTORIES, response.data );
        context.commit( SET_ARTICLE_PROGRESS_HISTORIES_PAGE, page );
      }
    } );
  },
};

const getters = {
  articleProgressHistory: (state)=> state.articleProgressHistory,
  articleProgressHistories: (state)=> state.articleProgressHistories,
  articleProgressHistoriesMeta: (state)=> state.articleProgressHistoriesMeta,
  articleProgressHistoriesPage: (state)=> state.articleProgressHistoriesPage,
  articleProgressHistoryError: (state)=> state.articleProgressHistoryError,
};

export default {
  state,
  mutations,
  actions,
  getters
}
