export const asset = (src)=>{
  return `${process.env.VUE_APP_API_BASE_URL}/storage/${src}`
}

export const numConvert = (num) => {
  if (num >= 1000000) {
    num = Math.round(num / 100000) / 10 + 'M';
  } else if (num >= 10000) {
    num = Math.round(num / 1000) / 10 + 'W';
  } else if (num >= 1000) {
    num = Math.round(num / 100) / 10 + 'K';
  }
  return num;
}

function createMessage( { message, prefix = null }) {
  return `%c [${prefix ? prefix : 'DEBUG LOG'}] %c ${message} %c`;
}

function createSprint(logType = 'log') {
  switch ( logType ) {
    case "log":
      return ["background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff", "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff", "background:transparent"];
    case "warn":
      return ["background:#f37b1d ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff", "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff", "background:transparent"];
    case "error":
      return ["background:#fbbd08 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff", "background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff", "background:transparent"];
  }
}

export const debugConsole = ( { message, ignoreDebug = false, logType = 'log', prefix = '' })=>{
  if ( ignoreDebug || process.env.VUE_APP_DEBUG ){
    switch ( logType ) {
      case "log":
        console.log(createMessage( { message, prefix } ), ...createSprint(logType));
        break;
      case "warn":
        console.warn(createMessage( { message, prefix } ), ...createSprint(logType));
        break;
      case "error":
        console.error(createMessage( { message, prefix } ), ...createSprint(logType));
        break;
    }
  }
}

export const idleTime = (hours = 1)=> {
  let microSeconds = 1000;
  let oneMinuteSeconds = 60;
  let oneHourMinute = 60;
  return (microSeconds * oneMinuteSeconds) * (oneHourMinute * hours);
};

const sprintfWrapper = {
  init : function () {
    if (typeof arguments == "undefined") { console.log(1); return null; }

    if (arguments.length < 1) { console.log(2); return null; }

    if (typeof arguments[0] != "string") { console.log(3); return null; }

    if (typeof RegExp == "undefined") { console.log(4); return null; }

    var string = arguments[0];

    var exp = new RegExp(/(%([%]|(\-)?(\+|\x20)?(0)?(\d+)?(\.(\d)?)?([bcdfosxX])))/g);

    var matches = new Array();

    var strings = new Array();

    var convCount = 0;

    var stringPosStart = 0;

    var stringPosEnd = 0;

    var matchPosEnd = 0;

    var newString = '';

    var match = null;
    var substitution;

    while (match = exp.exec(string)) {

      if (match[9]) { convCount += 1; }
      stringPosStart = matchPosEnd;

      stringPosEnd = exp.lastIndex - match[0].length;

      strings[strings.length] = string.substring(stringPosStart, stringPosEnd);
      matchPosEnd = exp.lastIndex;

      matches[matches.length] = {

        match: match[0],

        left: match[3] ? true : false,

        sign: match[4] || '',

        pad: match[5] || ' ',

        min: match[6] || 0,

        precision: match[8],

        code: match[9] || '%',

        negative: parseInt(arguments[convCount]) < 0 ? true : false,

        argument: String(arguments[convCount])

      };

    }

    strings[strings.length] = string.substring(matchPosEnd);
    if (matches.length == 0) { return string; }

    if ((arguments.length - 1) < convCount) { return null; }
    var code = null;

    var match = null;

    var i = null;
    for (i=0; i<matches.length; i++) {
      if (matches[i].code == '%') { substitution = '%' }

      else if (matches[i].code == 'b') {

        matches[i].argument = String(Math.abs(parseInt(matches[i].argument)).toString(2));

        substitution = sprintfWrapper.convert(matches[i], true);

      }

      else if (matches[i].code == 'c') {

        matches[i].argument = String(String.fromCharCode(parseInt(Math.abs(parseInt(matches[i].argument)))));

        substitution = sprintfWrapper.convert(matches[i], true);

      }

      else if (matches[i].code == 'd') {

        matches[i].argument = String(Math.abs(parseInt(matches[i].argument)));

        substitution = sprintfWrapper.convert(matches[i]);

      }

      else if (matches[i].code == 'f') {

        matches[i].argument = String(Math.abs(parseFloat(matches[i].argument)).toFixed(matches[i].precision ? matches[i].precision : 6));

        substitution = sprintfWrapper.convert(matches[i]);

      }

      else if (matches[i].code == 'o') {

        matches[i].argument = String(Math.abs(parseInt(matches[i].argument)).toString(8));

        substitution = sprintfWrapper.convert(matches[i]);

      }

      else if (matches[i].code == 's') {

        matches[i].argument = matches[i].argument.substring(0, matches[i].precision ? matches[i].precision : matches[i].argument.length)

        substitution = sprintfWrapper.convert(matches[i], true);

      }

      else if (matches[i].code == 'x') {

        matches[i].argument = String(Math.abs(parseInt(matches[i].argument)).toString(16));

        substitution = sprintfWrapper.convert(matches[i]);

      }

      else if (matches[i].code == 'X') {

        matches[i].argument = String(Math.abs(parseInt(matches[i].argument)).toString(16));

        substitution = sprintfWrapper.convert(matches[i]).toUpperCase();

      }

      else {

        substitution = matches[i].match;

      }
      newString += strings[i];

      newString += substitution;
    }

    newString += strings[i];
    return newString;
  },
  convert : function(match, nosign){

    if (nosign) {

      match.sign = '';

    } else {

      match.sign = match.negative ? '-' : match.sign;

    }

    var l = match.min - match.argument.length + 1 - match.sign.length;

    var pad = new Array(l < 0 ? 0 : l).join(match.pad);

    if (!match.left) {

      if (match.pad == "0" || nosign) {

        return match.sign + pad + match.argument;

      } else {

        return pad + match.sign + match.argument;

      }

    } else {

      if (match.pad == "0" || nosign) {

        return match.sign + match.argument + pad.replace(/0/g, ' ');

      } else {

        return match.sign + match.argument + pad;

      }

    }

  }
}
export const sprintf = sprintfWrapper.init;

export const calculateImageSize = (result, max_width = 1366, max_height = 1024)=> {
  //限制最大尺寸
  return new Promise((resolve, reject)=>{
    //图片实际尺寸
    let width = result.width;
    let height = result.height;
    let rate = width / height;
    debugConsole({prefix: 'RATE', message: rate});
    let response;
    if (width > height) {
      //横图
      if (width <= max_width) {
        //无需限制宽度
        response = {
          "width": width,
          "height": height
        };
        debugConsole({prefix: 'RESPONSE 1', message: JSON.stringify(response)});
        resolve(response);
      } else {
        // 限制宽度
        response = {
          "width": width,
          "height": width / rate
        };
        debugConsole({prefix: 'RESPONSE 2', message: JSON.stringify(response)});
        reject(response);
      }
    } else {
      //竖图
      if (height <= max_height) {
        //不限制高度
        response = {
          "width": width,
          "height": height
        };
        debugConsole({prefix: 'RESPONSE 3', message: JSON.stringify(response)});
        resolve(response);
      } else {
        //限制高度
        response = {
          "width": height * rate,
          "height": height
        };
        debugConsole({prefix: 'RESPONSE 4', message: JSON.stringify(response)});
        reject(response);
      }
    }
    reject();
  })
}

export const getImageInfo = (file, {handler = false})=>{
  return new Promise(resolve => {
    let url = window.URL || window.webkitURL;
    let img = new Image();              //手动创建一个Image对象
    img.src = url.createObjectURL(file);//创建Image的对象的url
    img.onload = function () {
      let result = { width: this.width, height: this.height };
      debugConsole({prefix: 'ImageInfo', message: JSON.stringify(result)});
      typeof handler === 'function' && handler(result);
      return resolve(result);
    }
  })
}

export const unique = (arr)=>{
  let newObj = {}
  let newArr = []
  arr.forEach(item => {
    if ( typeof item !== 'object' ) {
      // NaN是唯一一个不等于任何自身的类型
      if ( item !== item ) {
        if ( !newObj[item.toString()] ) {
          newArr.push( item )
          newObj[item.toString()] = true
        }
      } else {
        if ( newArr.indexOf( item ) === -1 ) {
          newArr.push( item )
        }
      }
    } else {
      let str = JSON.stringify( item )
      if ( !newObj[str] ) {
        newArr.push( item )
        newObj[str] = true
      }
    }
  });
  return newArr
}

export const randomString = (len = 32)=>{
  let randomStr = ()=>{
    const str = 'abcdefjhijklmnopqrstuvwxyz23456789ABCDEFJHIJKLMNOPQRSTUVWXYZ';
    let index = Math.floor(Math.random() * (str.length - 1));
    return str[index];
  }
  let key = '';
  while (key.length < len){
    key += randomStr();
  }
  return key;
}
