import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ARTICLE_WITNESS = 'getArticleWitness'; //
export const GET_ARTICLE_WITNESSES = 'getArticleWitnesses'; //
export const STORE_ARTICLE_WITNESS = 'storeArticleWitness'; //
export const UPDATE_ARTICLE_WITNESS = 'updateArticleWitness'; //
// [COPY MUTATIONS]
export const SET_ARTICLE_WITNESS = 'setArticleWitness'; //
export const SET_ARTICLE_WITNESSES = 'setArticleWitnesses'; //
export const SET_ARTICLE_WITNESSES_PAGE = 'setArticleWitnessesPage'; //
export const SET_ARTICLE_WITNESS_ERROR = 'setArticleWitnessError'; //

const state = {
  articleWitness: {},
  articleWitnesses: [],
  articleWitnessesMeta: {},
  articleWitnessesPage: 1,
  articleWitnessError: null
};

const mutations = {
  [SET_ARTICLE_WITNESS_ERROR](state, error){
    state.articleWitnessError = error;
  },
  [SET_ARTICLE_WITNESS](state, payload){
    state.articleWitness = payload;
  },
  [SET_ARTICLE_WITNESSES](state, payload){
    state.articleWitnesses = payload.data;
    state.articleWitnessesMeta = payload.meta;
  },
  [SET_ARTICLE_WITNESSES_PAGE](state, payload){
    state.articleWitnessesPage = payload;
  },
};

const actions = {
  [GET_ARTICLE_WITNESS](context, id){
    return GetAction("manager/witnesses", `${id}`, context, SET_ARTICLE_WITNESS_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_WITNESS, response.data );
    }});
  },
  [STORE_ARTICLE_WITNESS](context, { article_id, formData }){
    return PostAction(`manager/${article_id}/witnesses`, formData, context, SET_ARTICLE_WITNESS_ERROR, { credentials: true } )
  },
  [UPDATE_ARTICLE_WITNESS](context, { id, formData }){
    return PutAction(`manager/witnesses/${id}`, formData, context, SET_ARTICLE_WITNESS_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_WITNESS, response.data );
    }});
  },
  [GET_ARTICLE_WITNESSES](context, { article_id, page = 1 } ){
    return GetAction( `manager/${article_id}/witnesses?page=${page}`,
    ``,
    context,
    SET_ARTICLE_WITNESS_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_WITNESSES, response.data );
        context.commit( SET_ARTICLE_WITNESSES_PAGE, page );
      }
    } );
  },
};

const getters = {
  articleWitness: (state)=> state.articleWitness,
  articleWitnesses: (state)=> state.articleWitnesses,
  articleWitnessesMeta: (state)=> state.articleWitnessesMeta,
  articleWitnessesPage: (state)=> state.articleWitnessesPage,
  articleWitnessError: (state)=> state.articleWitnessError,
};

export default {
  state,
  mutations,
  actions,
  getters
}
