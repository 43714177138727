import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_MERCHANT = 'getMerchant'; //
export const GET_MERCHANTS = 'getMerchants'; //
export const GET_MERCHANTS_LIST = 'getMerchantsList'; //
export const STORE_MERCHANT = 'storeMerchant'; //
export const UPDATE_MERCHANT = 'updateMerchant'; //
// [COPY MUTATIONS]
export const SET_MERCHANT = 'setMerchant'; //
export const SET_MERCHANTS = 'setMerchants'; //
export const SET_MERCHANTS_LIST = 'setMerchantsList'; //
export const SET_MERCHANTS_PAGE = 'setMerchantsPage'; //
export const SET_MERCHANT_ERROR = 'setMerchantError'; //

const state = {
  merchant: {},
  merchants: [],
  merchantsList: [],
  merchantsMeta: {},
  merchantsPage: 1,
  merchantError: null
};

const mutations = {
  [SET_MERCHANT_ERROR](state, error){
    state.merchantError = error;
  },
  [SET_MERCHANT](state, payload){
    state.merchant = payload;
  },
  [SET_MERCHANTS](state, payload){
    state.merchants = payload.data;
    state.merchantsMeta = payload.meta;
  },
  [SET_MERCHANTS_LIST](state, payload){
    state.merchantsList = payload;
  },
  [SET_MERCHANTS_PAGE](state, payload){
    state.merchantsPage = payload;
  }
};

const actions = {
  [GET_MERCHANT](context, id){
    return GetAction("manager/merchants", `${id}`, context, SET_MERCHANT_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_MERCHANT, response.data );
    }});
  },
  [STORE_MERCHANT](context, formData){
    return PostAction(`manager/merchants`, formData, context, SET_MERCHANT_ERROR, { credentials: true } )
  },
  [UPDATE_MERCHANT](context, { id, formData }){
    return PutAction(`manager/merchants/${id}`, formData, context, SET_MERCHANT_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_MERCHANT, response.data );
    }});
  },
  [GET_MERCHANTS](context, page= 1 ){
    return GetAction( `manager/merchants?page=${page}`,
    ``,
    context,
    SET_MERCHANT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_MERCHANTS, response.data );
        context.commit( SET_MERCHANTS_PAGE, page );
      }
    } );
  },
  [GET_MERCHANTS_LIST](context){
    return GetAction( `manager/merchants/list`,
    ``,
    context,
    SET_MERCHANT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_MERCHANTS_LIST, response.data );
      }
    } );
  },
};

const getters = {
  merchant: (state)=> state.merchant,
  merchants: (state)=> state.merchants,
  merchantsList: (state)=> state.merchantsList,
  merchantsMeta: (state)=> state.merchantsMeta,
  merchantsPage: (state)=> state.merchantsPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
