import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_HOSPITAL = 'getHospital'; //
export const GET_HOSPITALS = 'getHospitals'; //
export const STORE_HOSPITAL = 'storeHospital'; //
export const UPDATE_HOSPITAL = 'updateHospital'; //
// [COPY MUTATIONS]
export const SET_HOSPITAL = 'setHospital'; //
export const SET_HOSPITALS = 'setHospitals'; //
export const SET_HOSPITALS_PAGE = 'setHospitalsPage'; //
export const SET_HOSPITAL_ERROR = 'setHospitalError'; //

const state = {
  hospital: {},
  hospitals: [],
  hospitalsMeta: {},
  hospitalsPage: 1,
  error: null
};

const mutations = {
  [SET_HOSPITAL_ERROR](state, error){
    state.error = error;
  },
  [SET_HOSPITAL](state, payload){
    state.hospital = payload;
  },
  [SET_HOSPITALS](state, payload){
    state.hospitals = payload.data;
    state.hospitalsMeta = payload.meta;
  },
  [SET_HOSPITALS_PAGE](state, payload){
    state.hospitalsPage = payload;
  }
};

const actions = {
  [GET_HOSPITAL](context, id){
    return GetAction("manager/hospitals", `${id}`, context, SET_HOSPITAL_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOSPITAL, response.data );
    }});
  },
  [STORE_HOSPITAL](context, formData){
    return PostAction(`manager/hospitals`, formData, context, SET_HOSPITAL_ERROR, { credentials: true } )
  },
  [UPDATE_HOSPITAL](context, { id, formData }){
    return PutAction(`manager/hospitals/${id}`, formData, context, SET_HOSPITAL_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOSPITAL, response.data );
    }});
  },
  [GET_HOSPITALS](context, page= 1 ){
    return GetAction( `manager/hospitals?page=${page}`,
    ``,
    context,
    SET_HOSPITAL_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOSPITALS, response.data );
        context.commit( SET_HOSPITALS_PAGE, page );
      }
    } );
  }
};

const getters = {
  hospital: (state)=> state.hospital,
  hospitals: (state)=> state.hospitals,
  hospitalsMeta: (state)=> state.hospitalsMeta,
  hospitalsPage: (state)=> state.hospitalsPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
