import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_REGULATOR = 'getRegulator'; //
export const GET_REGULATORS = 'getRegulators'; //
export const STORE_REGULATOR = 'storeRegulator'; //
export const UPDATE_REGULATOR = 'updateRegulator'; //
// [COPY MUTATIONS]
export const SET_REGULATOR = 'setRegulator'; //
export const SET_REGULATORS = 'setRegulators'; //
export const SET_REGULATORS_PAGE = 'setRegulatorsPage'; //
export const SET_REGULATOR_ERROR = 'setRegulatorError'; //

const state = {
  regulator: {},
  regulators: [],
  regulatorsMeta: {},
  regulatorsPage: 1,
  error: null
};

const mutations = {
  [SET_REGULATOR_ERROR](state, error){
    state.error = error;
  },
  [SET_REGULATOR](state, payload){
    state.regulator = payload;
  },
  [SET_REGULATORS](state, payload){
    state.regulators = payload.data;
    state.regulatorsMeta = payload.meta;
  },
  [SET_REGULATORS_PAGE](state, payload){
    state.regulatorsPage = payload;
  }
};

const actions = {
  [GET_REGULATOR](context, id){
    return GetAction("manager/regulators", `${id}`, context, SET_REGULATOR_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_REGULATOR, response.data );
    }});
  },
  [STORE_REGULATOR](context, formData){
    return PostAction(`manager/regulators`, formData, context, SET_REGULATOR_ERROR, { credentials: true } )
  },
  [UPDATE_REGULATOR](context, { id, formData }){
    return PutAction(`manager/regulators/${id}`, formData, context, SET_REGULATOR_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_REGULATOR, response.data );
    }});
  },
  [GET_REGULATORS](context, page= 1 ){
    return GetAction( `manager/regulators?page=${page}`,
    ``,
    context,
    SET_REGULATOR_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_REGULATORS, response.data );
        context.commit( SET_REGULATORS_PAGE, page );
      }
    } );
  }
};

const getters = {
  regulator: (state)=> state.regulator,
  regulators: (state)=> state.regulators,
  regulatorsMeta: (state)=> state.regulatorsMeta,
  regulatorsPage: (state)=> state.regulatorsPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
