import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as zhUG } from "@/common/config/i18n/ug";
import { locale as zhCN } from "@/common/config/i18n/cn";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, 'zh-UG': zhUG, 'zh-CN': zhCN };

// get current selected language
const lang = localStorage.getItem("language") || "zh-UG";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
  fallbackLocale: 'zh-UG', // set fallback locale
});

export default i18n;
