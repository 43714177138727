import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_PROJECT_LEADER = 'getProjectLeader'; //
export const GET_PROJECT_LEADERS = 'getProjectLeaders'; //
export const STORE_PROJECT_LEADER = 'storeProjectLeader'; //
export const UPDATE_PROJECT_LEADER = 'updateProjectLeader'; //
// [COPY MUTATIONS]
export const SET_PROJECT_LEADER = 'setProjectLeader'; //
export const SET_PROJECT_LEADERS = 'setProjectLeaders'; //
export const SET_PROJECT_LEADERS_PAGE = 'setProjectLeadersPage'; //
export const SET_PROJECT_LEADER_ERROR = 'setProjectLeaderError'; //

const state = {
  projectLeader: {},
  projectLeaders: [],
  projectLeadersMeta: {},
  projectLeadersPage: 1,
  error: null
};

const mutations = {
  [SET_PROJECT_LEADER_ERROR](state, error){
    state.error = error;
  },
  [SET_PROJECT_LEADER](state, payload){
    state.projectLeader = payload;
  },
  [SET_PROJECT_LEADERS](state, payload){
    state.projectLeaders = payload.data;
    state.projectLeadersMeta = payload.meta;
  },
  [SET_PROJECT_LEADERS_PAGE](state, payload){
    state.projectLeadersPage = payload;
  }
};

const actions = {
  [GET_PROJECT_LEADER](context, id){
    return GetAction("manager/project-leaders", `${id}`, context, SET_PROJECT_LEADER_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PROJECT_LEADER, response.data );
    }});
  },
  [STORE_PROJECT_LEADER](context, formData){
    return PostAction(`manager/project-leaders`, formData, context, SET_PROJECT_LEADER_ERROR, { credentials: true } )
  },
  [UPDATE_PROJECT_LEADER](context, { id, formData }){
    return PutAction(`manager/project-leaders/${id}`, formData, context, SET_PROJECT_LEADER_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PROJECT_LEADER, response.data );
    }});
  },
  [GET_PROJECT_LEADERS](context, page= 1 ){
    return GetAction( `manager/project-leaders?page=${page}`,
    ``,
    context,
    SET_PROJECT_LEADER_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_PROJECT_LEADERS, response.data );
        context.commit( SET_PROJECT_LEADERS_PAGE, page );
      }
    } );
  }
};

const getters = {
  projectLeader: (state)=> state.projectLeader,
  projectLeaders: (state)=> state.projectLeaders,
  projectLeadersMeta: (state)=> state.projectLeadersMeta,
  projectLeadersPage: (state)=> state.projectLeadersPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
