import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ACTUATOR = 'getActuator'; //
export const GET_ACTUATORS = 'getActuators'; //
export const STORE_ACTUATOR = 'storeActuator'; //
export const UPDATE_ACTUATOR = 'updateActuator'; //
// [COPY MUTATIONS]
export const SET_ACTUATOR = 'setActuator'; //
export const SET_ACTUATORS = 'setActuators'; //
export const SET_ACTUATORS_PAGE = 'setActuatorsPage'; //
export const SET_ACTUATOR_ERROR = 'setActuatorError'; //

const state = {
  actuator: {},
  actuators: [],
  actuatorsMeta: {},
  actuatorsPage: 1,
  error: null
};

const mutations = {
  [SET_ACTUATOR_ERROR](state, error){
    state.error = error;
  },
  [SET_ACTUATOR](state, payload){
    state.actuator = payload;
  },
  [SET_ACTUATORS](state, payload){
    state.actuators = payload.data;
    state.actuatorsMeta = payload.meta;
  },
  [SET_ACTUATORS_PAGE](state, payload){
    state.actuatorsPage = payload;
  }
};

const actions = {
  [GET_ACTUATOR](context, id){
    return GetAction("manager/actuators", `${id}`, context, SET_ACTUATOR_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ACTUATOR, response.data );
    }});
  },
  [STORE_ACTUATOR](context, formData){
    return PostAction(`manager/actuators`, formData, context, SET_ACTUATOR_ERROR, { credentials: true } )
  },
  [UPDATE_ACTUATOR](context, { id, formData }){
    return PutAction(`manager/actuators/${id}`, formData, context, SET_ACTUATOR_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ACTUATOR, response.data );
    }});
  },
  [GET_ACTUATORS](context, page= 1 ){
    return GetAction( `manager/actuators?page=${page}`,
    ``,
    context,
    SET_ACTUATOR_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ACTUATORS, response.data );
        context.commit( SET_ACTUATORS_PAGE, page );
      }
    } );
  }
};

const getters = {
  actuator: (state)=> state.actuator,
  actuators: (state)=> state.actuators,
  actuatorsMeta: (state)=> state.actuatorsMeta,
  actuatorsPage: (state)=> state.actuatorsPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
