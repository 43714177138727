import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ApiService from "./common/api.service";
import IdleVue from "idle-vue";
import Clipboard from 'v-clipboard';
const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  // idleTime: 6000,
  idleTime: idleTime(5)
});
Vue.use(Clipboard);
// import MockService from "./common/mock/mock.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
// import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import { idleTime } from "@c@/helpers/helpers";

const lang = localStorage.getItem("language") || "zh-UG";
/*if( lang === 'zh-CN' ){
  require("./assets/css/style.vue.css");
}else{
  require("./assets/css/style.vue.rtl.css");
}*/

// API service init
ApiService.init();

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
