import LC from "../../common/storage/localStorage";
import { GetAction, PostAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_SCAN_APP_CODE_STREAM = 'getScanAPPCodeStream'; //
// [COPY MUTATIONS]
export const SET_COPY = 'setCopy'; //
export const SET_MINIPROGRAM_AUTH_ERROR = 'setMiniProgramAuthError'; //

const state = {
  miniprogram_auth_copy: {},
  miniprogram_auth_error: null
};

const mutations = {
  [SET_MINIPROGRAM_AUTH_ERROR](state, error){
    state.miniprogram_auth_error = error;
  }
};

const actions = {
  [GET_SCAN_APP_CODE_STREAM](context, scene){
    return PostAction(`manager/authorizations/scan-app-code-stream`, {scene}, context, SET_MINIPROGRAM_AUTH_ERROR, { credentials: true });
  }
};

const getters = {
  miniprogram_auth_error: (state)=> state.miniprogram_auth_error,
};

export default {
  state,
  mutations,
  actions,
  getters
}
