import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ADMIN = 'getAdmin'; //
export const GET_ADMINS = 'getAdmins'; //
export const STORE_ADMIN = 'storeAdmin'; //
export const UPDATE_ADMIN = 'updateAdmin'; //
// [COPY MUTATIONS]
export const SET_ADMIN_USER = 'setAdminUser'; //
export const SET_ADMINS = 'setAdmins'; //
export const SET_ADMINS_PAGE = 'setAdminsPage'; //
export const SET_ADMIN_ERROR = 'setAdminError'; //

const state = {
  admin_user: {},
  admins: [],
  adminsMeta: {},
  adminsPage: 1,
  adminError: null
};

const mutations = {
  [SET_ADMIN_ERROR](state, error){
    state.adminError = error;
  },
  [SET_ADMIN_USER](state, payload){
    state.admin_user = payload;
  },
  [SET_ADMINS](state, payload){
    state.admins = payload.data;
    state.adminsMeta = payload.meta;
  },
  [SET_ADMINS_PAGE](state, payload){
    state.adminsPage = payload;
  }
};

const actions = {
  [GET_ADMIN](context, id){
    return GetAction("manager/admins", `${id}`, context, SET_ADMIN_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ADMIN_USER, response.data );
    }});
  },
  [STORE_ADMIN](context, formData){
    return PostAction(`manager/admins`, formData, context, SET_ADMIN_ERROR, { credentials: true } )
  },
  [UPDATE_ADMIN](context, { id, formData }){
    return PutAction(`manager/admins/${id}`, formData, context, SET_ADMIN_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ADMIN_USER, response.data );
    }});
  },
  [GET_ADMINS](context, page= 1 ){
    return GetAction( `manager/admins?page=${page}`,
    ``,
    context,
    SET_ADMIN_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ADMINS, response.data );
        context.commit( SET_ADMINS_PAGE, page );
      }
    } );
  }
};

const getters = {
  admin_user: (state)=> state.admin_user,
  admins: (state)=> state.admins,
  adminsMeta: (state)=> state.adminsMeta,
  adminsPage: (state)=> state.adminsPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
