// ZH-UG
export const locale = {
  TRANSLATOR: {
    SELECT: "تىل تاللاڭ"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ASIDES: {
    dashboard: "باشبەت",
    SECTIONS: {
      advert: "ئېلان باشقۇرۇش",
      article: "مەزمۇن باشقۇرۇش",
      user: "ئەزا باشقۇرۇش",
      merchant: "چىقىمدان باشقۇرۇش",
      subscribe_message: "مۇشتەرى ئۇچۇر يوللاشنى باشقۇرۇش",
      setting: "تەڭشەك باشقۇرۇش",
      admin: "باشقۇرغۇچى باشقۇرۇش"
    },
    ADVERTS: {
      first: "ئېلان باشقۇرۇش",
      home: "بارلىق ئېلانلار",
    },
    ARTICLES: {
      root: "مەزمۇن باشقۇرۇش",
      first: "مەزمۇن باشقۇرۇش",
      home: "بارلىق مەزمۇنلار",
      CLASSIFIES: {
        first: "قوشۇمچە تۈرلەرنى باشقۇرۇش",
        house: "ئۆي تۈرى",
        car: "ماشىنا تۈرى",
        job: "خىزمەت تۈرى",
        source_income: "كىرىم مەنبە تۈرى",
      },
      HOSPITALS: {
        first: "دوختۇرخانا باشقۇرۇش",
        home: "بارلىق دوختۇرخانىلار",
      },
      HOSPITAL_DEPARTMENTS: {
        home: "بارلىق بۆلۈملەر",
      },
      REGULATORS: {
        first: "ئورگان باشقۇرۇش",
        home: "نازارەتچى ئورگانلار",
      },
      ACTUATORS: {
        home: "ئىجرا ئورگانلار",
      },
      CONTRIBUTION_MANAGERS: {
        home: "ئىئانە تاپشۇرىۋالغۇچى ئورگانلار",
      },
      PROJECT_LEADERS: {
        home: "تۈر مەسئۇللىرى",
      },
    },
    COMMENTS: {
      first: "ئىنكاس باشقۇرۇش",
      home: "بارلىق ئىنكاسلار",
    },
    USERS: {
      first: "ئابونىتلار",
      home: "بارلىق ئابونىتلار",
    },
    ADMINS: {
      first: "باشقۇرغۇچى باشقۇرۇش",
      home: "بارلىق باشقۇرغۇچىلار",
    },
    MERCHANT: {
      first: "چىقىمدان باشقۇرۇش",
      home: "بارلىق چىقىمدانلار",
    },
    SUBSCRIBE_MESSAGES: {
      first: "مۇشتەرى ئۇچۇرى باشقۇرۇش",
      home: "باشقۇرۇش مەركىزى",
    },
    SETTINGS: {
      first: "تەڭشەكلەر",
      home: "بارلىق تەڭشەكلەر",
    },
  },

  COMMON: {
    WELCOME: 'ياخشىمۇسىز',
    REFRESH: 'يېڭىلاش',
    CREATE: 'يېڭىدىن قۇرۇش',
    BACK: 'قايتىش',
    $: "يۈەن",
    NO_BODY: "نامەلۇم",
    PHONE: "تېلفۇن",
    TABLIST: {
      TITLE: {
        UG: 'ئۇيغۇرچە مەزمۇن',
        CN: 'خەنزۇچە مەزمۇن'
      }
    },
    TITLES: {
      UG: "ئۇيغۇرچىسى",
      CN: "خەنزۇچىسى"
    },
    FORM: {
      LABEL: {
        is_show: 'ئوچۇقمۇ ؟',
        sort: 'تەرتىبى',
        describe: 'قىسقىچە چۈشەنچە',
        title: 'ماۋزو',
        gender: 'جىنسى'
      },
      PLACEHOLDER: {
        sort: 'تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ',
        describe: '{prefix} قىسقىچە چۈشەنچىسىنى يېزىڭ ياكى قۇرۇق قويۇڭ',
        title: '{prefix} ماۋزوسىنى كىرگۈزۈڭ',
        gender: "جىنسىنى تاللاڭ"
      },
      FEEDBACK: {
        title: '{prefix} ماۋزوسىنى كىرگۈزۈڭ'
      },
      OPTIONS: {
        YES: 'شۇنداق',
        NO: 'ياق',
        MALE: "ئەر",
        FEMALE: "ئايال",
      },
      BUTTONS: {
        SUBMIT: 'ساقلاش',
        CANCEL: 'بىكار قىلىش',
        DELETE: 'ئۆچۈرۈش',
        EDIT: 'تەھرىرلەش',
        NEXT: 'كىيىنكى قەدەم',
        PREV: 'ئالدىنقى قەدەم',
        CONFIRM: {
          YES: "شۇنداق",
          NO: "ياق"
        }
      }
    }
  },

  PAGES: {
    DASHBOARD: {
      BREAD_CRUMBS_TITLES: {
        first: "باشبەت",
      },
      STATISTICS: {
        title: "بىر ئاي ئىچىدىكى ئەزا سىتاستىكىسى",
        desc: "نەپەر ئەزا يېڭىدىن قوشۇلدى",
        label: "يېڭى كىرگەن ئەزا سانى"
      },
      PROTOCOL: {
        title: 'ئىشلىتىش كېلىشىمنامىسى'
      },
      USERS: {
        title: 'يېڭى ئەزالار'
      }
    },
    CLASSIFY: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          first: "قوشۇمچە تۈرلەرنى باشقۇرۇش",
          house: "ئۆي تۈرى باشقۇرۇش",
          car: "ماشىنا تۈرى باشقۇرۇش",
          job: "خىزمەت تۈرى باشقۇرۇش",
          source_income: "كىرىم مەنبە تۈرى باشقۇرۇش",
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر تۈرنى ئىشلىتىشنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر تۈرنى ئىشلىتىشنى توختىتامسىز؟"
        },
        LABEL: {
          title: 'نامى'
        }
      },
      STORE: {
        PORTLET_TITLES: {
          house: "ئۆي تۈرى قوشۇش",
          car: "ماشىنا تۈرى قوشۇش",
          job: "خىزمەت تۈرى قوشۇش",
          source_income: "كىرىم مەنبە تۈرى قوشۇش",
        },
        BREAD_CRUMBS_TITLES: {
          first: "قوشۇمچە تۈرلەرنى باشقۇرۇش",
          house_first: "ئۆي تۈرى باشقۇرۇش",
          house: "ئۆي تۈرى قوشۇش",
          car_first: "ماشىنا تۈرى باشقۇرۇش",
          car: "ماشىنا تۈرى قوشۇش",
          job_first: "خىزمەت تۈرى باشقۇرۇش",
          job: "خىزمەت تۈرى قوشۇش",
          source_income_first: "كىرىم مەنبە تۈرى باشقۇرۇش",
          source_income: "كىرىم مەنبە تۈرى قوشۇش",
        }
      },
      EDIT: {
        PORTLET_TITLES: {
          house: "ئۆي تۈرى تەھرىرلەش",
          car: "ماشىنا تۈرى تەھرىرلەش",
          job: "خىزمەت تۈرى تەھرىرلەش",
          source_income: "كىرىم مەنبە تۈرى تەھرىرلەش",
        },
        BREAD_CRUMBS_TITLES: {
          first: "قوشۇمچە تۈرلەرنى باشقۇرۇش",
          house_first: "ئۆي تۈرى باشقۇرۇش",
          house: "ئۆي تۈرى تەھرىرلەش",
          car_first: "ماشىنا تۈرى باشقۇرۇش",
          car: "ماشىنا تۈرى تەھرىرلەش",
          job_first: "خىزمەت تۈرى باشقۇرۇش",
          job: "خىزمەت تۈرى تەھرىرلەش",
          source_income_first: "كىرىم مەنبە تۈرى باشقۇرۇش",
          source_income: "كىرىم مەنبە تۈرى تەھرىرلەش",
        }
      },
    },
    HOSPITAL: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "دوختۇرخانا باشقۇرۇش",
          CURRENT_TITLE: "دوختۇرخانا ئۇچۇرىنى باشقۇرۇش"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر دوختۇرخانا ئۇچۇرىنى ئىشلىتىشنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر دوختۇرخانا ئۇچۇرىنى ئىشلىتىشنى توختىتامسىز؟"
        },
        LABEL: {
          title: 'نامى'
        }
      },
      STORE: {
        TITLE: "يېڭىدىن دوختۇرخانا قۇرۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "دوختۇرخانا باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن دوختۇرخانا ئۇچۇرى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "دوختۇرخانا ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "دوختۇرخانا باشقۇرۇش",
          CURRENT_TITLE: "دوختۇرخانا ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    HOSPITAL_DEPARTMENT: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "دوختۇرخانا بۆلۈمى باشقۇرۇش",
          CURRENT_TITLE: "بۆلۈم ئۇچۇرىنى باشقۇرۇش"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر بۆلۈم ئۇچۇرىنى ئىشلىتىشنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر بۆلۈم ئۇچۇرىنى ئىشلىتىشنى توختىتامسىز؟"
        },
        LABEL: {
          title: 'نامى'
        }
      },
      STORE: {
        TITLE: "يېڭىدىن بۆلۈم قۇرۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "دوختۇرخانا بۆلۈمى باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن بۆلۈم ئۇچۇرى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "بۆلۈم ئۇچۇرى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "دوختۇرخانا بۆلۈمى باشقۇرۇش",
          CURRENT_TITLE: "بۆلۈم ئۇچۇرى تەھرىرلەش"
        },
      }
    },
    REGULATOR: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "نازارەتچى ئورگان باشقۇرۇش",
          CURRENT_TITLE: "ئورگان ئۇچۇرىنى باشقۇرۇش"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر ئورگان ئۇچۇرىنى ئىشلىتىشنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر ئورگان ئۇچۇرىنى ئىشلىتىشنى توختىتامسىز؟"
        }
      },
      STORE: {
        TITLE: "يېڭىدىن نازارەتچى ئورگان قۇرۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "نازارەتچى ئورگان باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن ئورگان ئۇچۇرى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "ئورگان ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "نازارەتچى ئورگان باشقۇرۇش",
          CURRENT_TITLE: "ئورگان ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    ACTUATOR: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئىجرا ئورگان باشقۇرۇش",
          CURRENT_TITLE: "ئورگان ئۇچۇرىنى باشقۇرۇش"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر ئورگان ئۇچۇرىنى ئىشلىتىشنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر ئورگان ئۇچۇرىنى ئىشلىتىشنى توختىتامسىز؟"
        }
      },
      STORE: {
        TITLE: "يېڭىدىن ئىجرا ئورگان قۇرۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئىجرا ئورگان باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن ئورگان ئۇچۇرى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "ئورگان ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئىجرا ئورگان باشقۇرۇش",
          CURRENT_TITLE: "ئورگان ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    PROJECT_LEADER: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "تۈر مەسئۇللىرىنى باشقۇرۇش",
          CURRENT_TITLE: "بارلىق تۈر مەسئۇللىرى"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر كىشى ئۇچۇرىنى ئىشلىتىشنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر كىشى ئۇچۇرىنى ئىشلىتىشنى توختىتامسىز؟"
        }
      },
      STORE: {
        TITLE: "يېڭىدىن تۈر مەسئۇلى قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "تۈر مەسئۇللىرىنى باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن تۈر مەسئۇلى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "تۈر مەسئۇلى ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "تۈر مەسئۇللىرىنى باشقۇرۇش",
          CURRENT_TITLE: "تۈر مەسئۇلى ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    ARTICLE: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "بارلىق مەزمۇنلار"
        },
        LABEL: {
          title: 'نامى'
        }
      },
      STORE: {
        TITLE: "يېڭىدىن مەزمۇن قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن مەزمۇن قوشۇش"
        },
      },
      EDIT: {
        TITLE: "مەزمۇن ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "مەزمۇن ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    ARTICLE_STATISTICS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "سىتاستىكىلار"
        },
        LABEL: {
          title: 'نامى',
          order_total_fee: "ئىئانە سوممىسى",
          userDonations: "ئەزا ئىئانە تەپسىلاتى",
          donations: "ئىئانە قىلغۇچىلار",
          donationsSymbol: "نەپەر",
          donationsDesc: "ئۇمۇمىي ئىئانە قىلغۇچىلار سانى",
          donationTimes: "قېتىم سانى",
          donationTimesSymbol: "قېتىم",
          donationTimesDesc: "ئىئانە قىلىنغان ئۇمۇمىي قېتىم سانى",
          highAmount: "ئەڭ يۇقىرى سومما",
          highAmountDesc: "ئىئانە قىلىنغان ئەڭ يۇقىرى سومما",
          lowAmount: "ئەڭ تۆۋەن سومما",
          lowAmountDesc: "ئىئانە قىلىنغان ئەڭ تۆۋەن سومما"
        }
      },
    },
    ARTICLE_NOTIFICATION: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "بارلىق ئۇقتۇرۇشلار"
        },
        LABEL: {},
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر ئۇقتۇرۇشنى قايتا ئېچىۋېتەمسىز؟",
          CLOSE: "مەزكۇر ئۇقتۇرۇشنى ئىتىپ قويامسىز؟"
        },
      },
      STORE: {
        TITLE: "يېڭىدىن ئۇقتۇرۇش قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق ئۇقتۇرۇشلار",
          CURRENT_TITLE: "يېڭىدىن ئۇقتۇرۇش قوشۇش"
        },
      },
      EDIT: {
        TITLE: "ئۇقتۇرۇش ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق ئۇقتۇرۇشلار",
          CURRENT_TITLE: "ئۇقتۇرۇش ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    ARTICLE_WITNESS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "بارلىق گۇۋاھلىقلار"
        },
        LABEL: {
          phone: "تېلفۇن نۇمۇرى"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر گۇۋاھلىقنى ئېچىۋېتەمسىز؟",
          CLOSE: "مەزكۇر گۇۋاھلىقنى ئىتىپ قويامسىز؟"
        },
      },
      STORE: {
        TITLE: "يېڭىدىن گۇۋاھلىق قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق گۇۋاھلىقلار",
          CURRENT_TITLE: "يېڭىدىن گۇۋاھلىق قوشۇش"
        },
      },
      EDIT: {
        TITLE: "گۇۋاھلىق ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق گۇۋاھلىقلار",
          CURRENT_TITLE: "گۇۋاھلىق ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    MERCHANT: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "چىقىمدان باشقۇرۇش",
          CURRENT_TITLE: "بارلىق چىقىمدانلار"
        },
        LABEL: {
          mch_id: "چىقىمدان ئايدىسى",
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر چىقىمداننى قايتا ئېچىۋېتەمسىز؟",
          CLOSE: "مەزكۇر چىقىمداننى ئىتىپ قويامسىز؟"
        },
      },
      STORE: {
        TITLE: "يېڭىدىن چىقىمدان قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "چىقىمدان باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن چىقىمدان قوشۇش"
        },
      },
      EDIT: {
        TITLE: "چىقىمدان ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "چىقىمدان باشقۇرۇش",
          CURRENT_TITLE: "چىقىمدان ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    ARTICLE_MEDICAL_PAYMENT_HISTORY: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "بارلىق پۇل ئۇرۇش خاتىرىسى"
        },
        LABEL: {
          date: "ۋاقتى",
          amount: "سوممىسى"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر پۇل ئۇرۇش خاتىرىسىنى قايتا ئېچىۋېتەمسىز؟",
          CLOSE: "مەزكۇر پۇل ئۇرۇش خاتىرىسىنى ئىتىپ قويامسىز؟"
        },
      },
      STORE: {
        TITLE: "يېڭىدىن پۇل ئۇرۇش خاتىرىسى قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق پۇل ئۇرۇش خاتىرىسى",
          CURRENT_TITLE: "يېڭىدىن پۇل ئۇرۇش خاتىرىسى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "پۇل ئۇرۇش خاتىرىسىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق پۇل ئۇرۇش خاتىرىسى",
          CURRENT_TITLE: "پۇل ئۇرۇش خاتىرىسىنى تەھرىرلەش"
        },
      }
    },
    ARTICLE_PROGRESS_HISTORY: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          CURRENT_TITLE: "بارلىق ئىجرا خاتىرىسى"
        },
        LABEL: {
          date: "ۋاقتى",
          amount: "سوممىسى",
          total: "ياردەمگە ئېرىشكەنلەر سانى",
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر ئىجرا خاتىرىسىنى قايتا ئېچىۋېتەمسىز؟",
          CLOSE: "مەزكۇر ئىجرا خاتىرىسىنى ئىتىپ قويامسىز؟"
        },
      },
      STORE: {
        TITLE: "يېڭىدىن ئىجرا خاتىرىسى قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق ئىجرا خاتىرىسى",
          CURRENT_TITLE: "يېڭىدىن ئىجرا خاتىرىسى قوشۇش"
        },
      },
      EDIT: {
        TITLE: "ئىجرا خاتىرىسىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "مەزمۇن باشقۇرۇش",
          HOME_TITLE: "بارلىق ئىجرا خاتىرىسى",
          CURRENT_TITLE: "ئىجرا خاتىرىسىنى تەھرىرلەش"
        },
      }
    },
    COMMENTS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئىنكاس باشقۇرۇش",
          CURRENT_TITLE: "بارلىق ئىنكاسلار"
        },
        TIPS:{
          NODATA: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن."
        },
        OPTIONS: {
          ALL: "ھەممىسى",
          AUDIT: "تەستىقلانمىغىنى",
          NO_BODY: "نامەلۇم"
        },
        LABEL: {},
      },
      LIST: {
        TIPS: {
          AUDIT: "تەستىقلاش",
          DE_AUDIT: "تەستىقنى بىكار قىلىش",
          NO_BODY: "نامەلۇم",
          NO_DATA: "ھازىرچە قالدۇرۇلغان ئىنكاس يوق ئىكەن",
        }
      },
      DETAIL: {
        PLACEHOLDER: {
          typing: "ئىنكاس مەزمۇنىنى يېزىڭ ..."
        },
        BUTTONS: {
          AUDIT: "تەستىقلاش",
          AUDITED: "تەستىقلانغان",
          DELETE: "ئۆچۈرۈش",
          REPLY: "قايتۇرۇش",
          SET_TOP: 'چوققىلاش',
          DE_TOP: 'چوققىلاشنى بىكار قىلىش',
        },
        TIPS: {
          ERROR: "خاتالىق كۆرۈلدى، سەل تۇرۇپ قايتا سىناڭ",
          CONFIRM: "مەزكۇر ئىنكاسنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟",
          CONFIRM_WITH_LEN: "مەزكۇر ئىنكاس باش ئىنكاس، ئىنكاسقا قايتۇرۇلغان قايتۇرما ئىنكاس {len} دانە، بارىلىق ئىنكاس بىراقلا ئۆچۈرۈلىدۇ، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟"
        }
      }
    },
    ADVERTS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئېلان باشقۇرۇش",
          CURRENT_TITLE: "بارلىق ئېلانلار"
        },
        TIPS:{
          NODATA_TITLE: "ئۇچۇر تېپىلمىدى",
          NODATA: "ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن.",
          DELETE: "مەزكۇر ئېلاننى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟"
        },
        OPTIONS: {
        },
        LABEL: {
          TYPE: "تۈرى: ",
          TARGET_TITLE: {
            article: "مەزمۇن نامى: ",
            external_link: "ئۇلانما ئادېرسى: ",
            link: "بېسىپ كۆرۈڭ"
          },
          TARGET: {
            article: "مەزمۇنغا ئاتلايدۇ",
            mp: "ئەپچاققا ئاتلايدۇ",
            external_link: "سىرتقى ئۇلانمىغا ئاتلايدۇ",
          },
          BUTTON: {
            STORE: "ئېلان قوشۇش"
          }
        },
      },
      STORE: {
        TITLE: "يېڭىدىن ئېلان قوشۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئېلان باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن ئېلان قوشۇش"
        },
      },
      EDIT: {
        TITLE: "ئېلان ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئېلان باشقۇرۇش",
          CURRENT_TITLE: "ئېلان ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    USERS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئەزا باشقۇرۇش",
          CURRENT_TITLE: "بارلىق ئەزالار"
        },
        TIPS:{
          NODATA_TITLE: "ئۇچۇر تېپىلمىدى",
          NODATA_DESC: "مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، ھازىرچە مۇناسىۋەتلىك ئەزا ئۇچۇرى يوق ئىكەن.",
        },
        OPTIONS: {
        },
        LABEL: {
          SEARCH_PLACEHOLDER: "ئەزانىڭ تور نامىنى ياكى id نۇمۇرىنى كىرگۈزۈپ ئىزدەڭ",
          BLACK_LIST_REASON: "قارا تىزىملىك سەۋەبى: ",
          BLACK_LIST_NO_REASON: "خاتىرلەنمىگەن",
          IS_NOT_BLACK: "( ھازىر قارا تىزىملىك ئەمەس )",
          ACTIONS_TITLE: "مەشغۇلات",
          NO_BODY: "مەشغۇلات",
          id: "ئەزالىق id نۇمۇرى",
          created_at: "كىرگەن ۋاقتى",
          donation_total_fee: "ئۇمۇمىي ئىئانە سوممىسى",
          BUTTON: {
            SET_BLACK_LIST: "قارا تىزىملىك ئۇچۇرى يېڭىلاش",
            DONATION_HISTORY: "ئىئانە خاتىرىسى",
            SOCIALITE_ACCOUNTS: "سالاھىيەت تەپسىلاتى",
          }
        },
      },
      DONATION: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئەزا باشقۇرۇش",
          CURRENT_TITLE: "ئىئانە تەپسىلاتى"
        },
        LABEL: {
          phone: "تېلفۇن نۇمۇرى",
          order_total_fee: "جەمئىي",
          order_total_fee_desc: "ھازىرغىچە بولغان ئۇمۇمىي ئىئانە سوممىسى",
          order_total_fee_avg: "ئوتتۇرىچە سوممىسى",
          order_total_fee_avg_desc: "ھازىرغىچە بولغان ئوتتۇرىچە ئىئانە سوممىسى",
          total_article: "ياردەم قىلغان سانى",
          total_article_desc: "جەمئىي ياردەم قىلغان مۇراجەتنامە سانى",
          donation_times: "قېتىم سانى",
          donation_times_desc: "جەمئىي ياردەم قىلغان قېتىم سانى",
          times: "قېتىم",
          total_article_limit: "پارچە",
          user_total_fee: "جەمئىي ئىئانە سوممىسى",
          total_times: "ياردەم قىلغان قېتىم سانى",
        }
      },
    },
    ADMIN: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "باشقۇرغۇچى باشقۇرۇش",
          CURRENT_TITLE: "باشقۇرغۇچى ئۇچۇرىنى باشقۇرۇش"
        },
        CONFIRM_TIPS: {
          OPEN: "مەزكۇر باشقۇرغۇچى ئۇچۇرىنى قايتا قوزغىتامسىز؟",
          CLOSE: "مەزكۇر باشقۇرغۇچى ئۇچۇرىنى ئىشلىتىشنى توختىتامسىز؟"
        },
        LABEL: {
          title: 'تور نامى',
          superTitle: "ئالى باشقۇرغۇچى",
          actuator: "تەۋە ئەترەت: ",
          regulator: "نازارەتچى ئورگان: ",
        }
      },
      STORE: {
        TITLE: "يېڭىدىن باشقۇرغۇچى قۇرۇش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "باشقۇرغۇچى باشقۇرۇش",
          CURRENT_TITLE: "يېڭىدىن باشقۇرغۇچى قۇرۇش"
        },
      },
      EDIT: {
        TITLE: "باشقۇرغۇچى ئۇچۇرىنى تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "باشقۇرغۇچى باشقۇرۇش",
          CURRENT_TITLE: "باشقۇرغۇچى ئۇچۇرىنى تەھرىرلەش"
        },
      }
    },
    PROFILE: {
      INDEX: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئارخىپ باشقۇرۇش",
          CURRENT_TITLE: "ئارخىبىم"
        },
        LABEL: {
          phone: "تېلفۇن نۇمۇرىڭىز",
          action: {
            edit: "ئارخىپ تەھرىرلەش",
            reset_password: "پارول ئۆزگەرتىش"
          }
        }
      },
      EDIT: {
        title: "ئارخىپ تەھرىرلەش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئارخىپ باشقۇرۇش",
          CURRENT_TITLE: "ئارخىپ تەھرىرلەش"
        },
        LABEL: {
          phone: "تېلفۇن نۇمۇرىڭىز",
          nickname: "تور نامىڭىز",
          avatar: {
            label: "باش سۆرەت",
            desc: "باش سۆرەت تاللاپ ئالماشتۇرۇڭ"
          }
        },
        DESCRIPTION: {
          phone: "تېلفۇن نۇمۇرىڭىزنى كىرگۈزۈڭ",
          nickname: "تور نامىڭىزنى كىرگۈزۈڭ",
        },
        FEEDBACK: {
          phone: "تېلفۇن نۇمۇرىڭىزنى توغرا كىرگۈزۈڭ",
          nickname: "تور نامىڭىز كەم دىگەندە ئىككى ھەرىپتىن ئاز بولمىسۇن",
        },
        BUTTON: {
          submit: "ساقلاش",
          cancel: "بىكار قىلىش"
        },
        TIPS: {
          EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
          VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
        },
      },
      RESET_PASSWORD: {
        title: "پارول ئۆزگەرتىش",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "ئارخىپ باشقۇرۇش",
          CURRENT_TITLE: "پارول ئۆزگەرتىش"
        },
        LABEL: {
          old_password: "ھازىرقى پارولىڭىز",
          password: "يېڭى پارولىڭىز",
          password_confirmation: "يېڭى پارولىڭىز",
        },
        DESCRIPTION: {
          password: "يېڭى پارولىڭىزنى كىرگۈزۈڭ",
          password_confirmation: "يېڭى پارولىڭىزنى كىرگۈزۈڭ",
        },
        PLACEHOLDER: {
          old_password: "نۆۋەتتە ئىشلىتىۋاتقان پارولنى كىرگۈزۈڭ",
          password: "ئالماشتۇرماقچى بولغان يېڭى پارولىڭىزنى كىرگۈزۈڭ",
          password_confirmation: "ئالماشتۇرماقچى بولغان يېڭى پارولىڭىزنى قايتا كىرگۈزۈڭ",
        },
        FEEDBACK: {
          old_password: "نۆۋەتتە ئىشلىتىۋاتقان پارولىڭىزنى كىرگۈزۈڭ",
          password: "يېڭى پارولىڭىزنىڭ ئۇزۇنلىقى 6 ھەرىپتىن كەم بولمىسۇن",
          password_confirmation: "ئىككى قېتىم كىرگۈزگەن يېڭى پارولىڭىز ئوخشاش بولسۇن",
        },
        BUTTON: {
          submit: "ساقلاش",
          cancel: "بىكار قىلىش"
        },
        TIPS: {
          EDIT_SUCCESS: "پارولىڭىزنى مۇۋاپىقىيەتلىك ئۆزگەرتتىڭىز!",
          VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
        },
      }
    },
    PROTOCOL: {
      title: "ئىشلىتىش كېلىشىمنامىسى",
      BREAD_CRUMBS_TITLES: {
        FIRST_TITLE: "ئىشلىتىش كېلىشىمنامىسى",
        CURRENT_TITLE: "كېلىشىمنامە مەزمۇنى"
      },
    }
  },

  COMPONENTS: {
    FORMS: {
      CLASSIFY_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "تۈرنىڭ ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "تۈرنىڭ خەنزۇچە ئۇچۇرلىرى"
            }
          },
          LABEL: {
            title: "تۈر نامى",
            describe: "قىسقىچە چۈشەنچىسى",
          },
          PLACEHOLDER: {
            title_ug: "تۈرنىڭ ئۇيغۇرچە نامىنى تولدۇرۇڭ",
            title_cn: "تۈرنىڭ خەنزۇچە نامىنى تولدۇرۇڭ",
            describe_ug: "ئۇيغۇرچە قىسقىچە چۈشەنچىسىنى تولدۇرۇڭ",
            describe_cn: "خەنزۇچە قىسقىچە چۈشەنچىسىنى تولدۇرۇڭ",
          },
          FEEDBACK:{
            title: "تۈر نامىنى چوقۇم تولدۇرىسىز",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        }
      },
      HOSPITAL_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "دوختۇرخانىنىڭ ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "دوختۇرخانىنىڭ خەنزۇچە ئۇچۇرلىرى"
            }
          },
          LABEL: {
            bank_account: "بانكا ھېسابات نۇمۇرى",
            phone: "ئالاقىلىشىش تېلفۇن نۇمۇرى",

            title: "دوختۇرخانا نامى",
            address: "دوختۇرخانا ئادېرسى",
            describe: "قىسقىچە چۈشەنچە",
          },
          PLACEHOLDER: {
            bank_account: "دوختۇرخانىنىڭ بانكا ھېسابات نۇمۇرىنى كىرگۈزۈڭ",
            phone: "دوختۇرخانىنىڭ ئالاقىلىشىش تېلفۇن نۇمۇرىنى كىرگۈزۈڭ",

            title_ug: "دوختۇرخانىنىڭ ئۇيغۇرچە نامىنى كىرگۈزۈڭ",
            address_ug: "دوختۇرخانىنىڭ ئۇيغۇرچە ئادېرسىنى كىرگۈزۈڭ",
            describe_ug: "دوختۇرخانىنىڭ ئۇيغۇرچە قىسقىچە چۈشەنچىسىنى يېزىڭ ياكى قۇرۇق قويۇڭ",

            title_cn: "دوختۇرخانىنىڭ خەنزۇچە نامىنى كىرگۈزۈڭ",
            address_cn: "دوختۇرخانىنىڭ خەنزۇچە ئادېرسىنى كىرگۈزۈڭ",
            describe_cn: "دوختۇرخانىنىڭ خەنزۇچە قىسقىچە چۈشەنچىسىنى يېزىڭ ياكى قۇرۇق قويۇڭ",
          },
          FEEDBACK: {
            bank_account: "دوختۇرخانىنىڭ بانكا ھېسابات نۇمۇرىنى قۇرۇق قويماڭ",
            phone: "دوختۇرخانىنىڭ ئالاقىلىشىش تېلفۇن نۇمۇرىنى قۇرۇق قويماڭ",

            title: "دوختۇرخانا نامىنى قۇرۇق قويماڭ",
            address: "دوختۇرخانا ئادېرسىنى قۇرۇق قويماڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        },
      },
      HOSPITAL_DEPARTMENT_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "بۆلۈمنىڭ ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "بۆلۈمنىڭ خەنزۇچە ئۇچۇرلىرى"
            }
          },
          LABEL: {
            title: "بۆلۈم نامى",
            describe: "قىسقىچە چۈشەنچە",
          },
          PLACEHOLDER: {
            title_ug: "بۆلۈم نامىنى ئۇيغۇرچە كىرگۈزۈڭ",
            describe_ug: "بۆلۈمنىڭ قىسقىچە چۈشەنچىسىنى ئۇيغۇرچە يېزىڭ ياكى قۇرۇق قويۇڭ",

            title_cn: "بۆلۈم نامىنى خەنزۇچە كىرگۈزۈڭ",
            describe_cn: "بۆلۈمنىڭ قىسقىچە چۈشەنچىسىنى خەنزۇچە يېزىڭ ياكى قۇرۇق قويۇڭ",
          },
          FEEDBACK: {
            title: "بۆلۈم نامىنى قۇرۇق قويماڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        },
      },
      REGULATOR_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "نازارەتچى ئورگان ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "نازارەتچى ئورگان خەنزۇچە ئۇچۇرلىرى"
            }
          },
          LABEL: {
            bank_account: "بانكا ھېسابات نۇمۇرى",
            phone: "ئالاقىلىشىش تېلفۇن نۇمۇرى",
            credit_code: "ئىناۋەت نۇمۇرى",
            donation_certificate_image: "ئاشكارە ئىئانە توپلاش كىنىشكىسى",
            logo_image: "LOGO",
            organization_certificate_image: "خەيرى-ساخاۋەت تەشكىلاتى تىزىملاش كىنىشكىسى",

            title: "ئورگان نامى",
            address: "ئورگان ئادېرسى",
            register_authority: "تىزىملىغان ئورۇن",
            deposit_bank: "ھېسابات ئاچقان بانكا",
            describe: "قىسقىچە چۈشەنچە",
          },
          PLACEHOLDER: {
            bank_account: "ئورگان بانكا ھېسابات نۇمۇرىنى كىرگۈزۈڭ",
            phone: "ئورگان ئالاقىلىشىش تېلفۇن نۇمۇرىنى كىرگۈزۈڭ",
            credit_code: "كىنىشكىدىكى بىرلىككە كەلگەن ئىناۋەت نۇمۇرىنى كىرگۈزۈڭ",
            logo_image: "ئورگاننىڭ لوگو ماركا رەسىمىنى تاللاپ بېرىڭ",
            donation_certificate_image: "ئاشكارە ئىئانە توپلاش كىنىشكىسى رەسىمىنى تاللاپ بېرىڭ",
            organization_certificate_image: "خەيرى-ساخاۋەت تەشكىلاتى تىزىملاش كىنىشكىسى رەسىمىنى تاللاپ بېرىڭ",

            title_ug: "ئورگان نامىنى ئۇيغۇرچە كىرگۈزۈڭ",
            address_ug: "ئورگان ئادېرسىنى ئۇيغۇرچە كىرگۈزۈڭ",
            register_authority_ug: 'تىزىملاپ ئەنگە ئالغان ئورۇن نامىنى ئۇيغۇرچە كىرگۈزۈڭ',
            deposit_bank_ug: 'ئورگان ھېسابات ئاچقان بانكا نامىنى ئۇيغۇرچە كىرگۈزۈڭ',
            describe_ug: "ئورگان قىسقىچە چۈشەنچىسىنى ئۇيغۇرچە يېزىڭ ياكى قۇرۇق قويۇڭ",

            title_cn: "ئورگان نامىنى خەنزۇچە كىرگۈزۈڭ",
            address_cn: "ئورگان ئادېرسىنى خەنزۇچە كىرگۈزۈڭ",
            register_authority_cn: 'تىزىملاپ ئەنگە ئالغان ئورۇن نامىنى خەنزۇچە كىرگۈزۈڭ',
            deposit_bank_cn: 'ئورگان ھېسابات ئاچقان بانكا نامىنى خەنزۇچە كىرگۈزۈڭ',
            describe_cn: "ئورگان قىسقىچە چۈشەنچىسىنى خەنزۇچە يېزىڭ ياكى قۇرۇق قويۇڭ",
          },
          FEEDBACK: {
            bank_account: "ئورگان بانكا ھېسابات نۇمۇرىنى قۇرۇق قويماڭ",
            phone: "ئورگان ئالاقىلىشىش تېلفۇن نۇمۇرىنى قۇرۇق قويماڭ",
            credit_code: "ئورگاننىڭ بىرلىككە كەلگەن ئىناۋەت نۇمۇرىنى قۇرۇق قويماڭ",
            logo_image: "لوگو ماركا رەسىمنى چوقۇم تاللاپ يوللىشىڭىز كېرەك",
            donation_certificate_image: "ئاشكارە ئىئانە يىغىش كىنىشكىسىنى چوقۇم تاللاپ يوللىشىڭىز كېرەك",
            organization_certificate_image: "خەيرى-ساخاۋەت تەشكىلاتى تىزىملاش كىنىشكىسىنى چوقۇم تاللاپ يوللىشىڭىز كېرەك",

            title: "ئورگان نامىنى قۇرۇق قويماڭ",
            address: "ئورگان ئادېرسىنى قۇرۇق قويماڭ",
            register_authority: 'مەزكۇر ئورگاننى تىزىملاپ ئەنگە ئالغان ئورۇن نامىنى قۇرۇق قويماڭ',
            deposit_bank: 'ئورگان ھېسابات ئاچقان بانكا نامىنى قۇرۇق قويماڭ',
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        }
      },
      ACTUATOR_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "ئىجرا ئورگان ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "ئىجرا ئورگان خەنزۇچە ئۇچۇرلىرى"
            }
          },
          BUTTON: {
            CHOOSE: "تاللاش"
          },
          LABEL: {
            regulator_id: "نازارەتچى ئورگان",
            phone: "ئالاقىلىشىش تېلفۇن نۇمۇرى",
            logo_image: "LOGO",

            title: "ئورگان نامى",
            describe: "قىسقىچە چۈشەنچە",
          },
          PLACEHOLDER: {
            regulator_id: "نازارەتچى ئورگاننى تاللاڭ",
            phone: "ئورگان ئالاقىلىشىش تېلفۇن نۇمۇرىنى كىرگۈزۈڭ",
            logo_image: "ئورگاننىڭ لوگو ماركا رەسىمىنى تاللاپ بېرىڭ",

            title_ug: "ئورگان نامىنى ئۇيغۇرچە كىرگۈزۈڭ",
            describe_ug: "ئورگان قىسقىچە چۈشەنچىسىنى ئۇيغۇرچە يېزىڭ ياكى قۇرۇق قويۇڭ",

            title_cn: "ئورگان نامىنى خەنزۇچە كىرگۈزۈڭ",
            describe_cn: "ئورگان قىسقىچە چۈشەنچىسىنى خەنزۇچە يېزىڭ ياكى قۇرۇق قويۇڭ",
          },
          FEEDBACK: {
            regulator_id: "نازارەتچى ئورگاننى چوقۇم تاللاڭ",
            phone: "ئورگان ئالاقىلىشىش تېلفۇن نۇمۇرىنى قۇرۇق قويماڭ",
            logo_image: "لوگو ماركا رەسىمنى چوقۇم تاللاپ يوللىشىڭىز كېرەك",

            title: "ئورگان نامىنى قۇرۇق قويماڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        },
      },
      PROJECT_LEADER_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "تۈر مەسئۇلى ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "تۈر مەسئۇلى خەنزۇچە ئۇچۇرلىرى"
            }
          },
          BUTTON: {
            CHOOSE: "تاللاش"
          },
          LABEL: {
            actuator_id: "ئىجرا قىلغۇچى ئورگان",
            phone: "ئالاقىلىشىش تېلفۇن نۇمۇرى",
            avatar_image: "باش سۆرەت",

            full_name: "ئىسىم - فامىلىسى",
            describe: "قىسقىچە چۈشەنچە",
          },
          PLACEHOLDER: {
            actuator_id: "ئىجرا قىلغۇچى ئورگاننى تاللاڭ",
            phone: " ئالاقىلىشىش تېلفۇن نۇمۇرىنى كىرگۈزۈڭ",
            avatar_image: "باش سۆرەت رەسىمىنى تاللاپ بېرىڭ",

            full_name_ug: "تۈر مەسئۇلىنىڭ ئىسىم - فامىلىسىنى ئۇيغۇرچە كىرگۈزۈڭ",
            describe_ug: "تۈر مەسئۇلىنىڭ قىسقىچە چۈشەنچىسىنى ئۇيغۇرچە يېزىڭ ياكى قۇرۇق قويۇڭ",

            full_name_cn: "تۈر مەسئۇلىنىڭ ئىسىم - فامىلىسىنى خەنزۇچە كىرگۈزۈڭ",
            describe_cn: "تۈر مەسئۇلىنىڭ قىسقىچە چۈشەنچىسىنى خەنزۇچە يېزىڭ ياكى قۇرۇق قويۇڭ",
          },
          FEEDBACK: {
            actuator_id: "ئىجرا قىلغۇچى ئورگاننى چوقۇم تاللاڭ",
            phone: "ئالاقىلىشىش تېلفۇن نۇمۇرىنى قۇرۇق قويماڭ",
            avatar_image: "باش سۆرەت رەسىمنى چوقۇم تاللاپ يوللىشىڭىز كېرەك",

            full_name: "تۈر مەسئۇلىنىڭ ئىسىم - فامىلىسىنى قۇرۇق قويماڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        },
      },
      ARTICLE_RAISE_FORM: {
        TIPS: {
          EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
          VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
        },
        NAV_ITEMS: {
          AUTH_ITEM: {
            TITLE: "ھوقوق بېرىش",
            DESC: "ئۇچۇر يوللىغۇچى سالاھىيىتى"
          },
          TITLE_ITEM: {
            TITLE: "ماۋزو ۋە ئالبوم رەسىم",
            DESC: "مۇراجەتنامە ماۋزوسى ۋە ئالبوم رەسىم"
          },
          CONTENT_ITEM: {
            TITLE: "مەزمۇن",
            DESC: "مۇراجەتنامە مەزمۇنى"
          },
          PATIENT_ITEM: {
            TITLE: "بىمار ئۇچۇرى",
            DESC: "بىمارنىڭ شەخسىي ئۇچۇرى"
          },
          HOSPITAL_ITEM: {
            TITLE: "دوختۇرخانا ئۇچۇرى",
            DESC: "دوختۇرخانا ئۇچۇرلىرى"
          },
          BANNER_ITEM: {
            TITLE: "ئايلانما رەسىم",
            DESC: "بەتنىڭ ئەڭ ئۈستىدە كۆرۈنىدىغان رەسىم"
          },
          EVIDENCES_IMAGE_ITEM: {
            TITLE: "ئىسپاتلار",
            DESC: "بەت يۈزىدە كۆرۈنىدىغان مۇناسىۋەتلىك ئىسپاتلار"
          },
          PERSONAL_IMAGE_ITEM: {
            TITLE: "شەخسىي رەسىملەر",
            DESC: "بەت يۈزىدە كۆرۈنىدىغان بىمارنىڭ شەخسىي رەسىملىرى"
          },
          PUBLIC_INFORMATION_ITEM: {
            TITLE: "ئائىلە ئەھۋالى",
            DESC: "بىمارنىڭ قىسقىچە ئائىلە ئەھۋالى"
          },
          MANAGER_ITEM: {
            TITLE: "ئورگانلار",
            DESC: "مەزكۇر مۇراجەتنامىگە مەسئۇل ئورگانلار ئۇچۇرى"
          },
          CONFIG_ITEM: {
            TITLE: "تەڭشەكلەر",
            DESC: "مەزكۇر مەخسۇس تۈرگە مۇناسىۋەتلىك تەڭشەك ئۇچۇرى"
          },
          PREVIEW_ITEM: {
            TITLE: "كۆرۈپ چىقىڭ",
            DESC: "ئۈستىدىكى تولدۇرغان ئۇچۇرلارنى كۆرۈپ چىقىڭ"
          },
        },
        AUTH_FORM: {
          HEADING_TITLE: "ئۇچۇر يوللىغۇچى سالاھىيىتىگە ھوقوق بېرىڭ",
        },
        TITLE_FORM: {
          HEADING_TITLE: "مۇراجەتنامە ماۋزوسىنى ۋە ئالبوم رەسىمنى تەڭشەڭ",
          LABEL: {
            title: "ماۋزو",
            main_title: "نىشانى ماۋزو",
            title_prefix: "قىستۇرما ماۋزو",
            describe: "قىسقىچە چۈشەنچە",
            cover_image: "ئالبوم رەسىم",
            external_url: "مۇراجەت ئۇلانمىسى",
          },
          PLACEHOLDER: {
            title: "مۇراجەتنامە ماۋزوسىنى تولدۇرۇڭ",
            main_title: "ياردەم ئوبېكتى ماۋزوسىنى تولدۇرۇڭ",
            title_prefix: "مۇراجەتنامە قىستۇرما ماۋزوسىنى تولدۇرۇڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ",
            describe: "مۇراجەتنامە قىسقىچە چۈشەنچىسىنى تولدۇرۇڭ",
            cover_image: "ئالبوم رەسىم تاللاپ بېرىڭ، بەت يۈزىدە ۋە بەھىرلىگەندە كۆرۈنىدىغان رەسىم",
            external_url: 'مۇراجەت ئۇلانمىسىنى كىرگۈزۈڭ',
          },
          FEEDBACK: {
            title: "مۇراجەتنامە ماۋزوسى قۇرۇق قالمىسۇن",
            main_title: "ياردەم ئوبېكتى ماۋزوسى قۇرۇق قالمىسۇن",
            cover_image: "ئالبوم رەسىمنى چوقۇم تاللاپ يوللايسىز",
            external_url: 'لىڭشى قاتارلىق سۇپىلاردىكى مۇراجەتنىڭ ئۇلانمىسىنى كىرگۈزىشىڭىز كېرەك'
          },
          TIPS: {},
        },
        CONTENT_FORM: {
          HEADING_TITLE: "مۇراجەتنامە مەزمۇنىنى تەڭشەڭ",
          LABEL: {
            content: "مۇراجەتنامە مەزمۇنى",
          },
          PLACEHOLDER: {},
          FEEDBACK: {
            content: "مۇراجەتنامە مەزمۇنىنى قۇرۇق قويۇشقا بولمايدۇ",
          },
          TIPS: {},
        },
        PATIENT_FORM: {
          HEADING_TITLE: "بىمارنىڭ شەخسىي ئۇچۇرىنى تەڭشەڭ",
          LABEL: {
            id_card: "كىملىك نۇمۇرى",
            phone: "تېلفۇن نۇمۇرى",
            gender: "جىنسى",
            ug_name: "ئۇيغۇرچە ئىسىم - فامىلىسى",
            cn_name: "خەنزۇچە ئىسىم - فامىلىسى",
            ug_address: "ئۇيغۇرچە تۇرۇشلۇق ئادرېس",
            cn_address: "خەنزۇچە تۇرۇشلۇق ئادرېس",
          },
          PLACEHOLDER: {
            id_card: "كىملىك نۇمۇرىنى تولدۇرۇڭ",
            phone: "تېلفۇن نۇمۇرىنى تولدۇرۇڭ",
            gender: "جىنسىنى تاللاڭ",
            ug_name: "ئۇيغۇرچە ئىسىم - فامىلىسىنى كىرگۈزۈڭ",
            cn_name: "خەنزۇچە ئىسىم - فامىلىسىنى كىرگۈزۈڭ",
            ug_address: "ئۇيغۇرچە تۇرۇشلۇق ئادرېسنى كىرگۈزۈڭ",
            cn_address: "خەنزۇچە تۇرۇشلۇق ئادرېسنى كىرگۈزۈڭ",
          },
          FEEDBACK: {
            id_card: "كىملىك نۇمۇرىنى قۇرۇق قويماڭ",
            phone: "تېلفۇن نۇمۇرىنى قۇرۇق قويماڭ",
            gender: "جىنسىنى چوقۇم تاللاڭ",
            ug_name: "ئۇيغۇرچە ئىسىم - فامىلىسىنى قۇرۇق قويماڭ",
            cn_name: "خەنزۇچە ئىسىم - فامىلىسىنى قۇرۇق قويماڭ",
          },
          TIPS: {},
        },
        HOSPITAL_FORM: {
          HEADING_TITLE: "دوختۇرخانا ئۇچۇرلىرىنى تەڭشەڭ",
          LABEL: {
            patient_id: "بالنىست نۇمۇرى",
            hospital_id: "دوختۇرخانا تاللاڭ",
            department_id: "بۆلۈم تاللاڭ",
            address_ug: "ئۇيغۇرچە كارۋات نۇمۇرى",
            address_cn: "خەنزۇچە كارۋات نۇمۇرى",
          },
          PLACEHOLDER: {
            patient_id: "بالنىست نۇمۇرىنى كىرگۈزۈڭ",
            hospital_id: "ئورۇنلاشقان دوختۇرخانىنى تاللاڭ",
            department_id: "ئورۇنلاشقان بۆلۈمنى تاللاڭ",
            address_ug: "كارۋات نۇمۇرىنى ئۇيغۇرچە تولدۇرۇڭ",
            address_cn: "كارۋات نۇمۇرىنى خەنزۇچە تولدۇرۇڭ",
          },
          FEEDBACK: {
            patient_id: "بالنىست نۇمۇرىنى قۇرۇق قويماڭ",
            hospital_id: "ئورۇنلاشقان دوختۇرخانىنى چوقۇم تاللايسىز",
            department_id: "ئورۇنلاشقان بۆلۈمنى چوقۇم تاللايسىز",
            address_ug: "ئۇيغۇرچە كارۋات نۇمۇرىنى قۇرۇق قويماڭ",
            address_cn: "خەنزۇچە كارۋات نۇمۇرىنى قۇرۇق قويماڭ",
          },
          TIPS: {},
        },
        BANNER_FORM: {
          HEADING_TITLE: "ئايلانما رەسىم ئۇچۇرلىرىنى تەڭشەڭ",
          LABEL: {
            banner_type: "ئايلانما رەسىم تىپى",
            image: "رەسىم تاللاڭ",
            video: "فىلىم تاللاڭ",
            image_type: "رەسىم يوللاش",
            video_type: "فىلىم يوللاش",
          },
          PLACEHOLDER: {
            banner_type: "ئايلانما رەسىم تىپىنى تاللاڭ",
            image: "ئايلانما رەسىم ئۈچۈن ئەڭ كۆپ بولغاندا 4 پارچە رەسىم يوللىيالايسىز.",
            video: "ئايلانما رەسىم ئۈچۈن فىلىم تاللاڭ، پەقەت بىر پارچىلا فىلىم يوللىيالايسىز.",
          },
          FEEDBACK: {
            image: "چوقۇم ئاز بولغاندا بىر پارچە رەسىم يوللىشىڭىز كېرەك.",
            video: "ئەڭ ئاز بولغاندا چوقۇم بىر پارچە فىلىم يوللىشىڭىز كېرەك.",
          },
          TIPS: {},
        },
        EVIDENCES_FORM: {
          HEADING_TITLE: "مۇناسىۋەتلىك ئىسپات رەسىملىرىنى تەڭشەڭ",
          LABEL: {
            image: "ئىسپات رەسىمى"
          },
          PLACEHOLDER: {
            image: "ئىسپات رەسىمى تاللاڭ، ئەڭ كۆپ بولغاندا 12 پارچە يوللىيالايسىز."
          },
          FEEDBACK: {
            image: "ئىسپات رەسىمىنى چوقۇم يوللىشىڭىز كېرەك"
          },
          TIPS: {},
        },
        PERSONAL_IMAGE_FORM: {
          HEADING_TITLE: "بىمارنىڭ شەخسىي رەسىملىرىنى تەڭشەڭ",
          LABEL: {
            image: "شەخسىي رەسىمى"
          },
          PLACEHOLDER: {
            image: "بىمارنىڭ شەخسىي رەسىمىنى تاللاڭ، ئەڭ كۆپ بولغاندا 8 پارچە يوللىيالايسىز."
          },
          FEEDBACK: {
            image: "بىمارنىڭ شەخسىي رەسىمىنى چوقۇم يوللىشىڭىز كېرەك"
          },
          TIPS: {},
        },
        PUBLIC_INFORMATION_FORM: {
          HEADING_TITLE: "بىمارنىڭ قىسقىچە ئائىلە ئەھۋالىنى تەڭشەڭ",
          LABEL: {
            house_id: "تۇرالغۇ ئۆي ئەھۋالى",
            car_id: "شەخسىي ماشىنا ئەھۋالى",
            job_id: "خىزمەت ئەھۋالى",
            income: "يىللىق كىرىم",
            source_income_id: "كىرىم مەنبەسى",
            debt: "قەرزدارلىق سوممىسى",
            medical_expense: "داۋالىنىشقا كەتكەن چىقىم",
            amount_raised: "ئىئانە سوممىسى",
          },
          PLACEHOLDER: {
            house_id: "تۇرالغۇ ئۆي ئەھۋالىنى تاللاڭ",
            car_id: "شەخسىي ماشىنا ئەھۋالىنى تاللاڭ",
            job_id: "خىزمەت ئەھۋالىنى تاللاڭ",
            income: "يىللىق كىرىم ئەھۋالىنى تاللاڭ",
            source_income_id: "كىرىم مەنبەسىنى تاللاڭ",
            debt: "قەرزدارلىق سوممىسىنى تاللاڭ",
            medical_expense: "ھازىرغا قەدەر داۋالىنىشقا كەتكەن ئۇمۇمى چىقىمنى تاللاڭ",
            amount_raised: "بۇ قېتىمقى داۋالىنىشقا كېتەرلىك ئۇمۇمىي چىقىم ئەھۋالىغا ئاساسەن قانچىلىك سوممىدا ئىئانە توپلىماقچى ئىكەنلىكىڭىزنى تاللاڭ",
          },
          FEEDBACK: {
            house_id: "تۇرالغۇ ئۆي ئەھۋالىنى چوقۇم تاللاڭ",
            car_id: "شەخسىي ماشىنا ئەھۋالىنى چوقۇم تاللاڭ",
            job_id: "خىزمەت ئەھۋالىنى چوقۇم تاللاڭ",
            income: "يىللىق كىرىم ئەھۋالىنى چوقۇم تاللاڭ",
            source_income_id: "كىرىم مەنبەسىنى چوقۇم تاللاڭ",
            debt: "قەرزدارلىق سوممىسىنى چوقۇم تاللاڭ",
            medical_expense: "داۋالىنىشقا كەتكەن ئۇمۇمى چىقىمنى چوقۇم تاللاڭ",
            amount_raised: "ئىئانە سوممىسىنى چوقۇم تاللاڭ",
          },
          TIPS: {},
        },
        MANAGER_FORM: {
          HEADING_TITLE: "مەسئۇل ئورگانلار ئۇچۇرىنى تەڭشەڭ",
          BUTTON: {
            CHOOSE: "تاللاش"
          },
          LABEL: {
            regulator_id: "نازارەتچى ئورگان",
            actuator_id: "ئىجرا قىلغۇچى ئورگان",
            project_leader_id: "تۈر مەسئۇلى",
            donation_manager_id: "ئىئانە پۇلىنى باشقۇرغۇچى ئورگان",
            record_number: "تۈر ئەن نۇمۇرى",
            merchant_id: "چىقىمدان",
          },
          PLACEHOLDER: {
            regulator_id: "نازارەتچى ئورگاننى تاللاڭ",
            actuator_id: "ئىجرا قىلغۇچى ئورگاننى تاللاڭ",
            project_leader_id: "تۈر مەسئۇلىنى تاللاڭ",
            merchant_id: "چىقىمدان تاللاڭ",
            donation_manager_id: "ئىئانە پۇلىنى باشقۇرغۇچى ئورگاننى تاللاڭ",
            record_number: "تۈر ئەن نۇمۇرىنى تولدۇرۇڭ",
          },
          FEEDBACK: {
            regulator_id: "نازارەتچى ئورگاننى چوقۇم تاللاڭ",
            actuator_id: "ئىجرا قىلغۇچى ئورگاننى چوقۇم تاللاڭ",
            project_leader_id: "تۈر مەسئۇلىنى چوقۇم تاللاڭ",
            donation_manager_id: "ئىئانە پۇلىنى باشقۇرغۇچى ئورگاننى چوقۇم تاللاڭ",
            merchant_id: "چىقىمداننى چوقۇم تاللاڭ",
            record_number: "تۈر ئەن نۇمۇرىنى چوقۇم تولدۇرۇڭ",
          },
          TIPS: {},
        },
        CONFIG_FORM: {
          HEADING_TITLE: "مەخسۇس تۈرگە مۇناسىۋەتلىك تەڭشەك ئۇچۇرىنى تەڭشەڭ",
          LABEL: {
            part: "بىر كىشىلىك ياردەم سوممىسى",
            operator: "مىقدار سۆز",
            gift: "ئەشيا نامى",
            options: {
              total_fee: "ياردەم سوممىسى",
              label_text_ug: "ئۇيغۇرچە تاللانما ماۋزوسى",
              label_text_cn: "خەنزۇچە تاللانما ماۋزوسى",
              addButton: "قوشۇش"
            },
          },
          PLACEHOLDER: {
            part: "بىر كىشىلىك ياردەم سوممىسىنى كىرگۈزۈڭ",
            operator: "ماددى ئەشيا مىقدار سۆزنى كىرگۈزۈڭ",
            gift: "ماددى ئەشيا نامىنى كىرگۈزۈڭ",
            options: {
              total_fee: "ياردەم سوممىسىنى كىرگۈزۈڭ",
              label_text: "تاللانما ماۋزوسىنى كىرگۈزۈڭ",
            },
          },
          FEEDBACK: {
            part: "بىر كىشىلىك ياردەم سوممىسى قۇرۇق قالمىسۇن",
            operator: "ماددى ئەشيا مىقدار سۆزى قۇرۇق قالمىسۇن",
            gift: "ماددى ئەشيا نامى قۇرۇق قالمىسۇن",
          },
          DIVIDER: {
            options: "سومما تاللانمىلىرى"
          },
          TIPS: {},
        },
        REVIEW: {
          PATIENT: {
            title: "بىمارنىڭ شەخسىي ئۇچۇرى",
            id_card: "كىملىك نۇمۇرى",
            phone: "تېلفۇن نۇمۇرى",
            gender: "جىنسى",
            name: "ئىسىم - فامىلىسى",
            address: "نۇپۇس تۇرۇشلۇق ئورنى"
          },
          HOSPITAL: {
            title: "ئورۇنلاشقان دوختۇرخانا ئۇچۇرى",
            patient_id: "بالنىست نۇمۇرى",
            hospital_id: "دوختۇرخانا",
            department_id: "بۆلۈم",
            address: "كارۋات نۇمۇرى",
          },
          BANNER: {
            title: "ئايلانما رەسىم"
          },
          EVIDENCE_IMAGE: {
            title: "ئىسپات رەسىملىرى"
          },
          PERSONAL_IMAGE: {
            title: "بىمار شەخسى رەسىملىرى"
          },
          PUBLIC_INFORMATION: {
            title: "بىمارنىڭ قىسقىچە ئائىلە ئەھۋالى",
            house_id: "تۇرالغۇ ئۆي ئەھۋالى",
            car_id: "شەخسىي ماشىنا ئەھۋالى",
            job_id: "خىزمەت ئەھۋالى",
            income: "يىللىق كىرىم",
            source_income_id: "كىرىم مەنبەسى",
            debt: "قەرزدارلىق سوممىسى",
            medical_expense: "داۋالىنىشقا كەتكەن چىقىم",
            amount_raised: "ئىئانە سوممىسى",
            symbol: "{price} يۈەن"
          },
          MANAGER: {
            title: "ئورگانغا مۇناسىۋەتلىك ئۇچۇرلار",
            regulator_id: "نازارەتچى ئورگان",
            actuator_id: "ئىجرا قىلغۇچى ئورگان",
            project_leader_id: "تۈر مەسئۇلى",
            donation_manager_id: "ئىئانە پۇلىنى باشقۇرغۇچى ئورگان",
            merchant_id: "چىقىمدان",
            record_number: "تۈر ئەن نۇمۇرى",
          },
          EXTERNAL: {
            title: 'سىرتقى ئۇلانما ئۇچۇرلىرى',
            raise_amount: 'نىشان سومما',
            url: 'سىرتقى مۇراجەتنامە ئۇلانمىسى'
          }
        },
        COPY_FORM: {
          HEADING_TITLE: "",
          LABEL: {},
          PLACEHOLDER: {},
          FEEDBACK: {},
          TIPS: {},
        },
      },
      ARTICLE_NOTIFICATION_FORM: {
        FORM: {
          STEP: {
            UG: {
              TITLE: "ئۇيغۇرچە مەزمۇن",
              DESC: "ئۇيغۇرچە ئۇقتۇرۇش مەزمۇنلىرى"
            },
            CN: {
              TITLE: "خەنزۇچە مەزمۇن",
              DESC: "خەنزۇچە ئۇقتۇرۇش مەزمۇنلىرى"
            }
          },
          LABEL: {
            title: "ماۋزو",
            describe: "قىسقىچە تەپسىلات"
          },
          PLACEHOLDER: {
            title: "ماۋزو كىرگۈزۈڭ",
            describe: "قىسقىچە تەپسىلاتنى كىرگۈزۈڭ"
          },
          FEEDBACK: {
            title: "ماۋزونى چوقۇم تولدۇرۇڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          },
        },
      },
      MERCHANT_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "قوشۇمچە ئۇچۇرلار",
              DESC: "چىقىمداننىڭ قوشۇمچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "ئاساسىي ئۇچۇرلار",
              DESC: "چىقىمدان ئاساسىي ئۇچۇرلىرى"
            }
          },
          LABEL: {
            mch_id: "چىقىمدان ئايدىسى",
            key: "چىقىمدان ئېغىز ئاچقۇچى",
            cert_path: "چىقىمدان SSL ھۆججىتى",
            key_path: "چىقىمدان SSL ئاچقۇچ ھۆججىتى",
            title_ug: "چىقىمدان ئۇيغۇرچە نامى",
            title_cn: "چىقىمدان خەنزۇچە نامى",
            describe_ug: "ئۇيغۇرچە قىسقىچە چۈشەنچىسى",
            describe_cn: "خەنزۇچە قىسقىچە چۈشەنچىسى",
            cert_uploader: "ھۆججەت تاللاڭ",
            generate: "ھاسىللاش",
          },
          PLACEHOLDER: {
            mch_id: "چىقىمدان ئايدىسىنى تولدۇرۇڭ",
            key: "چىقىمدان ئېغىز ئاچقۇچىنى تولدۇرۇڭ",
            cert_path: "چىقىمدان SSL ھۆججىتىنى يوللاڭ",
            key_path: "چىقىمدان SSL ئاچقۇچ ھۆججىتىنى يوللاڭ",
            title_ug: "چىقىمدان ئۇيغۇرچە نامىنى تولدۇرۇڭ",
            title_cn: "چىقىمدان خەنزۇچە نامىنى تولدۇرۇڭ",
            describe_ug: "ئۇيغۇرچە قىسقىچە چۈشەنچىسىنى تولدۇرۇڭ",
            describe_cn: "خەنزۇچە قىسقىچە چۈشەنچىسىنى تولدۇرۇڭ",
          },
          FEEDBACK: {
            mch_id: "چىقىمدان ئايدىسىنى چوقۇم تولدۇرىسىز",
            key: "چىقىمدان ئېغىز ئاچقۇچىنى چوقۇم تولدۇرىسىز",
            cert_path: "چىقىمدان SSL ھۆججىتىنى چوقۇم يوللايسىز",
            key_path: "چىقىمدان SSL ئاچقۇچ ھۆججىتىنى چوقۇم يوللايسىز",
            title_ug: "چىقىمدان ئۇيغۇرچە نامىنى چوقۇم تولدۇرىسىز",
            title_cn: "چىقىمدان خەنزۇچە نامىنى چوقۇم تولدۇرىسىز",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          },
        },
      },
      MEDICAL_PAYMENT_HISTORY_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "پۇل ئۇرۇش خاتىرىسى ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "پۇل ئۇرۇش خاتىرىسى خەنزۇچە ئۇچۇرلىرى"
            }
          },
          LABEL: {
            describe: "قىسقىچە چۈشەنچە",
            amount: "سوممىسى",
            service_fee: "مۇلازىمەت ھەققى",
            ticket_image: "بانكا پۇل ئۇرۇلغان تالون رەسىمى",
            payment_at: "پۇل ئۇرۇلغان ۋاقتى",
          },
          PLACEHOLDER: {
            describe_ug: "ئۇيغۇرچە قىسقىچە چۈشەنچە مەزمۇنىنى يېزىڭ",
            describe_cn: "خەنزۇچە قىسقىچە چۈشەنچە مەزمۇنىنى يېزىڭ",
            amount: "پۇل سوممىسىنى كىرگۈزۈڭ",
            service_fee: "بۇ قېتىمقى پۇل ئۇرۇش مۇلازىمەت ھەققى سوممىسىنى كىرگۈزۈڭ",
            ticket_image: "بانكا پۇل ئۇرۇلغان تالون رەسىمىنى يوللاڭ",
            payment_at: "پۇل ئۇرۇلغان ۋاقىتنى تاللاڭ",
          },
          FEEDBACK: {
            describe: "قىسقىچە چۈشەنچە مەزمۇنىنى چوقۇم يېزىڭ",
            amount: "ئ‍ۇرۇلغان پۇل سوممىسىنى چوقۇم كىرگۈزۈڭ",
            ticket_image: "بانكا پۇل ئۇرۇلغان تالون رەسىمىنى چوقۇم يوللايسىز",
            payment_at: "پۇل ئۇرۇلغان ۋاقىتنى تاللاڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        },
      },
      PROGRESS_HISTORY_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئۇيغۇرچە ئۇچۇرلار",
              DESC: "ئىجرا خاتىرىسى ئۇيغۇرچە ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "خەنزۇچە ئۇچۇرلار",
              DESC: "ئىجرا خاتىرىسى خەنزۇچە ئۇچۇرلىرى"
            }
          },
          LABEL: {
            title: "ماۋزو",
            describe: "قىسقىچە مەزمۇن",
            content: "تۇلۇق مەزمۇن",
            amount: "سوممىسى",
            total: "كىشى سانى",
            cover_image: "ئالبوم رەسىمى",
            ticket_image: "پۇل ئۇرۇلغان تالون رەسىمى"
          },
          PLACEHOLDER: {
            title_ug: "ئۇيغۇرچە ماۋزونى يېزىڭ",
            title_cn: "خەنزۇچە ماۋزونى يېزىڭ",
            describe_ug: "ئۇيغۇرچە قىسقىچە مەزمۇننى يېزىڭ",
            describe_cn: "خەنزۇچە قىسقىچە مەزمۇننى يېزىڭ",
            content_ug: "ئۇيغۇرچە تۇلۇق مەزمۇنىنى يېزىڭ",
            content_cn: "خەنزۇچە تۇلۇق مەزمۇنىنى يېزىڭ",
            amount: "پۇل سوممىسىنى كىرگۈزۈڭ",
            total: "بۇ قېتىم ياردەمگە ئېرىشكەن كىشى سانىنى كىرگۈزۈڭ",
            cover_image: "ئالبوم رەسىمىنى يوللاڭ",
            ticket_image: "پۇل ئۇرۇلغان تالون رەسىمىنى يوللاڭ",
          },
          FEEDBACK: {
            title: "ماۋزونى چوقۇم كىرگۈزۈڭ",
            describe: "قىسقىچە مەزمۇننى چوقۇم يېزىڭ",
            content: "تۇلۇق مەزمۇنىنى چوقۇم يېزىڭ",
            amount: "ئ‍ۇرۇلغان پۇل سوممىسىنى چوقۇم كىرگۈزۈڭ",
            total: "بۇ قېتىم ياردەمگە ئېرىشكەن كىشى سانىنى چوقۇم كىرگۈزۈڭ",
            cover_image: "ئالبوم رەسىمىنى چوقۇم يوللايسىز",
            ticket_image: "پۇل ئۇرۇلغان تالون رەسىمىنى چوقۇم يوللايسىز",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        },
      },
      NAVIGATION_FORM: {
        FORM: {
          LABEL: {
            VIEW_TYPE: {
              BANNER: "ئايلانما ئېلان",
              LANDSCAPE: "لەيلىمە ئېلان"
            },
            TARGET_OPTION: {
              article: "مەزمۇن",
              mp: "ئەپچاق",
              external_link: "سىرتقى ئۇلانما",
            },
            IMAGE_DESC: {
              banner: "ئايلانما ئېلان رەسىمىگە ئادەتتە كەڭ رەسىم يوللاش تەۋسىيە قىلىنىدۇ. كەڭلىكنى 750 پېكسېلدا كونترول قىلسىڭىز بىر قەدەر ياخشى .",
              landscape: "لەيلىمە ئېلان رەسىمىگە ئادەتتە تىك ھالەتتىكى رەسىمنى يوللاشنى تەۋسىيە قىلىمىز. ئېگىزلىك كەڭلىكتىن چوڭراق بولغىنى بىر قەدەر ياخشى .",
              navigation: "تىزىملىك سىنبەلگىسى ئادەتتە كەڭلىك ئېگىزلىك 1:1 نىسبەتتىكى رەسىم يوللاشنى تەۋسىيە قىلىمىز. يەنى ئېگىزلىك ۋە كەڭلىك ئوخشاش چوڭلۇقتا بولغان رەسىمنى يوللاڭ، رازمېرى 100 پېكسىل بولسا بىر قەدەر مۇۋاپىق."
            },
            BELONGS: {
              any: "ھەممە بەتتە",
              home: "باشبەت",
              show: "تەپسىلات بېتى",
              member: "ئەزالار مەركىزى",
            },
            FEEDBACK_LABEL: {
              str_1: "سول تەرەپتىكى كونۇپكىنى بېسىپ %s تاللاڭ",
              str_2: "%s كىرگۈزۈڭ",
              article: "مەزمۇن",
              mp: "ئەپچاق 页面路径 سىنى",
              external_link: "تور بەت ئادېرسىنى"
            },
            TARGET_LABEL: {
              article: "مەزمۇن تاللاڭ",
              mp: "ئەپچاق بەت ئادېرسىنى تولدۇرۇڭ",
              external_link: "ئۇلانما ئادېرسىنى تولدۇرۇڭ"
            },
            UPLOADER_LABEL: {
              banner: "ئېلان رەسىمى",
              navigation: "تىزىملىك سىنبەلگىسى",
              label: "تاللاڭ"
            },
            OPTION_DESC: {
              article: "تاللاپ بەرگەن مەزمۇنغا ",
              mp: "ئۇچۇرى تولدۇرۇلغان ئەپچاققا ",
              external_link: "تولدۇرۇپ بەرگەن سىرتقى ئۇلانمىغا ",
              str: "%s رەسىمىنى چەككەندە %s  ئاتلايدۇ"
            },
            VIEW_TYPE_TITLE: {
              banner: "ئېلان",
              navigation: "تىزىملىك",
              landscape: "لەيلىمە ئېلان",
            },
            TO_LABEL: {
              article: "مەزمۇن تاللاڭ",
              mp: "ئەپچاق بەت ئادېرسى",
              external_link: "سىرتقى ئۇلانما ئادېرسى",
            },
            BUTTON: {
              choose: "تاللاڭ"
            },
            target: "تۈرى",
            view_type: "شەكلى",
            background: "ماۋزو رەڭ نۇمۇرى",
            title: "ماۋزوسى",
            belongs_t: "ئېلان تەۋە بەتلەر",
            article_choose_modal_title: "مەزمۇن تاللاڭ",
          },
          PLACEHOLDER: {
            target_option_mp: "ئەپچاق AppId سىنى كىرگۈزۈڭ",
            background: " ماۋزو رەڭگىنى تاللاڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ",
            title: "ماۋزوسىنى كىرگۈزۈڭ"
          },
          FEEDBACK: {
            target_option_mp: "ئەپچاق AppId سىنى چوقۇم كىرگۈزىسىز",
            target: "تۈرىنى تاللاڭ",
            title: "ماۋزوسىنى كىرگۈزۈڭ"
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          },
        },
      },
      ADMIN_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "ئاساسى ئۇچۇرلار",
              DESC: "باشقۇرغۇچى ئاساسى ئۇچۇرلىرى"
            },
            TWO: {
              TITLE: "ئورگان ئۇچۇرى",
              DESC: "باشقۇرغۇچى تەۋە ئورگان ئۇچۇرلىرى"
            }
          },
          LABEL: {
            name: "باشقۇرغۇچىلىق نامى",
            phone: "ئ‍الاقىلىشىش نۇمۇرى",
            password: "پارول",
            password_confirmation: "قايتا پارول",
            regulators: "ئورگان",
            actuators: "ئىجرا ئورگان",
            merchants: "چىقىمدان",
            project_leaders: "تۈر مەسئۇللىرى",
            nickname: "تور نامى",
            generate: "ھاسىللاش",
            super: "ئالى ھوقوقلۇق باشقۇرغۇچىمۇ؟",
            sub_account: "قوشۇمچە ئاككونتمۇ؟",
            multiSelector: {
              placeholder: "تەۋە ئورگاننى تاللاڭ",
              selectLabel: "",
              selectedLabel: "تاللانغان",
              deselectLabel: "",
            },
            singleSelector: {
              placeholder: "تەۋە ئىجرا ئورگاننى تاللاڭ",
              selectLabel: "",
              selectedLabel: "تاللانغان",
              deselectLabel: "",
            },
            merchantSelector: {
              placeholder: "چىقىمدان تاللاڭ",
              selectLabel: "",
              selectedLabel: "تاللانغان",
              deselectLabel: "",
            },
            projectLeaderSelector: {
              placeholder: "تۈر مەسئۇللىرىنى تاللاڭ",
              selectLabel: "",
              selectedLabel: "تاللانغان",
              deselectLabel: "",
            },
          },
          PLACEHOLDER: {
            name: "باشقۇرغۇچىلىق نامىنى تولدۇرۇڭ",
            phone: "ئ‍الاقىلىشىش نۇمۇرىنى تولدۇرۇڭ",
            password: "پارول دەسلەپكى قىممىتىنى تولدۇرۇڭ",
            password_confirmation: "پارول دەسلەپكى قىممىتىنى تولدۇرۇڭ",
            regulators: "تەۋە ئورگاننى تاللاڭ",
            actuators: "تەۋە ئىجرا ئورگاننى تاللاڭ",
            merchants: "ئىشلىتەلەيدىغان چىقىمدانلارنى تاللاڭ",
            project_leaders: "تۈر مەسئۇللىرىنى تاللاڭ",
            nickname: "تور نامىنى تولدۇرۇڭ"
          },
          FEEDBACK:{
            name: "باشقۇرغۇچىلىق نامىنى چوقۇم تولدۇرۇڭ",
            phone: "ئ‍الاقىلىشىش نۇمۇرىنى چوقۇم تولدۇرۇڭ",
            password: "پارول دەسلەپكى قىممىتىنى چوقۇم تولدۇرۇڭ",
            password_confirmation: "پارول دەسلەپكى قىممىتى بىلەن ئوخشاش بولسۇن",
            regulators: "مۇناسىۋەتلىك ئورگاننى چوقۇم تاللاڭ",
            actuators: "مۇناسىۋەتلىك ئىجرا ئورگاننى چوقۇم تاللاڭ",
            merchants: "ئىشلىتەلەيدىغان چىقىمدانلارنى چوقۇم تاللاڭ",
            project_leaders: "تۈر مەسئۇللىرىنى چوقۇم تاللاڭ",
          },
          TIPS: {
            EDIT_SUCCESS: "تەھىرىرلەش مۇۋاپىقىيەتلىك بولدى",
            VALIDATION_TIPS: "تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ"
          }
        }
      },

      COPY_FORM: {
        COPY: {
          LABEL: {},
          PLACEHOLDER: {},
          FEEDBACK: {},
          TIPS: {},
        },
      },
    },

    ITEMS: {
      ARTICLE: {
        RAISE_ITEM: {
          ACTIONS: {
            TEXT: "مەشغۇلاتلار",
            EDIT: "تەھرىرلەش",
            DESCRIPTION: "مەزمۇن تەپسىلاتى",
            STATUS: "ئىئانە ھالىتى",
            STATISTICS: "سىتاستىكا",
            ALLOCATION: "تەقسىملەش",
            COPY_LINK: "ئۇلانما كۆچۈرۈش",
            COPY_H5_LINK: "سالون بەت ئۇلانمىسى",
            ALL_NOTIFICATION: "ئۇقتۇرۇش باشقۇرۇش",
            ADD_NOTIFICATION: "ئۇقتۇرۇش قوشۇش",
            COMMENT: "ئىنكاس باشقۇرۇش",
            ALL_WITNESS: "ئىسپاتلىق سۆزى باشقۇرۇش",
            PAYMENT_HISTORY: "پۇل ئۇرۇش خاتىرىسى باشقۇرۇش",
          },
          INFO: {
            DESC: "قىسقىچە چۈشەنچە قوشۇلمىغان، قىسقىچە چۈشەنچە قوشىشىڭىزنى تەۋسىيە قىلىمىز",
            PENDING_REVIEW: "تەھرىرلەنگەن مەزمۇن بار",
            PENDING_AUDIT: "تېخى تەستىقلانمىغان",
            EXTERNAL: "بۇ بىر سىرتقى ئۇلانما",
          },
          FOOTER: {
            RAISE: "نىشان سومما",
            TOTAL: "يىغىلغىنى",
            CASHING: "نەقلەشتۈرۈلگىنى",
            COMMENTS_COUNT: "دانە ئىنكاس",
            NO_COMMENTS: "تېخى ئىنكاس چۈشمىدى",
            COMMENT_MANAGER: "باشقۇرۇش",
            DONORS_COUNT: "نەپەر ئىئانە قىلغۇچى بار",
          },
          LABEL: {
            NO_TITLE: 'ھازىرچە ماۋزو قويۇلمىغان',
            NO_FULL_NAME: 'نامسىز',
          },
          TIPS: {
            COPY_SUCCESS: "ئۇلانما كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى",
            COPY_FAIL: "ئۇلانما كۆچۈرۈشتە مەسىلە كۆرۈلدى",
          }
        },
        PROJECT_ITEM: {
          ACTIONS: {
            TEXT: "مەشغۇلاتلار",
            DESCRIPTION: "مەزمۇن تەپسىلاتى",
            STATUS: "ئىئانە ھالىتى",
            STATISTICS: "سىتاستىكا",
            ALLOCATION: "تەقسىملەش",
            COPY_LINK: "ئۇلانما كۆچۈرۈش",
            COPY_H5_LINK: "سالون بەت ئۇلانمىسى",
            ALL_NOTIFICATION: "ئۇقتۇرۇش باشقۇرۇش",
            ADD_NOTIFICATION: "ئۇقتۇرۇش قوشۇش",
            COMMENT: "ئىنكاس باشقۇرۇش",
            ALL_WITNESS: "ئىسپاتلىق سۆزى باشقۇرۇش",
            PROGRESS_HISTORY: "تۈر ئىجرا خاتىرىسى باشقۇرۇش",
          },
          INFO: {
            DESC: "قىسقىچە چۈشەنچە قوشۇلمىغان، قىسقىچە چۈشەنچە قوشىشىڭىزنى تەۋسىيە قىلىمىز",
            PENDING_REVIEW: "تەھرىرلەنگەن مەزمۇن بار",
            PENDING_AUDIT: "تېخى تەستىقلانمىغان"
          },
          FOOTER: {
            RAISE: "نىشان سومما",
            TOTAL: "يىغىلغىنى",
            GROUP: "تارماق ئەترەت",
            NO_GROUP: "تارماق ئەترەت قۇرۇلمىغان",
            GROUP_LIMIT_SYMBOL: "ئەترەت",
            COMMENTS_COUNT: "دانە ئىنكاس",
            NO_COMMENTS: "تېخى ئىنكاس چۈشمىدى",
            COMMENT_MANAGER: "باشقۇرۇش",
            DONORS_COUNT: "نەپەر ئىئانە قىلغۇچى بار",
          },
          LABEL: {
            NO_TITLE: 'ھازىرچە ماۋزو قويۇلمىغان',
            NO_FULL_NAME: 'نامسىز',
            TITLE: "بۇ بولسا مەخسۇس تۈر"
          },
          TIPS: {
            COPY_SUCCESS: "ئۇلانما كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى",
            COPY_FAIL: "ئۇلانما كۆچۈرۈشتە مەسىلە كۆرۈلدى",
          }
        },
        VERSION_ITEM: {
          ACTIONS: {
            EDIT: "تەھرىرلەش",
            COPY: "نۇسقىلاپ تەھرىرلەش",
            ONLINE: "تورغا چىقىرىش",
          },
          INFO: {
            DESC: "قىسقىچە چۈشەنچە قوشۇلمىغان، قىسقىچە چۈشەنچە قوشىشىڭىزنى تەۋسىيە قىلىمىز",
            CURRENT_ONLINE: "نۆۋەتتە توردىكى مەزمۇن",
            ARCHIVED: "ئارخىپقا ساقلانغان مەزمۇن",
            PENDING_ONLINE: "تەستىقلاپ تورغا چىقىرىشنى ساقلاۋاتقان مەزمۇن",
          },
          LABEL: {
            NO_TITLE: 'ھازىرچە ماۋزو قويۇلمىغان',
            NO_FULL_NAME: 'نامسىز',
            FULL_NAME: "ئىسىم-فامىلىسى",
            PHONE: "تېلفۇن نۇمۇرى",
            ID_CARD:"كىملىك نۇمۇرى",
            PATIENT_ADDRESS:"تۇرۇشلۇق ئورنى",
            HOSPITAL: "دوختۇرخانا",
            DEPARTMENT: "ئورۇنلاشقان بۆلۈم",
            HOSPITAL_ADDRESS: "دوختۇرخانا ئادېرسى",
            RAISE_ADDRESS: "ئورۇنلاشقان ياتاق",
            PATIENT_ID: "بالنېست نۇمۇرى",
            RAISE: "نىشان سومما",
            CREATED_AT: "يوللانغان ۋاقتى",
          },
          TIPS: {
            ONLINE: "مەزكۇر نەشىردىكى ئۇچۇر مەزمۇنىنى تورغا چىقىرامسىز ؟",
            COPY: "نۆۋەتتە تورغا چىقىرىلغان مەزمۇننى نۇسقىلاپ تەھرىرلەش ئېلىپ بارامسىز؟ (توردىكى مەزمۇنغا ھىچقانداق تەسىرى يوق.)",
          }
        }
      },
      USER: {
        ACCOUNT: {
          LABEL: {
            created_at: "تۇنجى قېتىم كىرگەن ۋاقتى",
            no_data: "ھازىرچە خاتىرە يوق",
            copy_openid_success: "نۆۋەتتىكى سالاھىيەت تىزىملىكىدىن openid ئۇچۇرىنى كۆچۈردىڭىز!"
          }
        }
      },
      WIDGETS: {
        ARTICLE_STATISTICS: {
          TITLE: "ئىئانە ئۇمۇمىي سىتاستىكىسى",
          LABEL: {
            total_fee_desc: "ھازىرغىچە يىغىلغان ئۇمۇمىي سومما",
            $: "يۈەن"
          }
        }
      }
    },

    MODALS: {
      USER: {
        SWITCH_LOCK: {
          TITLE: "قارا تىزىملىك ئۇچۇرى يېڭىلاش",
          BUTTON: {
            SUBMIT: "ساقلاش",
          },
          LABEL: {
            OUT_REASON_LABEL: "بىكار قىلىش سەۋەبى",
            OUT_REASON_TIPS: "نۆۋەتتىكى ئەزانى قارا تىزىملىكتىن بىكار قىلماقچى بولىۋاتىسىز، سەۋەبىنى يازمىسىڭىزمۇ بولىدۇ",
            IN_REASON_LABEL: "قوشۇش سەۋەبى",
            IN_REASON_TIPS: "قارا تىزىملىككە قوشۇش سەۋەبىنى يېزىڭ",
          }
        },
        ACCOUNT: {
          title: "ئەزا سالاھىيىتى",
          BUTTON: {
            confirm: "بىلدىم",
          },
          LABEL: {
            DESC: {
              miniprogram: "ئەپچاقتىكى سالاھىيىتى",
              officialAccount: "سالون بەتتىكى سالاھىيىتى",
              app: "ئەپتىكى سالاھىيىتى"
            }
          }
        }
      },
      RAISE: {
        STATUS: {
          TITLE: "ئىئانە ھالىتىنى تاللاڭ",
          OPTIONS: {
            ONE: {
              title: "نورمال",
              describe: "ئىئانە توپلاشنى نورمال داۋاملاشتۇرۇش ئۈچۈن مەزكۇر تاللاشنى تاللاپ نورمال ئىئانە توپلاشقا بولىدۇ.",
            },
            TWO: {
              title: "ئاياقلاشتۇرۇش",
              describe: "مەزكۇر مۇراجەتنامىگە كېتەرلىك پۇل سوممىسى يىغىلىپ بولۇنغاندا، مەزكۇر تاللاشنى تاللاپ ئىئانە يىغىشنى توختىتىشقا بولىدۇ.",
            },
            THREE: {
              title: "توختىتىش",
              describe: "مۇراجەتنامە ئىجرا قىلىنىش باسقۇچىدا ھەرقانداق بىر ساقلانغىلى بولمايدىغان سەۋەپ بىلەن مۇراجەت يىغىشنى توختىتىشقا توغرا كەلگەندە تاللايمىز.",
            },
          },
          LABEL: {
            articleModalTitle: "ئاتلايدىغان مۇراجەتنامىنى تاللاڭ",
            reason: "سەۋەبى",
            target_id: "ئاتلايدىغان مۇراجەتنامە"
          },
          PLACEHOLDER: {
            reason_ug: "توختىتىش سەۋەبىنى ئۇيغۇرچە يېزىڭ، ياكى قۇرۇق قويسىڭىزمۇ بولىدۇ",
            reason_cn: "توختىتىش سەۋەبىنى خەنزۇچە يېزىڭ، ياكى قۇرۇق قويسىڭىزمۇ بولىدۇ",
            target_id: "ئاتلايدىغان باشقا مۇراجەتنامىنى تاللاڭ، ئاتلاتقۇزمىسىڭىز تاللىمىسىڭىزمۇ بولىدۇ"
          },
          FEEDBACK: {
            reason_ug: "خەنزۇچە سەۋەبىنى تولدۇرغاندا ئۇيغۇرچىسىنىمۇ تولدۇرىشىڭىز كېرەك",
            reason_cn: "ئۇيغۇرچە سەۋەبىنى تولدۇرغاندا خەنزۇچىسىنىمۇ تولدۇرىشىڭىز كېرەك",
          },
          BUTTON: {
            CHOOSE: "تاللاش",
            CLEAR: "تازلاش"
          }
        }
      },
      SUBJECT_CREATE_TYPE: {
        BUTTONS: {
          RAISE: "مۇراجەتنامە قوشۇش",
          PROJECT: "مەخسۇس تۈر قوشۇش",
          EXTERNAL: "سىرتقى ئۇلانما قوشۇش"
        },
        TITLE: "قوشماقچى بولغان ئۇچۇر تۈرىنى تاللاڭ"
      }
    },

    MINIPROGRAM_SCAN_APP_CODE: {
      LABEL: {
        tips: {
          init: "ئۈندىداردا سىكاننېرلاپ سالاھىيىتىڭىزنى دەلىللەڭ",
          scanned: "ئەسكەرتمىگە ئاساسەن مەشغۇلات قىلىڭ",
          completed: "ھوقوق بېرىش مۇۋاپىقىيەتلىك بولدى",
          refresh: "چاچما كودنىڭ ۋاقتى ئۆتۈپ كەتتى",
          unidentified: "سالاھىيىتىڭىزنى دەلىللىيەلمىدۇق، قايتا سىناڭ",
        }
      },
      BUTTON: {
        refresh: "يېڭىلاش",
        redo: "ئالماشتۇرۇش",
      }
    },

    THEME: {
      TOPBAR: {
        USER: {
          title: "ئارخىبىم",
          action: {
            edit: "ئارخىب تەھرىرلەش",
            exit: "چىكىنىش"
          }
        }
      }
    },

    IMAGE_UPLOADER: {
      LABEL: {
        BUTTON: "رەسىم تاللاڭ",
        InvalidField: "رەسىم فورماتىدا خاتالىق بار",
        FileWaitingForSize: "رەسىم سىغىمى دەللىلىنىۋاتىدۇ",
        FileSizeNotAvailable: "مەزكۇر رەسىم ئۆچۈرۈلگەن بولىشى مۇمكىن",
        FileLoading: "يۈكلەۋاتىدۇ ...",
        FileLoadError: "يۈكلەش مەغلۇپ بولدى",
        FileProcessing: "يوللىنىۋاتىدۇ ...",
        FileProcessingComplete: "يوللىنىپ بولۇندى",
        FileProcessingAborted: "يوللاش بىكار قىلىندى",
        FileProcessingError: "يوللاش مەغلۇپ بولدى",
        FileProcessingRevertError: "ئۆچۈرۈش مەغلۇپ بولدى",
        FileRemoveError: "ئۆچۈرۈش مەغلۇپ بولدى",
        TapToCancel: "بېسىپ يوللاشنى بىكار قىلىڭ",
        TapToRetry: "بېسىپ قايتا سىناڭ",
        TapToUndo: "ئۆچۈرۈش",
        ButtonRemoveItem: "ئۆچۈرۈش",
        ButtonAbortItemLoad: "بىكار قىلىش",
        ButtonRetryItemLoad: "قايتا سىناش",
        ButtonAbortItemProcessing: "بىكار قىلىش",
        ButtonRetryItemProcessing: "قايتا سىناش",
        ButtonProcessItem: "يوللاش",
        ValidateSizeLabelImageSizeTooSmall: "رەسىم بەك كىچىك",
        ValidateSizeLabelImageSizeTooBig: "رەسىم بەك چوڭ",
        ValidateSizeLabelExpectedMinSize: "رەسىمنىڭ رازمېرى بەلگىلەنگەن رازمېردىن كىچىك بولمىسۇن",
        ValidateSizeLabelExpectedMaxSize: "رەسىمنىڭ رازمېرى بەلگىلەنگەن رازمېردىن چوڭ بولمىسۇن",
        minWidthTips: "بۇ يەردىكى كەڭلىك ئەڭ كىچىك كەڭلىكنى كۆرسىتىدۇ. ئەمەلىي ئەھۋالغا ئاساسەن كەڭلىكنى كونترول قىلىڭ",
        minHeightTips: "بۇ يەردىكى ئېگىزلىك ئەڭ كىچىك ئېگىزلىكنى كۆرسىتىدۇ. ئەمەلىي ئەھۋالغا ئاساسەن ئېگىزلىكنى كونترول قىلىڭ",
        minWidth: "كەڭلىك {width} پېكسىل",
        minHeight: "ئېگىزلىك {height} پېكسىل",
      }
    },
    SIMPLE_EDITOR: {
      TYPES: {
        text: "خەت قوشۇش",
        image: "رەسىم قوشۇش",
        video: "فىلىم قوشۇش",
        voice: "ئاۋاز قوشۇش",
      },
      LABEL: {
        text: "خەتلىك مەزمۇن",
        image: "رەسىملەر",
        video: "فىلىم ئادېرسى",
        voice: "ئاۋاز ھۆججىتى",
      },
      PLACEHOLDER: {
        text: "خەتلىك مەزمۇننى كىرگۈزۈڭ",
        video: "فىلىمنىڭ بىۋاستە قويۇلۇش ئادېرسىنى كىرگۈزۈڭ",
        voice: "ئاۋاز ھۆججىتىنىڭ بىۋاستە قويۇلۇش ئادېرسىنى كىرگۈزۈڭ",
      },
      DESCRIPTION: {
        image: "ئەڭ كۆپ بولغاندا 5 پارچە رەسىم يوللىيالايسىز",
      }
    },
    MULTI_UPLOADER: {
      LABEL: {
        RATE_LIMIT: "ئەڭ كۆپ بولغاندا {limit} دانە ھۆججەت يوللاشقا بولىدۇ"
      }
    },
  },

  SWEET_ALERT: {
    SUCCESS: {
      MESSAGE: "مۇۋاپىقىيەتلىك بولدى!",
      CONFIRM_TEXT: "بىلدىم"
    },
    WARNING: {
      MESSAGE: "دېققەت قىلىڭ",
      CONFIRM_TEXT: "بىلدىم"
    },
    ERROR: {
      MESSAGE: "كۈتۈلمىگەن خاتالىق چىقتى!",
      CONFIRM_TEXT: "بىلدىم"
    },
  },
  MSG_BOX: {
    TITLE: "جەزىملەشتۈرەمسىز؟",
    CONFIRM_TEXT: "شۇنداق",
    CANCEL_TEXT: "ياق"
  }
};
