import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_CLASSIFY = 'getClassify'; //
export const GET_CLASSIFIES = 'getClassifies'; //
export const STORE_CLASSIFY = 'storeClassify'; //
export const UPDATE_CLASSIFY = 'updateClassify'; //
// [COPY MUTATIONS]
export const SET_CLASSIFY = 'setClassify'; //
export const SET_CLASSIFIES = 'setClassifies'; //
export const SET_CLASSIFIES_PAGE = 'setClassifiesPage'; //
export const SET_CLASSIFY_ERROR = 'setClassifyError'; //

const state = {
  classify: {},
  classifies: [],
  classifiesMeta: {},
  classifiesPage: {page: 1, belongs: null},
  error: null
};

const mutations = {
  [SET_CLASSIFY_ERROR](state, error){
    state.error = error;
  },
  [SET_CLASSIFY](state, payload){
    state.classify = payload;
  },
  [SET_CLASSIFIES](state, payload){
    state.classifies = payload.data;
    state.classifiesMeta = payload.meta;
  },
  [SET_CLASSIFIES_PAGE](state, payload){
    state.classifiesPage = payload;
  }
};

const actions = {
  [GET_CLASSIFY](context, id){
    return GetAction("manager/classifies", `${id}`, context, SET_CLASSIFY_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_CLASSIFY, response.data );
    }});
  },
  [STORE_CLASSIFY](context, formData){
    return PostAction(`manager/classifies`, formData, context, SET_CLASSIFY_ERROR, { credentials: true } )
  },
  [UPDATE_CLASSIFY](context, { id, formData }){
    return PutAction(`manager/classifies/${id}`, formData, context, SET_CLASSIFY_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_CLASSIFY, response.data );
    }});
  },
  [GET_CLASSIFIES](context, { page= 1, belongs= 'house' } ){
    return GetAction( `manager/classifies?belongs=${belongs}&page=${page}`,
    ``,
    context,
    SET_CLASSIFY_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_CLASSIFIES, response.data );
        context.commit( SET_CLASSIFIES_PAGE, { page, belongs } );
      }
    } );
  }
};

const getters = {
  classify: (state)=> state.classify,
  classifies: (state)=> state.classifies,
  classifiesMeta: (state)=> state.classifiesMeta,
  classifiesPage: (state)=> state.classifiesPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
