import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ARTICLE_NOTIFICATION = 'getArticleNotification'; //
export const GET_ARTICLE_NOTIFICATIONS = 'getArticleNotifications'; //
export const STORE_ARTICLE_NOTIFICATION = 'storeArticleNotification'; //
export const UPDATE_ARTICLE_NOTIFICATION = 'updateArticleNotification'; //
// [COPY MUTATIONS]
export const SET_ARTICLE_NOTIFICATION = 'setArticleNotification'; //
export const SET_ARTICLE_NOTIFICATIONS = 'setArticleNotifications'; //
export const SET_ARTICLE_NOTIFICATIONS_PAGE = 'setArticleNotificationsPage'; //
export const SET_ARTICLE_NOTIFICATION_ERROR = 'setArticleNotificationError'; //

const state = {
  articleNotification: {},
  articleNotifications: [],
  articleNotificationsMeta: {},
  articleNotificationsPage: 1,
  articleNotificationError: null
};

const mutations = {
  [SET_ARTICLE_NOTIFICATION_ERROR](state, error){
    state.articleNotificationError = error;
  },
  [SET_ARTICLE_NOTIFICATION](state, payload){
    state.articleNotification = payload;
  },
  [SET_ARTICLE_NOTIFICATIONS](state, payload){
    state.articleNotifications = payload.data;
    state.articleNotificationsMeta = payload.meta;
  },
  [SET_ARTICLE_NOTIFICATIONS_PAGE](state, payload){
    state.articleNotificationsPage = payload;
  },
};

const actions = {
  [GET_ARTICLE_NOTIFICATION](context, id){
    return GetAction("manager/article-notifications", `${id}`, context, SET_ARTICLE_NOTIFICATION_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_NOTIFICATION, response.data );
    }});
  },
  [STORE_ARTICLE_NOTIFICATION](context, { article_id, formData }){
    return PostAction(`manager/${article_id}/article-notifications`, formData, context, SET_ARTICLE_NOTIFICATION_ERROR, { credentials: true } )
  },
  [UPDATE_ARTICLE_NOTIFICATION](context, { id, formData }){
    return PutAction(`manager/article-notifications/${id}`, formData, context, SET_ARTICLE_NOTIFICATION_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_NOTIFICATION, response.data );
    }});
  },
  [GET_ARTICLE_NOTIFICATIONS](context, { article_id, page = 1 } ){
    return GetAction( `manager/${article_id}/article-notifications?page=${page}`,
    ``,
    context,
    SET_ARTICLE_NOTIFICATION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_NOTIFICATIONS, response.data );
        context.commit( SET_ARTICLE_NOTIFICATIONS_PAGE, page );
      }
    } );
  },
};

const getters = {
  articleNotification: (state)=> state.articleNotification,
  articleNotifications: (state)=> state.articleNotifications,
  articleNotificationsMeta: (state)=> state.articleNotificationsMeta,
  articleNotificationsPage: (state)=> state.articleNotificationsPage,
  articleNotificationError: (state)=> state.articleNotificationError,
};

export default {
  state,
  mutations,
  actions,
  getters
}
