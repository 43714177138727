export default [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/theme/Base"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard.vue"),
        meta: { requiresAuth: true }
      },
      {
        name: "advert",
        path: "/adverts",
        meta: { requiresAuth: true, title: 'ئېلان باشقۇرۇش' },
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        redirect: 'home',
        children: [
          {
            name: 'advert.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'بارلىق ئېلانلار',requiresSuper: true },
            component: () => import(/* webpackChunkName: "advert" */ "@/views/pages/advert/home"),
          },
          {
            name: 'advert.store',
            path: 'store',
            meta: { requiresAuth: true, title: 'يېڭىدىن ئېلان قوشۇش',requiresSuper: true },
            component: () => import(/* webpackChunkName: "advert" */ "@/views/pages/advert/create"),
          },
          {
            name: 'advert.edit',
            path: ':id/edit',
            meta: { requiresAuth: true, title: 'ئېلان ئۇچۇرىنى تەھرىرلەش',requiresSuper: true },
            component: () => import(/* webpackChunkName: "advert" */ "@/views/pages/advert/edit"),
          },
        ]
      },
      {
        name: "navigation",
        path: "/navigations",
        redirect: 'home',
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        children: [
          {
            name: "navigation.home",
            path: "home",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/navigation/home"),
            meta: { requiresAuth: true, title: 'بارلىق باشلاش تىزىملىكى',requiresSuper: true }
          },
          {
            name: "navigation.store",
            path: "store",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/navigation/create"),
            meta: { requiresAuth: true, title: 'يېڭىدىن باشلاش تىزىملىكى قوشۇش',requiresSuper: true }
          },
          {
            name: "navigation.edit",
            path: ":id/edit",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/navigation/edit"),
            meta: { requiresAuth: true, title: 'تىزىملىك ئۇچۇرى تەھرىرلەش',requiresSuper: true }
          },
        ]
      },
      {
        name: "user",
        path: "/users",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "user.home",
            meta: { requiresAuth: true, title: 'ئەزا باشقۇرۇش',requiresSuper: true },
            component: () => import(/* webpackChunkName: "user" */ "../views/pages/user/home"),
          },
          {
            path: ":id/show",
            name: "user.show",
            meta: { requiresAuth: true, title: 'ئەزا تەپسىلاتى',requiresSuper: true },
            component: () => import(/* webpackChunkName: "user" */ "../views/pages/user/show"),
          },
          {
            path: ":id/donations",
            name: "user.donations",
            meta: { requiresAuth: true, title: 'ئىئانە تەپسىلاتى',requiresSuper: true },
            component: () => import(/* webpackChunkName: "user" */ "../views/pages/user/donation"),
          },
        ]
      },
      {
        name: "subscribe_message",
        path: "/subscribe_messages",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            name: 'subscribe_message.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش',requiresSuper: true },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/subscribeMessage/home"),
          },
          {
            name: 'subscribe_message.history',
            path: ':id/history',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش خاتىرىسى',requiresSuper: true },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/subscribeMessage/history"),
          },
          {
            name: 'subscribe_message.send',
            path: ':id/send',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش',requiresSuper: true },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/subscribeMessage/send"),
          },
        ]
      },
      {
        name: "profile",
        path: "/profile",
        redirect: "/profile/edit",
        component: ()=> import("@/views/pages/profile"),
        children: [
          {
            name: "edit.profile",
            path: "edit",
            component: ()=> import("@/views/pages/profile/Edit"),
            meta: { requiresAuth: true }
          },
          {
            name: "change.password",
            path: "change/password",
            component: ()=> import("@/views/pages/profile/ChangePassword"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        name: "setting",
        path: "/settings",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true,requiresSuper: true },
        children: [
          {
            path: "store",
            name: "setting.store",
            component: () => import(/* webpackChunkName: "setting" */ "@/views/pages/setting/create"),
            meta: { requiresAuth: true, requiresSuper: true }
          },
          {
            path: "home",
            name: "setting.home",
            component: () => import(/* webpackChunkName: "setting" */ "@/views/pages/setting/home"),
            meta: { requiresAuth: true, title: 'بارلىق تەڭشەكلەر', requiresSuper: true }
          }
        ]
      },
      {
        name: "protocol",
        path: "/protocol",
        component: () => import(/* webpackChunkName: "protocol" */ "@/views/pages/protocol/Content"),
        meta: { requiresAuth: true }
      },
      {
        name: "tools",
        path: "/tools",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "wechat-info",
            name: "wechatInfo",
            component: () => import(/* webpackChunkName: "tools" */ "@/views/pages/tools/WeChatInfo"),
            meta: { requiresAuth: true }
          },
        ]
      },
      {
        name: "test",
        path: "/test",
        component: () => import("@/views/pages/test"),
        meta: { requiresAuth: true }
      },
      {
        name: "h5",
        path: "/h5",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'subscribe_messages/home',
        children: [
          {
            name: 'h5.subscribe_message.home',
            path: 'subscribe_messages/home',
            meta: { requiresAuth: true, title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/subscribeMessage/home"),
          },
          {
            name: 'h5.subscribe_message.history',
            path: 'subscribe_messages/:id/history',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش خاتىرىسى' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/subscribeMessage/history"),
          },
          {
            name: 'h5.subscribe_message.send',
            path: 'subscribe_messages/:id/send',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/subscribeMessage/send"),
          },
          {
            name: 'h5.menus.home',
            path: 'menus/home',
            meta: { requiresAuth: true, title: 'تىزىملىك ئۇچۇرى باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/menus/home"),
          },
        ]
      },
      {
        name: "comment",
        path: "/comments",
        meta: { requiresAuth: true, title: 'ئىنكاس باشقۇرۇش' },
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        redirect: 'home',
        children: [
          {
            name: 'comment.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'بارلىق ئىنكاسلار' },
            component: () => import(/* webpackChunkName: "comment" */ "@/views/pages/comment/home"),
            children: [
              {
                name: 'comment.list',
                path: 'list/:id',
                meta: { requiresAuth: true, title: 'بارلىق ئىنكاسلار' },
                component: () => import(/* webpackChunkName: "comment" */ "@/views/pages/comment/list"),
                props: true
              },
              {
                name: 'comment.detail',
                path: 'detail/:id',
                meta: { requiresAuth: true, title: 'ئىنكاس تەپسىلاتى' },
                component: () => import(/* webpackChunkName: "comment" */ "@/views/pages/comment/detail"),
                props: true
              }
            ]
          },
        ]
      },
      /* 新的 */
      {
        name: "classify.house",
        path: "/classifies/houses",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: '/classifies/houses/home',
        children: [
          {
            path: "home",
            name: "classify.house.home",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/home"),
            meta: { requiresAuth: true },
            props: { classify: 'house' }
          },
          {
            path: "store",
            name: "classify.house.store",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/store"),
            meta: { requiresAuth: true },
            props: { classify: 'house' }
          },
          {
            path: ":id/edit",
            name: "classify.house.edit",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/edit"),
            meta: { requiresAuth: true },
            props: route=> ( { classify: 'house', id: route.params.id } )
          },
        ]
      },
      {
        name: "classify.car",
        path: "/classifies/cars",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: '/classifies/cars/home',
        children: [
          {
            path: "home",
            name: "classify.car.home",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/home"),
            meta: { requiresAuth: true },
            props: { classify: 'car' }
          },
          {
            path: "store",
            name: "classify.car.store",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/store"),
            meta: { requiresAuth: true },
            props: { classify: 'car' }
          },
          {
            path: ":id/edit",
            name: "classify.car.edit",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/edit"),
            meta: { requiresAuth: true },
            props: route=> ( { classify: 'car', id: route.params.id } )
          },
        ]
      },
      {
        name: "classify.job",
        path: "/classifies/jobs",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: '/jobs/home',
        children: [
          {
            path: "home",
            name: "classify.job.home",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/home"),
            meta: { requiresAuth: true },
            props: { classify: 'job' }
          },
          {
            path: "store",
            name: "classify.job.store",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/store"),
            meta: { requiresAuth: true },
            props: { classify: 'job' }
          },
          {
            path: ":id/edit",
            name: "classify.job.edit",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/edit"),
            meta: { requiresAuth: true },
            props: route=> ( { classify: 'job', id: route.params.id } )
          },
        ]
      },
      {
        name: "classify.source_income",
        path: "/classifies/source_incomes",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: '/classifies/source_incomes/home',
        children: [
          {
            path: "home",
            name: "classify.source_income.home",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/home"),
            meta: { requiresAuth: true },
            props: { classify: 'source_income' }
          },
          {
            path: "store",
            name: "classify.source_income.store",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/store"),
            meta: { requiresAuth: true },
            props: { classify: 'source_income' }
          },
          {
            path: ":id/edit",
            name: "classify.source_income.edit",
            component: () => import(/* webpackChunkName: "classify" */ "@/views/pages/classify/edit"),
            meta: { requiresAuth: true },
            props: route=> ( { classify: 'source_income', id: route.params.id } )
          },
        ]
      },
      /* 医院 */
      {
        name: "hospital",
        path: "/hospitals",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "hospital.home",
            component: () => import(/* webpackChunkName: "hospital" */ "@/views/pages/hospital/home"),
            meta: { requiresAuth: true }
          },
          {
            path: "store",
            name: "hospital.store",
            component: () => import(/* webpackChunkName: "hospital" */ "@/views/pages/hospital/store"),
            meta: { requiresAuth: true }
          },
          {
            path: ":id/edit",
            name: "hospital.edit",
            component: () => import(/* webpackChunkName: "hospital" */ "@/views/pages/hospital/edit"),
            meta: { requiresAuth: true },
            props: true
          },
        ]
      },
      /* 医院科室 */
      {
        name: "department",
        path: "/departments",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "department.home",
            component: () => import(/* webpackChunkName: "department" */ "@/views/pages/hospitalDepartment/home"),
            meta: { requiresAuth: true }
          },
          {
            path: "store",
            name: "department.store",
            component: () => import(/* webpackChunkName: "department" */ "@/views/pages/hospitalDepartment/store"),
            meta: { requiresAuth: true }
          },
          {
            path: ":id/edit",
            name: "department.edit",
            component: () => import(/* webpackChunkName: "department" */ "@/views/pages/hospitalDepartment/edit"),
            meta: { requiresAuth: true },
            props: true
          },
        ]
      },
      /* 监管部门 */
      {
        name: "regulator",
        path: "/regulators",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "regulator.home",
            component: () => import(/* webpackChunkName: "regulator" */ "@/views/pages/regulator/home"),
            meta: { requiresAuth: true }
          },
          {
            path: "store",
            name: "regulator.store",
            component: () => import(/* webpackChunkName: "regulator" */ "@/views/pages/regulator/store"),
            meta: { requiresAuth: true, requiresSuper: true }
          },
          {
            path: ":id/edit",
            name: "regulator.edit",
            component: () => import(/* webpackChunkName: "regulator" */ "@/views/pages/regulator/edit"),
            meta: { requiresAuth: true, requiresSuper: true },
            props: true
          },
        ]
      },
      /* 执行机构 */
      {
        name: "actuator",
        path: "/actuators",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "actuator.home",
            component: () => import(/* webpackChunkName: "actuator" */ "@/views/pages/actuator/home"),
            meta: { requiresAuth: true }
          },
          {
            path: "store",
            name: "actuator.store",
            component: () => import(/* webpackChunkName: "actuator" */ "@/views/pages/actuator/store"),
            meta: { requiresAuth: true, requiresSuper: true }
          },
          {
            path: ":id/edit",
            name: "actuator.edit",
            component: () => import(/* webpackChunkName: "actuator" */ "@/views/pages/actuator/edit"),
            meta: { requiresAuth: true, requiresSuper: true },
            props: true
          },
        ]
      },
      /* 项目负责人 */
      {
        name: "project-leader",
        path: "/project-leaders",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "project-leader.home",
            component: () => import(/* webpackChunkName: "project-leader" */ "@/views/pages/projectLeader/home"),
            meta: { requiresAuth: true }
          },
          {
            path: "store",
            name: "project-leader.store",
            component: () => import(/* webpackChunkName: "project-leader" */ "@/views/pages/projectLeader/store"),
            meta: { requiresAuth: true, requiresSuper: true }
          },
          {
            path: ":id/edit",
            name: "project-leader.edit",
            component: () => import(/* webpackChunkName: "project-leader" */ "@/views/pages/projectLeader/edit"),
            meta: { requiresAuth: true, requiresSuper: true },
            props: true
          },
        ]
      },
      /* 内容 */
      {
        name: "article",
        path: "/articles",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "article.home",
            component: () => import(/* webpackChunkName: "article" */ "@/views/pages/article/home"),
            meta: { requiresAuth: true }
          },
          {
            path: ":id/versions/:type?",
            name: "article.versions",
            component: () => import(/* webpackChunkName: "article" */ "@/views/pages/article/version"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/statistics",
            name: "article.statistics",
            component: () => import(/* webpackChunkName: "article" */ "@/views/pages/article/statistics"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/notifications",
            name: "article.notifications",
            component: () => import(/* webpackChunkName: "notifications" */ "@/views/pages/article/notifications/home"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/notifications/store",
            name: "article.notifications.store",
            component: () => import(/* webpackChunkName: "notifications" */ "@/views/pages/article/notifications/store"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/notifications/edit",
            name: "article.notifications.edit",
            component: () => import(/* webpackChunkName: "notifications" */ "@/views/pages/article/notifications/edit"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/witnesses",
            name: "article.witnesses",
            component: () => import(/* webpackChunkName: "witnesses" */ "@/views/pages/article/witness/home"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/witnesses/store",
            name: "article.witnesses.store",
            component: () => import(/* webpackChunkName: "witnesses" */ "@/views/pages/article/witness/store"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/witnesses/edit",
            name: "article.witnesses.edit",
            component: () => import(/* webpackChunkName: "witnesses" */ "@/views/pages/article/witness/edit"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/medical-payment-histories",
            name: "article.medical-payment-history",
            component: () => import(/* webpackChunkName: "medicalPaymentHistory" */ "@/views/pages/article/medicalPaymentHistory/home"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/medical-payment-histories/store",
            name: "article.medical-payment-history.store",
            component: () => import(/* webpackChunkName: "medicalPaymentHistory" */ "@/views/pages/article/medicalPaymentHistory/store"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/medical-payment-histories/edit",
            name: "article.medical-payment-history.edit",
            component: () => import(/* webpackChunkName: "medicalPaymentHistory" */ "@/views/pages/article/medicalPaymentHistory/edit"),
            meta: { requiresAuth: true },
            props: true
          },
          // 项目进展
          {
            path: ":id/progress-histories",
            name: "article.progress-history",
            component: () => import(/* webpackChunkName: "progressHistory" */ "@/views/pages/article/progressHistory/home"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/progress-histories/store",
            name: "article.progress-history.store",
            component: () => import(/* webpackChunkName: "progressHistory" */ "@/views/pages/article/progressHistory/store"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/progress-histories/edit",
            name: "article.progress-history.edit",
            component: () => import(/* webpackChunkName: "progressHistory" */ "@/views/pages/article/progressHistory/edit"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: "store/:form?",
            name: "article.store",
            component: () => import(/* webpackChunkName: "article" */ "@/views/pages/article/store"),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ":id/edit/:rid/:type?",
            name: "article.edit",
            component: () => import(/* webpackChunkName: "article" */ "@/views/pages/article/edit"),
            meta: { requiresAuth: true },
            props: true
          },
        ]
      },
      /* 商户平台 */
      {
        name: "merchant",
        path: "/merchants",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true, requiresSuper: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "merchant.home",
            component: () => import(/* webpackChunkName: "merchant" */ "@/views/pages/merchant/home"),
            meta: { requiresAuth: true, requiresSuper: true }
          },
          {
            path: "store",
            name: "merchant.store",
            component: () => import(/* webpackChunkName: "merchant" */ "@/views/pages/merchant/store"),
            meta: { requiresAuth: true, requiresSuper: true }
          },
          {
            path: ":id/edit",
            name: "merchant.edit",
            component: () => import(/* webpackChunkName: "merchant" */ "@/views/pages/merchant/edit"),
            meta: { requiresAuth: true, requiresSuper: true },
            props: true
          },
        ]
      },
      /* admin管理 */
      {
        name: "admin",
        path: "/admins",
        redirect: 'home',
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        children: [
          {
            name: "admin.home",
            path: "home",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/admin/home"),
            meta: { requiresAuth: true, title: 'باشقۇرغۇچىلار تىزىملىكى' ,requiresSuper: true }
          },
          {
            name: "admin.store",
            path: "store",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/admin/store"),
            meta: { requiresAuth: true, title: 'يېڭىدىن باشقۇرغۇچى قوشۇش',requiresSuper: true  }
          },
          {
            name: "admin.edit",
            path: ":id/edit",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/admin/edit"),
            meta: { requiresAuth: true, title: 'باشقۇرغۇچى تەھرىرلەش',requiresSuper: true  },
            props: true,
          },
        ]
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/views/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/views/pages/auth/Login"),
        meta: { requiresGuest: true }
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/pages/error/Error-1.vue")
  }
]
