import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ARTICLE_MEDICAL_PAYMENT_HISTORY = 'getArticleMedicalPaymentHistory'; //
export const GET_ARTICLE_MEDICAL_PAYMENT_HISTORIES = 'getArticleMedicalPaymentHistories'; //
export const STORE_ARTICLE_MEDICAL_PAYMENT_HISTORY = 'storeArticleMedicalPaymentHistory'; //
export const UPDATE_ARTICLE_MEDICAL_PAYMENT_HISTORY = 'updateArticleMedicalPaymentHistory'; //
// [COPY MUTATIONS]
export const SET_ARTICLE_MEDICAL_PAYMENT_HISTORY = 'setArticleMedicalPaymentHistory'; //
export const SET_ARTICLE_MEDICAL_PAYMENT_HISTORIES = 'setArticleMedicalPaymentHistories'; //
export const SET_ARTICLE_MEDICAL_PAYMENT_HISTORIES_PAGE = 'setArticleMedicalPaymentHistoriesPage'; //
export const SET_ARTICLE_MEDICAL_PAYMENT_HISTORY_ERROR = 'setArticleMedicalPaymentHistoryError'; //

const state = {
  articleMedicalPaymentHistory: {},
  articleMedicalPaymentHistories: [],
  articleMedicalPaymentHistoriesMeta: {},
  articleMedicalPaymentHistoriesPage: 1,
  articleMedicalPaymentHistoryError: null
};

const mutations = {
  [SET_ARTICLE_MEDICAL_PAYMENT_HISTORY_ERROR](state, error){
    state.articleMedicalPaymentHistoryError = error;
  },
  [SET_ARTICLE_MEDICAL_PAYMENT_HISTORY](state, payload){
    state.articleMedicalPaymentHistory = payload;
  },
  [SET_ARTICLE_MEDICAL_PAYMENT_HISTORIES](state, payload){
    state.articleMedicalPaymentHistories = payload.data;
    state.articleMedicalPaymentHistoriesMeta = payload.meta;
  },
  [SET_ARTICLE_MEDICAL_PAYMENT_HISTORIES_PAGE](state, payload){
    state.articleMedicalPaymentHistoriesPage = payload;
  },
};

const actions = {
  [GET_ARTICLE_MEDICAL_PAYMENT_HISTORY](context, id){
    return GetAction("manager/medical-payment-histories", `${id}`, context, SET_ARTICLE_MEDICAL_PAYMENT_HISTORY_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_MEDICAL_PAYMENT_HISTORY, response.data );
    }});
  },
  [STORE_ARTICLE_MEDICAL_PAYMENT_HISTORY](context, { article_id, formData }){
    return PostAction(`manager/${article_id}/medical-payment-histories`, formData, context, SET_ARTICLE_MEDICAL_PAYMENT_HISTORY_ERROR, { credentials: true } )
  },
  [UPDATE_ARTICLE_MEDICAL_PAYMENT_HISTORY](context, { id, formData }){
    return PutAction(`manager/medical-payment-histories/${id}`, formData, context, SET_ARTICLE_MEDICAL_PAYMENT_HISTORY_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_MEDICAL_PAYMENT_HISTORY, response.data );
    }});
  },
  [GET_ARTICLE_MEDICAL_PAYMENT_HISTORIES](context, { article_id, page = 1 } ){
    return GetAction( `manager/${article_id}/medical-payment-histories?page=${page}`,
    ``,
    context,
    SET_ARTICLE_MEDICAL_PAYMENT_HISTORY_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_MEDICAL_PAYMENT_HISTORIES, response.data );
        context.commit( SET_ARTICLE_MEDICAL_PAYMENT_HISTORIES_PAGE, page );
      }
    } );
  },
};

const getters = {
  articleMedicalPaymentHistory: (state)=> state.articleMedicalPaymentHistory,
  articleMedicalPaymentHistories: (state)=> state.articleMedicalPaymentHistories,
  articleMedicalPaymentHistoriesMeta: (state)=> state.articleMedicalPaymentHistoriesMeta,
  articleMedicalPaymentHistoriesPage: (state)=> state.articleMedicalPaymentHistoriesPage,
  articleMedicalPaymentHistoryError: (state)=> state.articleMedicalPaymentHistoryError,
};

export default {
  state,
  mutations,
  actions,
  getters
}
