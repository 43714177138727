import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_ARTICLE = 'getArticle'; //
export const GET_ARTICLE_SUBJECT = 'getArticleSubject'; //
export const GET_ARTICLE_CORE_DATA = 'getArticleCoreData'; //
export const GET_ARTICLES = 'getArticles'; //
export const GET_ARTICLE_VERSION = 'getArticleVersion'; //
export const STORE_ARTICLE = 'storeArticle'; //
export const MAKE_COPY_VERSION = 'makeCopyVersion'; //
export const UPDATE_ARTICLE = 'updateArticle'; //
export const SET_ARTICLE_LOCK_STATE = 'setArticleLockState'; //
export const SET_ARTICLE_ONLINE = 'setArticleOnline'; //
export const SET_ARTICLE_STATUS = 'setArticleStatus'; //
export const GET_ARTICLE_STATISTICS = 'getArticleStatistics'; //
export const GET_ARTICLE_STATISTICS_USERS = 'getArticleStatisticsUsers'; //
export const GET_ARTICLE_BASIC_QUANTITY = 'getArticleBasicQuantity'; // basicQuantity
export const ARTICLE_ALLOCATION_ACTION = 'articleAllocationAction'; // articleAllocationAction
// [COPY MUTATIONS]
export const SET_ARTICLE = 'setArticle'; //
export const SET_ARTICLE_SUBJECT = 'setArticleSubject'; //
export const SET_ARTICLES = 'setArticles'; //
export const SET_ARTICLE_STATISTICS_USERS = 'setArticleStatisticsUsers'; //
export const SET_ARTICLE_VERSION = 'setArticleVersion'; //
export const SET_ARTICLES_PAGE = 'setArticlesPage'; //
export const SET_ARTICLE_ERROR = 'setArticleError'; //
export const SET_ARTICLE_CORE_DATA = 'setArticleCoreData'; //

const state = {
  article: {},
  articleSubject: {},
  articles: [],
  articlesMeta: {},
  articlesPage: 1,
  articleVersion: {},
  articleCoreData: {},
  statisticsUsers: [],
  statisticsUsersMeta: {},
  error: null
};

const mutations = {
  [SET_ARTICLE_ERROR](state, error){
    state.error = error;
  },
  [SET_ARTICLE](state, payload){
    state.article = payload;
  },
  [SET_ARTICLE_SUBJECT](state, payload){
    let { subject, cover_image } = payload;
    let manager = {
      record_number: subject.record_number,
      regulator_id: subject.regulator_id,
      actuator_id: subject.actuator_id,
      project_leader_id: subject.project_leader_id,
      donation_manager_id: subject.donation_manager_id,
      merchant_id: subject.merchant_id,
    };
    if ( subject.hasOwnProperty('public_information') ){
      subject.public_information.amount_raised = subject.raise;
      delete subject.raise;
    }

    {
      delete subject.project
      delete subject.record_number
      delete subject.regulator_id
      delete subject.actuator_id
      delete subject.project_leader_id
      delete subject.donation_manager_id
      delete subject.merchant_id
    }

    state.articleSubject = Object.assign({}, subject, { cover_image }, {manager: manager});
  },
  [SET_ARTICLES](state, payload){
    state.articles = payload.data;
    state.articlesMeta = payload.meta;
  },
  [SET_ARTICLES_PAGE](state, payload){
    state.articlesPage = payload;
  },
  [SET_ARTICLE_VERSION](state, payload){
    state.articleVersion = payload;
  },
  [SET_ARTICLE_CORE_DATA](state, payload){
    state.articleCoreData = payload;
  },
  [SET_ARTICLE_STATISTICS_USERS](state, payload){
    state.statisticsUsers = payload.data;
    state.statisticsUsersMeta = payload.meta;
  },
};

const actions = {
  [GET_ARTICLE](context, id){
    return GetAction("manager/articles", `${id}`, context, SET_ARTICLE_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE, response.data );
    }});
  },
  [GET_ARTICLE_SUBJECT](context, { id, rid }){
    return GetAction("manager/articles", `${id}/subject/${rid}`, context, SET_ARTICLE_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_SUBJECT, response.data );
      }});
  },
  [GET_ARTICLE_CORE_DATA](context){
    return GetAction("manager/articles", `core-data`, context, SET_ARTICLE_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_CORE_DATA, response.data );
      }});
  },
  [STORE_ARTICLE](context, formData){
    return PostAction(`manager/articles`, formData, context, SET_ARTICLE_ERROR, { credentials: true } )
  },
  [UPDATE_ARTICLE](context, { id, formData }){
    return PutAction(`manager/articles/${id}`, formData, context, SET_ARTICLE_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE, response.data );
    }});
  },
  [GET_ARTICLES](context, page= 1 ){
    return GetAction( `manager/articles?page=${page}`,
    ``,
    context,
    SET_ARTICLE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLES, response.data );
        context.commit( SET_ARTICLES_PAGE, page );
      }
    } );
  },
  [GET_ARTICLE_VERSION](context, id){
    return GetAction("manager/articles", `${id}/versions`, context, SET_ARTICLE_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_VERSION, response.data );
      }});
  },
  [SET_ARTICLE_LOCK_STATE](context, { id, state, type = 'raise' }){
    return PutAction(`manager/articles/subject/${id}/lock-state`, {state, type}, context, SET_ARTICLE_ERROR, { credentials: true } )
  },
  [SET_ARTICLE_ONLINE](context, { id, version = 1 }){
    return PutAction(`manager/articles/${id}/online`, {version}, context, SET_ARTICLE_ERROR, { credentials: true } )
  },
  [SET_ARTICLE_STATUS](context, { id, formData }){
    return PutAction(`manager/articles/${id}/status`, formData, context, SET_ARTICLE_ERROR, { credentials: true } )
  },
  [GET_ARTICLE_STATISTICS](context, id){
    return GetAction("manager/articles", `${id}/all-statistics`, context, SET_ARTICLE_ERROR, {credentials: true});
  },
  [GET_ARTICLE_BASIC_QUANTITY](context, id){
    return GetAction("manager/articles", `${id}/basic-quantity`, context, SET_ARTICLE_ERROR, {credentials: true});
  },
  [GET_ARTICLE_STATISTICS_USERS](context, { id, page = 1 } ){
    return GetAction( `manager/articles/${id}/statistics-users?page=${page}`,
    ``,
    context,
    SET_ARTICLE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_ARTICLE_STATISTICS_USERS, response.data );
      }
    } );
  },
  [ARTICLE_ALLOCATION_ACTION](context, { id, formData }){
    return PutAction(`manager/articles/${id}/allocation`, formData, context, SET_ARTICLE_ERROR, {credentials: true});
  },
  [MAKE_COPY_VERSION](context, { rid, aid }){
    return PostAction(`manager/articles/make-copy/${rid}`, {aid}, context, SET_ARTICLE_ERROR, { credentials: true } )
  },
};

const getters = {
  article: (state)=> state.article,
  articleSubject: (state)=> state.articleSubject,
  articleCoreData: (state)=> state.articleCoreData,
  articles: (state)=> state.articles,
  articlesMeta: (state)=> state.articlesMeta,
  articlesPage: (state)=> state.articlesPage,
  articleVersion: (state)=> state.articleVersion,
  statisticsUsers: state=> state.statisticsUsers,
  statisticsUsersMeta: state=> state.statisticsUsersMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
