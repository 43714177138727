import LC from "../../common/storage/localStorage";
import { DeleteAction, GetAction, PostAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_HOME_COMMENTS = 'getHomeComments'; //
export const GET_ARTICLE_COMMENTS = 'getArticleComments'; //
export const GET_COMMENT_DETAIL = 'getCommentDetail'; //
export const DELETE_COMMENT = 'deleteComment'; //
export const REPLY_COMMENT = 'replyComment'; //
export const SET_COMMENT_TOP = 'setCommentTop'; //
// [COPY MUTATIONS]
export const SET_COMMENT_ERROR = 'setCommentError'; //
export const SET_HOME_COMMENTS_PAGE = 'setHomeCommentsPage'; //
export const SET_HOME_COMMENTS = 'setHomeComments'; //
export const SET_ARTICLE_COMMENTS = 'setArticleComments'; //
export const SET_ARTICLE_TITLE = 'setArticleTitle'; //
export const SET_ARTICLE_IMAGE = 'setArticleImage'; //
export const SET_COMMENT_DETAIL = 'setCommentDetail'; //

const state = {
  home_comments: [],
  home_comments_meta: {},
  home_comments_page: 1,
  article_comments_list: [],
  article_comments_meta: {},
  article_title: [],
  article_image: '',
  comment_detail: {},
  error: null
};

const mutations = {
  [SET_HOME_COMMENTS](state, payload){
    state.home_comments = payload.data;
    state.home_comments_meta = payload.meta;
  },
  [SET_COMMENT_ERROR](state, error){
    state.error = error;
  },
  [SET_HOME_COMMENTS_PAGE](state, page = 1){
    state.home_comments_page = page;
  },
  [SET_ARTICLE_COMMENTS](state, payload = {}){
    state.article_comments_list = payload.data;
    state.article_comments_meta = payload.meta;
  },
  [SET_ARTICLE_TITLE](state, title = []){
    state.article_title = title;
  },
  [SET_ARTICLE_IMAGE](state, image = null){
    state.article_image = image;
  },
  [SET_COMMENT_DETAIL](state, detail){
    state.comment_detail = detail;
  }
};

const actions = {
  [GET_HOME_COMMENTS](context, { page= 1, filter= 1 } ){
    return GetAction( "manager/comments",
    `?filter=${filter}&page=${page}`,
    context,
    SET_COMMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOME_COMMENTS, response.data );
        context.commit( SET_HOME_COMMENTS_PAGE, page );
      }
    } );
  },
  [GET_ARTICLE_COMMENTS](context, { id, page= 1 } ){
    return GetAction( `manager/comments/${id}/commenter`,
    `?page=${page}`,
    context,
    SET_COMMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit(SET_ARTICLE_COMMENTS, response.data );
        context.commit(SET_ARTICLE_TITLE, response.data.title );
        context.commit(SET_ARTICLE_IMAGE, response.data.image );
        // context.commit(SET_HOME_COMMENTS_PAGE, page);
      }
    } );
  },
  [GET_COMMENT_DETAIL](context, { id } ){
    return GetAction( `manager/comments/${id}`,
    ``,
    context,
    SET_COMMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit(SET_COMMENT_DETAIL, response.data );
      }
    } );
  },
  [DELETE_COMMENT](context, {id}){
    return DeleteAction(
    `manager/comments/${id}`, context, SET_COMMENT_ERROR,
    { credentials: true }
    );
  },
  [REPLY_COMMENT]( context, {id, formData = {}} ) {
    return PostAction(
    `manager/comments/${id}`, formData, context, SET_COMMENT_ERROR,
    { credentials: true }
    );
  },
  [SET_COMMENT_TOP]( context, {id} ) {
    return PostAction(
    `manager/comments/${id}/set-top`, {}, context, SET_COMMENT_ERROR,
    { credentials: true }
    );
  },
};

const getters = {
  home_comments: (state)=> state.home_comments, //
  home_comments_meta: (state)=> state.home_comments_meta, //
  home_comments_page: (state)=> state.home_comments_page, //
  comments_error: (state)=> state.error, //
  article_comments_list: (state)=> state.article_comments_list, //
  article_comments_meta: (state)=> state.article_comments_meta, //
  article_title: (state)=> state.article_title, //
  article_image: (state)=> state.article_image, //
  comment_detail: (state)=> state.comment_detail, //
};

export default {
  state,
  mutations,
  actions,
  getters
}
