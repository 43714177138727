import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  /**
   *
   * @param resource
   * @param params
   * @param credential
   * @param version
   * @returns {*}
   */
  query(resource, params, credential = false, version = 'v1') {
    return this.__base({method: 'query', resource, params, credential, version});
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param credential
   * @param version
   * @returns {*}
   */
  get(resource, slug = "", credential = false, version = 'v1') {
    return this.__base({method: 'get', resource, slug, credential, version});
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param credential
   * @param version
   * @returns {*}
   */
  post(resource, params, credential = false, version = 'v1') {
    return this.__base({method: 'post', resource, params, credential, version});
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param credential
   * @param version
   * @returns {*}
   */
  update(resource, slug, params, credential = false, version = 'v1') {
    return this.__base({method: 'update', resource, params, slug, credential, version});
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param credential
   * @param version
   * @returns {*}
   */
  put(resource, params, credential = false, version = 'v1') {
    return this.__base({method: 'put', resource, params, credential, version});
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param credential
   * @param version
   * @returns {*}
   */
  delete(resource, credential = false, version = 'v1') {
    return this.__base({method: 'delete', resource, credential, version});
  },

  __base({method = 'get', resource, slug = '', params = null, version = 'v1', credential = false}){
    credential && this.setHeader();
    let truestResource = `${version}/${resource}`;
    switch ( method ) {
      case "query":
        return Vue.axios.get(truestResource, params);
      case "get":
        return Vue.axios.get(`${truestResource}${slug ? '/' + slug : ''}`);
      case "post":
        return Vue.axios.post(truestResource, params);
      case "put":
        return Vue.axios.put(truestResource, params);
      case "update":
        return Vue.axios.put(`${truestResource}${slug ? '/' + slug : ''}`, params);
      case "delete":
        return Vue.axios.delete(truestResource);
    }
  },

  generateUrl(url, slug = '', version = 'v1', sec = true){
    return `${sec ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_NO_SEC}/${version}/${url}${slug ? '?' + slug : ''}`;
  },
};

axios.interceptors.request.use(config=> {
  if( config.method === 'get' ){
    config.params = {
      ...config.params,
      lang: localStorage.getItem("language") || 'zh-UG'
    }
  }else if( config.method === 'post' ){
    config.data = {
      ...config.data,
      lang: localStorage.getItem("language") || 'zh-UG'
    }
  }
  return config;
});

export default ApiService;
