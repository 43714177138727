import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import handleReject from "../../common/handleReject";
import LC from "../../common/storage/localStorage";
import { ID_TOKEN_KEY } from "../types";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_ADMIN = "updateAdmin";
export const CHANGE_PASSWORD = "changePassword";
export const REFRESH_TOKEN = "refreshToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAdmin";
export const SET_AUTH_ERROR = "setError";
export const SET_MENU_ITEMS = "setMenuItems";
export const SET_ENABLE_LIST = "setEnableList";
export const SET_OTHER_SETTING = "setOtherSetting";

const state = {
  authError: null,
  admin: {},
  isAuthenticated: !!JwtService.getToken(),
  menuItems: [],
  enableList: [],
  otherSetting: {},
};

const getters = {
  currentAdmin(state) {
    return state.admin;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  menuItems(state){
    if ( !LC.hasExpired('menuItems') )
      return LC.get('menuItems');
    return state.menuItems;
  },
  enableList(state){
    if ( !LC.hasExpired('enableList') )
      return LC.get('enableList');
    return state.enableList;
  },
  authError(state){
    return state.authError
  },
  otherSetting(state){
    if ( !LC.hasExpired('otherSetting') )
      return LC.get('otherSetting');
    return state.otherSetting;
  },
};

const handleAuth = (context, data)=>{
  context.commit(SET_AUTH, data);
  LC.destroy('menuItems');
  LC.destroy('enableList');
  LC.destroy('otherSetting');
  handleCommit(context, data, 'menuItems', SET_MENU_ITEMS);
  handleCommit(context, data, 'enable_list', SET_ENABLE_LIST);
  handleCommit(context, data, 'other_setting', SET_OTHER_SETTING, {});
}
const handleCommit = (context, data, key, types, defaults = [], value = null)=>{
  context.commit(types, data.hasOwnProperty(key) ? typeof value === 'function' ? value(data) : data[key] : defaults);
}

const actions = {
  [LOGIN](context, credentials) {
    return PostAction("manager/authorizations/login", credentials, context, SET_AUTH_ERROR, {
      resolved: (context, response)=> {handleAuth(context, response.data);}
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context, router = null) {
    if (JwtService.getToken()) {
      if ( LC.expiredAtDiffInMinutes(ID_TOKEN_KEY) < 30 ){
        context.dispatch(REFRESH_TOKEN);
      }else {
        return GetAction("manager/authorizations/verify", null, context, SET_AUTH_ERROR, {
          credentials: true,
          resolved: (context, response)=> { handleAuth(context, response.data) }
        });
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_ADMIN](context, payload) {
    const { phone, nickname, avatar } = payload;
    const user = { phone, nickname, avatar };
    return PutAction("manager/administrators", user, context, SET_AUTH_ERROR, {
      credentials: true, resolved: (context, response)=>{ context.commit(SET_AUTH, response.data); }
    });
  },
  [CHANGE_PASSWORD](context, payload) {
    return PostAction("manager/administrators/change-password", payload, context, SET_AUTH_ERROR, {
      credentials: true,
      resolved: (context, response)=> {
        context.commit(PURGE_AUTH);
      }
    });
  },
  [REFRESH_TOKEN](context){
    return PostAction("manager/authorizations/refresh", null, context, SET_AUTH_ERROR, {
      credentials: true,
      resolved: (context, response)=> {
        handleAuth(context, response.data);
      }
    });
  }
};

const mutations = {
  [SET_AUTH_ERROR](state, error) {
    state.authError = error;
  },
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.admin = payload.admin;
    state.authError = {};
    let { token } = payload;
    JwtService.saveToken(token.access_token, token.expires_in);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.admin = {};
    state.authError = {};
    state.enableList = [];
    state.menuItems = [];
    state.otherSetting = {}
    LC.destroy('menuItems');
    LC.destroy('enableList');
    LC.destroy('otherSetting');
    JwtService.destroyToken();
  },
  [SET_MENU_ITEMS](state, payload){
    state.menuItems = payload;
    LC.set('menuItems', payload);
  },
  [SET_ENABLE_LIST](state, payload){
    state.enableList = payload;
    LC.set('enableList', payload);
  },
  [SET_OTHER_SETTING](state, payload){
    state.otherSetting = payload;
    LC.set('otherSetting', payload);
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
