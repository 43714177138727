import LC from "../../common/storage/localStorage";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// [COPY ACTIONS]
export const GET_HOSPITAL_DEPARTMENT = 'getHospitalDepartment'; //
export const GET_HOSPITAL_DEPARTMENTS = 'getHospitalDepartments'; //
export const STORE_HOSPITAL_DEPARTMENT = 'storeHospitalDepartment'; //
export const UPDATE_HOSPITAL_DEPARTMENT = 'updateHospitalDepartment'; //
// [COPY MUTATIONS]
export const SET_HOSPITAL_DEPARTMENT = 'setHospitalDepartment'; //
export const SET_HOSPITAL_DEPARTMENTS = 'setHospitalDepartments'; //
export const SET_HOSPITAL_DEPARTMENTS_PAGE = 'setHospitalDepartmentsPage'; //
export const SET_HOSPITAL_DEPARTMENT_ERROR = 'setHospitalDepartmentError'; //

const state = {
  hospitalDepartment: {},
  hospitalDepartments: [],
  hospitalDepartmentsMeta: {},
  hospitalDepartmentsPage: 1,
  error: null
};

const mutations = {
  [SET_HOSPITAL_DEPARTMENT_ERROR](state, error){
    state.error = error;
  },
  [SET_HOSPITAL_DEPARTMENT](state, payload){
    state.hospitalDepartment = payload;
  },
  [SET_HOSPITAL_DEPARTMENTS](state, payload){
    state.hospitalDepartments = payload.data;
    state.hospitalDepartmentsMeta = payload.meta;
  },
  [SET_HOSPITAL_DEPARTMENTS_PAGE](state, payload){
    state.hospitalDepartmentsPage = payload;
  }
};

const actions = {
  [GET_HOSPITAL_DEPARTMENT](context, id){
    return GetAction("manager/hospital-departments", `${id}`, context, SET_HOSPITAL_DEPARTMENT_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOSPITAL_DEPARTMENT, response.data );
    }});
  },
  [STORE_HOSPITAL_DEPARTMENT](context, formData){
    return PostAction(`manager/hospital-departments`, formData, context, SET_HOSPITAL_DEPARTMENT_ERROR, { credentials: true } )
  },
  [UPDATE_HOSPITAL_DEPARTMENT](context, { id, formData }){
    return PutAction(`manager/hospital-departments/${id}`, formData, context, SET_HOSPITAL_DEPARTMENT_ERROR, {credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOSPITAL_DEPARTMENT, response.data );
    }});
  },
  [GET_HOSPITAL_DEPARTMENTS](context, page= 1 ){
    return GetAction( `manager/hospital-departments?page=${page}`,
    ``,
    context,
    SET_HOSPITAL_DEPARTMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOSPITAL_DEPARTMENTS, response.data );
        context.commit( SET_HOSPITAL_DEPARTMENTS_PAGE, page );
      }
    } );
  }
};

const getters = {
  hospitalDepartment: (state)=> state.hospitalDepartment,
  hospitalDepartments: (state)=> state.hospitalDepartments,
  hospitalDepartmentsMeta: (state)=> state.hospitalDepartmentsMeta,
  hospitalDepartmentsPage: (state)=> state.hospitalDepartmentsPage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
