// ZH-CN
export const locale = {
  TRANSLATOR: {
    SELECT: "选择你的语言"
  },
  MENU: {
    NEW: "新",
    ACTIONS: "行动",
    CREATE_POST: "创建新帖子",
    PAGES: "Pages",
    FEATURES: "特征",
    APPS: "应用",
    DASHBOARD: "仪表板"
  },
  AUTH: {
    GENERAL: {
      OR: "要么",
      SUBMIT_BUTTON: "提交",
      NO_ACCOUNT: "没有账号？",
      SIGNUP_BUTTON: "注册",
      FORGOT_BUTTON: "忘记密码",
      BACK_BUTTON: "背部",
      PRIVACY: "隐私",
      LEGAL: "法律",
      CONTACT: "联系"
    },
    LOGIN: {
      TITLE: "创建帐号",
      BUTTON: "签到"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "用戶名"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ASIDES: {
    dashboard: "首页",
    SECTIONS: {
      advert: "广告管理",
      article: "内容管理",
      user: "用户管理",
      merchant: "商户平台管理",
      subscribe_message: "订阅消息管理",
      setting: "配置管理",
      admin: "管理员管理"
    },
    ADVERTS: {
      first: "广告管理",
      home: "广告管理",
    },
    ARTICLES: {
      root: "内容管理",
      first: "内容管理",
      home: "所有内容",
      CLASSIFIES: {
        first: "其他类目管理",
        house: "房产类目",
        car: "车产类目",
        job: "工作类目",
        source_income: "收入来源类目",
      },
      HOSPITALS: {
        first: "医院管理",
        home: "医院管理",
      },
      HOSPITAL_DEPARTMENTS: {
        home: "科室管理",
      },
      REGULATORS: {
        first: "机构管理",
        home: "发起机构管理",
      },
      ACTUATORS: {
        home: "执行机构管理",
      },
      CONTRIBUTION_MANAGERS: {
        home: "善款接受方管理",
      },
      PROJECT_LEADERS: {
        home: "项目负责人管理",
      },
    },
    COMMENTS: {
      first: "留言管理",
      home: "留言管理",
    },
    USERS: {
      first: "用户管理",
      home: "用户管理",
    },
    ADMINS: {
      first: "管理员管理",
      home: "管理员管理",
    },
    MERCHANT: {
      first: "商户平台管理",
      home: "商户平台",
    },
    SUBSCRIBE_MESSAGES: {
      first: "订阅消息管理",
      home: "管理中心",
    },
    SETTINGS: {
      first: "配置管理",
      home: "配置管理",
    },
  },

  COMMON: {
    WELCOME: '你好',
    REFRESH: '刷新',
    CREATE: '新增',
    BACK: '返回',
    $: "元",
    NO_BODY: "无名氏",
    PHONE: "电话",
    TABLIST: {
      TITLE: {
        UG: '维吾尔语内容',
        CN: '国语内容　　'
      }
    },
    TITLES: {
      UG: "维吾尔语",
      CN: "国　　语"
    },
    FORM: {
      LABEL: {
        is_show: '是否开启',
        sort: '排序',
        describe: '简介',
        title: '标题',
        gender: '性别'
      },
      PLACEHOLDER: {
        sort: '请输入排序，可以留空',
        describe: '请填写简介或者留空',
        title: '请输入标题',
        gender: '请选择性别'
      },
      FEEDBACK: {
        title: '请输入标题'
      },
      OPTIONS: {
        YES: '是',
        NO: '否',
        MALE: "男",
        FEMALE: "女",
      },
      BUTTONS: {
        SUBMIT: '保存',
        CANCEL: '取消',
        EDIT: '编辑',
        DELETE: '删除',
        NEXT: '下一步',
        PREV: '上一步',
        CONFIRM: {
          YES: "确定",
          NO: "取消"
        }
      }
    }
  },

  PAGES: {
    DASHBOARD: {
      BREAD_CRUMBS_TITLES: {
        first: "首页",
      },
      STATISTICS: {
        title: "进一个月以内用户统计",
        desc: "名新增用户",
        label: "新增用户数量"
      },
      PROTOCOL: {
        title: '使用协议'
      },
      USERS: {
        title: '新用户'
      }
    },
    CLASSIFY: {
      STORE: {
        PORTLET_TITLES: {
          house: "新增房产类目",
          car: "新增车产类目",
          job: "新增工作类目",
          source_income: "新增收入来源类目",
        },
        BREAD_CRUMBS_TITLES: {
          first: "普通类目管理",
          house_first: "房产类目管理",
          house: "新增房产类目",
          car_first: "车产类目管理",
          car: "新增车产类目",
          job_first: "工作类目管理",
          job: "新增工作类目",
          source_income_first: "收入类目管理",
          source_income: "新增收入类目",
        }
      },
      EDIT: {
        PORTLET_TITLES: {
          house: "编辑房产类目",
          car: "编辑车产类目",
          job: "编辑工作类目",
          source_income: "编辑收入类目",
        },
        BREAD_CRUMBS_TITLES: {
          first: "普通类目管理",
          house_first: "房产类目管理",
          house: "编辑房产类目",
          car_first: "车产类目管理",
          car: "编辑车产类目",
          job_first: "工作类目管理",
          job: "编辑工作类目",
          source_income_first: "收入类目管理",
          source_income: "编辑收入类目",
        }
      },
      HOME: {
        BREAD_CRUMBS_TITLES: {
          first: "普通类目管理",
          house: "房产类目管理",
          car: "车产类目管理",
          job: "工作类目管理",
          source_income: "收入类目管理",
        },
        CONFIRM_TIPS: {
          OPEN: "确认启用该类目吗？",
          CLOSE: "确认停用该类目吗？"
        },
        LABEL: {
          title: '名称'
        }
      }
    },
    HOSPITAL: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "医院管理",
          CURRENT_TITLE: "医院信息管理"
        },
        CONFIRM_TIPS: {
          OPEN: "确认启用该医院信息吗？",
          CLOSE: "确认停用该医院信息吗？",
        },
        LABEL: {
          title: '名称'
        }
      },
      STORE: {
        TITLE: "新增医院",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "医院管理",
          CURRENT_TITLE: "新增医院"
        },
      },
      EDIT: {
        TITLE: "编辑医院信息",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "医院管理",
          CURRENT_TITLE: "编辑医院信息"
        },
      }
    },
    HOSPITAL_DEPARTMENT: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "科室管理",
          CURRENT_TITLE: "科室信息管理"
        },
        CONFIRM_TIPS: {
          OPEN: "确认启用该科室信息吗？",
          CLOSE: "确认停用该科室信息吗？",
        },
        LABEL: {
          title: '名称'
        }
      },
      STORE: {
        TITLE: "新增科室",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "科室管理",
          CURRENT_TITLE: "新增科室",
        },
      },
      EDIT: {
        TITLE: "编辑科室信息",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "新增科室",
          CURRENT_TITLE: "编辑科室信息",
        },
      }
    },
    REGULATOR: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "发起机构管理",
          CURRENT_TITLE: "机构信息管理"
        },
        CONFIRM_TIPS: {
          OPEN: "确认启用该机构信息吗？",
          CLOSE: "确认停用该机构信息吗？",
        }
      },
      STORE: {
        TITLE: "新增机构",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "发起机构管理",
          CURRENT_TITLE: "新增机构"
        },
      },
      EDIT: {
        TITLE: "编辑机构信息",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "发起机构管理",
          CURRENT_TITLE: "编辑机构信息"
        },
      }
    },
    ACTUATOR: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "执行机构管理",
          CURRENT_TITLE: "机构信息管理"
        },
        CONFIRM_TIPS: {
          OPEN: "确认启用该机构信息吗？",
          CLOSE: "确认停用该机构信息吗？",
        }
      },
      STORE: {
        TITLE: "新增机构",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "执行机构管理",
          CURRENT_TITLE: "新增机构"
        },
      },
      EDIT: {
        TITLE: "编辑机构信息",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "执行机构管理",
          CURRENT_TITLE: "编辑机构信息"
        },
      }
    },
    PROJECT_LEADER: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "项目负责人管理",
          CURRENT_TITLE: "项目负责人信息管理"
        },
        CONFIRM_TIPS: {
          OPEN: "确认启用该项目负责人信息吗？",
          CLOSE: "确认停用该项目负责人信息吗？",
        }
      },
      STORE: {
        TITLE: "新增项目负责人",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "项目负责人管理",
          CURRENT_TITLE: "新增项目负责人"
        },
      },
      EDIT: {
        TITLE: "编辑项目负责人信息",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "项目负责人管理",
          CURRENT_TITLE: "编辑项目负责人信息",
        },
      }
    },
    ARTICLE: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "所有内容"
        },
        LABEL: {
          title: '标题'
        }
      },
      STORE: {
        TITLE: "新增内容",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "新增内容",
        },
      },
      EDIT: {
        TITLE: "编辑内容",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "编辑内容",
        },
      }
    },
    ARTICLE_STATISTICS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "统计"
        },
        LABEL: {
          title: '名称',
          order_total_fee: "金额",
          userDonations: "用户捐款详情",
          donations: "帮助人数",
          donationsSymbol: "名",
          donationsDesc: "本求助信帮助人数",
          donationTimes: "次数",
          donationTimesSymbol: "次",
          donationTimesDesc: "总帮助次数，也是付款次数",
          highAmount: "最高",
          highAmountDesc: "捐款单次最高金额",
          lowAmount: "最低",
          lowAmountDesc: "捐款单次最低金额"
        }
      },
    },
    ARTICLE_NOTIFICATION: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "通知管理"
        },
        LABEL: {},
        CONFIRM_TIPS: {
          OPEN: "确定要开启该通知吗？",
          CLOSE: "确定要关闭该通知吗？"
        },
      },
      STORE: {
        TITLE: "新增通知",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "通知管理",
          CURRENT_TITLE: "新增通知"
        },
      },
      EDIT: {
        TITLE: "编辑通知",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "通知管理",
          CURRENT_TITLE: "编辑通知"
        },
      }
    },
    ARTICLE_WITNESS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "证人证词管理"
        },
        LABEL: {
          phone: "联系电话"
        },
        CONFIRM_TIPS: {
          OPEN: "确定要开启该证词吗？",
          CLOSE: "确定要关闭该证词吗？"
        },
      },
      STORE: {
        TITLE: "新增证人证词",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "证人证词管理",
          CURRENT_TITLE: "新增证人证词"
        },
      },
      EDIT: {
        TITLE: "编辑证人证词",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "证人证词管理",
          CURRENT_TITLE: "编辑证人证词"
        },
      }
    },
    MERCHANT: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "商户平台管理",
          CURRENT_TITLE: "商户平台"
        },
        LABEL: {
          mch_id: "商户号",
        },
        CONFIRM_TIPS: {
          OPEN: "要开启该商户号吗？",
          CLOSE: "要关闭该商户号吗？"
        },
      },
      STORE: {
        TITLE: "新增商户号",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "商户平台管理",
          CURRENT_TITLE: "新增商户号"
        },
      },
      EDIT: {
        TITLE: "编辑商户号",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "商户平台管理",
          CURRENT_TITLE: "编辑商户号",
        },
      }
    },
    ARTICLE_MEDICAL_PAYMENT_HISTORY: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "打款记录管理"
        },
        LABEL: {
          date: "时间",
          amount: "金额"
        },
        CONFIRM_TIPS: {
          OPEN: "确定要开启该打款记录吗？",
          CLOSE: "确定要关闭该打款记录吗？"
        },
      },
      STORE: {
        TITLE: "新增打款记录",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "打款记录管理",
          CURRENT_TITLE: "新增打款记录"
        },
      },
      EDIT: {
        TITLE: "编辑打款记录",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "打款记录管理",
          CURRENT_TITLE: "编辑打款记录"
        },
      }
    },
    ARTICLE_PROGRESS_HISTORY: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          CURRENT_TITLE: "进展记录管理"
        },
        LABEL: {
          date: "时间",
          amount: "金额"
        },
        CONFIRM_TIPS: {
          OPEN: "确定要开启该进展记录吗？",
          CLOSE: "确定要关闭该进展记录吗？"
        },
      },
      STORE: {
        TITLE: "新增进展记录",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "进展记录管理",
          CURRENT_TITLE: "新增进展记录"
        },
      },
      EDIT: {
        TITLE: "编辑进展记录",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "内容管理",
          HOME_TITLE: "进展记录管理",
          CURRENT_TITLE: "编辑进展记录"
        },
      }
    },
    COMMENTS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "留言管理",
          CURRENT_TITLE: "留言管理",
        },
        TIPS:{
          NODATA: "暂时没有留言信息"
        },
        OPTIONS: {
          ALL: "全部",
          AUDIT: "未审核",
          NO_BODY: "无名"
        },
        LABEL: {},
      },
      LIST: {
        TIPS: {
          AUDIT: "审核",
          DE_AUDIT: "取消审核",
          NO_BODY: "无名",
          NO_DATA: "暂时没有留言数据",
        }
      },
      DETAIL: {
        PLACEHOLDER: {
          typing: "请填写回复内容..."
        },
        BUTTONS: {
          AUDIT: "审核",
          AUDITED: "已审核",
          DELETE: "删除",
          REPLY: "回复",
          SET_TOP: "置顶",
          DE_TOP: "取消置顶"
        },
        TIPS: {
          ERROR: "发生错误，请稍后再试",
          CONFIRM: "删除之后无法恢复，请确认要删除吗？",
          CONFIRM_WITH_LEN: "本留言为头部留言，若删除已经回复的其他{len}条留言信息也会一并删除，请确认要删除吗？"
        }
      }
    },
    ADVERTS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "广告管理",
          CURRENT_TITLE: "所有的广告"
        },
        TIPS:{
          NODATA_TITLE: "没有数据",
          NODATA: "暂时没有想过的数据",
          DELETE: "确定删除该广告吗？"
        },
        OPTIONS: {
        },
        LABEL: {
          TYPE: "类型: ",
          TARGET_TITLE: {
            article: "标题: ",
            external_link: "网址: ",
            link: "点击查看"
          },
          TARGET: {
            article: "跳转到内容",
            mp: "跳转到小程序",
            external_link: "跳转到外链网址",
          },
          BUTTON: {
            STORE: "新增广告"
          }
        },
      },
      STORE: {
        TITLE: "新增广告",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "广告管理",
          CURRENT_TITLE: "新增广告"
        },
      },
      EDIT: {
        TITLE: "编辑广告",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "广告管理",
          CURRENT_TITLE: "编辑广告"
        },
      }
    },
    USERS: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "用户管理",
          CURRENT_TITLE: "用户管理",
        },
        TIPS:{
          NODATA_TITLE: "暂无数据",
          NODATA_DESC: "暂无数据",
        },
        LABEL: {
          SEARCH_PLACEHOLDER: "请输入昵称或者id号",
          BLACK_LIST_REASON: "黑名单原因：",
          BLACK_LIST_NO_REASON: "没有记录",
          IS_NOT_BLACK: "（现在不是黑名单）",
          ACTIONS_TITLE: "操作",
          id: "用户id",
          created_at: "注册时间",
          donation_total_fee: "捐款总额",
          BUTTON: {
            SET_BLACK_LIST: "设置黑名单信息",
            DONATION_HISTORY: "捐款记录",
            SOCIALITE_ACCOUNTS: "社会化信息",
          }
        },
      },
      DONATION: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "用户管理",
          CURRENT_TITLE: "捐款记录"
        },
        LABEL: {
          phone: "电话号码",
          order_total_fee: "总金额",
          order_total_fee_desc: "到现在为止总捐款金额",
          order_total_fee_avg: "平均",
          order_total_fee_avg_desc: "到现在为止的捐款额平均值",
          total_article: "项目数量",
          total_article_desc: "总共帮助的项目数量",
          donation_times: "次数",
          donation_times_desc: "总共捐款次数",
          times: "次",
          total_article_limit: "个",
          user_total_fee: "总捐款金额",
          total_times: "捐款次数",
        }
      },
    },
    ADMIN: {
      HOME: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "管理员管理",
          CURRENT_TITLE: "管理员管理"
        },
        CONFIRM_TIPS: {
          OPEN: "确定启用该管理员吗？",
          CLOSE: "确定停用该管理员吗？"
        },
        LABEL: {
          title: '昵称',
          superTitle: "超级管理员",
          actuator: "执行机构：",
          regulator: "发起机构：",
        }
      },
      STORE: {
        TITLE: "新增管理员",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "管理员管理",
          CURRENT_TITLE: "新增管理员"
        },
      },
      EDIT: {
        TITLE: "编辑管理员信息",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "管理员管理",
          CURRENT_TITLE: "编辑管理员信息"
        },
      }
    },
    PROFILE: {
      INDEX: {
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "资料管理",
          CURRENT_TITLE: "我的资料"
        },
        LABEL: {
          phone: "手机号码",
          action: {
            edit: "资料编辑",
            reset_password: "修改密码"
          }
        }
      },
      EDIT: {
        title: "资料编辑",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "资料管理",
          CURRENT_TITLE: "资料编辑"
        },
        LABEL: {
          phone: "手机号码",
          nickname: "昵称",
          avatar: {
            label: "头像",
            desc: "请上传头像"
          }
        },
        DESCRIPTION: {
          phone: "请输入手机号码",
          nickname: "请输入昵称",
        },
        FEEDBACK: {
          phone: "请准确输入手机号码",
          nickname: "昵称不能少于2个字符",
        },
        BUTTON: {
          submit: "保存",
          cancel: "取消"
        },
        TIPS: {
          EDIT_SUCCESS: "编辑成功",
          VALIDATION_TIPS: "请注意填写表单"
        },
      },
      RESET_PASSWORD: {
        title: "修改密码",
        BREAD_CRUMBS_TITLES: {
          FIRST_TITLE: "资料管理",
          CURRENT_TITLE: "修改密码"
        },
        LABEL: {
          old_password: "当前密码",
          password: "新密码",
          password_confirmation: "新密码",
        },
        DESCRIPTION: {
          password: "请输入新密码",
          password_confirmation: "请在次输入新密码",
        },
        PLACEHOLDER: {
          old_password: "请输入当前的密码",
          password: "请输入新密码",
          password_confirmation: "请再次输入新密码",
        },
        FEEDBACK: {
          old_password: "请输入当前的密码",
          password: "新密码长度不能小于6个字符",
          password_confirmation: "两次输入的新密码不一致",
        },
        BUTTON: {
          submit: "保存",
          cancel: "取消"
        },
        TIPS: {
          EDIT_SUCCESS: "修改密码成功",
          VALIDATION_TIPS: "请注意填写表单"
        },
      }
    },
    PROTOCOL: {
      title: "使用协议",
      BREAD_CRUMBS_TITLES: {
        FIRST_TITLE: "使用协议",
        CURRENT_TITLE: "使用协议"
      },
    }
  },
  COMPONENTS: {
    FORMS: {
      CLASSIFY_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "类目维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "类目中文内容"
            }
          },
          LABEL: {
            title: "类目名称",
            describe: "类目简介",
          },
          PLACEHOLDER: {
            title_ug: "请输入类目维吾尔文名称",
            title_cn: "请输入类目中文名称",
            describe_ug: "请输入维吾尔文简介",
            describe_cn: "请输入中文简介",
          },
          FEEDBACK:{
            title: "类目名称是必填项",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请输入必填项，呈红色的都为必填项"
          }
        }
      },
      HOSPITAL_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "医院维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "医院中文内容"
            }
          },
          LABEL: {
            bank_account: "银行账号",
            phone: "联系电话",

            title: "医院名称",
            address: "医院地址",
            describe: "医院简介",
          },
          PLACEHOLDER: {
            bank_account: "请输入医院的开户银行账号",
            phone: "请输入医院的联系电话",

            title_ug: "请输入医院维吾尔文名称",
            address_ug: "请输入医院维吾尔文地址",
            describe_ug: "请输入医院维吾尔文简介，也可以留空",

            title_cn: "请输入医院中文名称",
            address_cn: "请输入医院中文地址",
            describe_cn: "请输入医院中文简介，也可以留空",
          },
          FEEDBACK: {
            bank_account: "医院开户银行账号是必填项，请不要留空。",
            phone: "医院联系电话是必填项，请不要留空。",

            title: "医院名称不能为空",
            address: "医院地址不能为空",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        },
      },
      HOSPITAL_DEPARTMENT_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "医院科室维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "医院科室中文内容"
            }
          },
          LABEL: {
            title: "科室名称",
            describe: "科室简介"
          },
          PLACEHOLDER: {
            title_ug: "请输入科室维吾尔文名称",
            describe_ug: "请输入科室维吾尔文简介，也可以留空",
            title_cn: "请输入科室中文名称",
            describe_cn: "请输入科室中文简介，也可以留空"
          },
          FEEDBACK: {
            title: "科室名称不能为空"
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项，呈红色的都是必填项！"
          }
        },
      },
      REGULATOR_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "发起机构维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "发起机构中文内容"
            }
          },
          LABEL: {
            bank_account: "银行账号",
            phone: "联系电话",
            credit_code: "统一社会信用代码",
            logo_image: "LOGO",
            donation_certificate_image: "公开募捐资格证书",
            organization_certificate_image: "慈善组织登记证书",

            title: "机构名称",
            address: "机构地址",
            describe: "简介",
            register_authority: "登记机关",
            deposit_bank: "开户银行名称",
          },
          PLACEHOLDER: {
            bank_account: "请输入机构的开户银行账号",
            phone: "请输入机构的联系电话",
            credit_code: "请输入统一社会信用代码",
            logo_image: "请上传商标图片",
            donation_certificate_image: "请上传公开募捐资格证书图片",
            organization_certificate_image: "请上传慈善组织登记证书图片",

            title_ug: "请输入机构维吾尔文名称",
            address_ug: "请输入机构维吾尔文地址",
            describe_ug: "请输入机构维吾尔文简介，也可以留空",
            register_authority_ug: '请输入登记机关维吾尔文名称',
            deposit_bank_ug: '请输入开户银行维吾尔文名称',

            title_cn: "请输入机构中文名称",
            address_cn: "请输入机构中文地址",
            describe_cn: "请输入机构中文简介，也可以留空",
            register_authority_cn: '请输入登记机关中文名称',
            deposit_bank_cn: '请输入开户银行中文名称',
          },
          FEEDBACK: {
            bank_account: "机构开户银行账号是必填项，请不要留空。",
            phone: "机构联系电话是必填项，请不要留空。",
            credit_code: "统一社会信用代码是必填项，请不要留空。",
            logo_image: "必须上传商标图片",
            donation_certificate_image: "必须上传公开募捐资格证书图片",
            organization_certificate_image: "必须上传慈善组织登记证书图片",

            title: "机构名称不能为空",
            address: "机构地址不能留空",
            register_authority: '登记机关名称不能为空',
            deposit_bank: '开户银行名称不能为空',
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        },
      },
      ACTUATOR_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "执行机构维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "执行机构中文内容"
            }
          },
          BUTTON: {
            CHOOSE: "点击选择"
          },
          LABEL: {
            regulator_id: "发起机构",
            phone: "联系电话",
            logo_image: "LOGO",

            title: "机构名称",
            describe: "简介"
          },
          PLACEHOLDER: {
            regulator_id: "请选择发起机构",
            phone: "请输入机构的联系电话",
            logo_image: "请上传商标图片",

            title_ug: "请输入机构维吾尔文名称",
            describe_ug: "请输入机构维吾尔文简介，也可以留空",
          },
          FEEDBACK: {
            regulator_id: "发起机构为必选项",
            phone: "机构联系电话是必填项，请不要留空。",
            logo_image: "必须上传商标图片",

            title: "机构名称不能为空"
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        }
      },
      PROJECT_LEADER_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "项目负责人维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "项目负责人中文内容"
            }
          },
          BUTTON: {
            CHOOSE: "点击选择"
          },
          LABEL: {
            actuator_id: "执行机构",
            phone: "联系电话",
            avatar_image: "头像",

            full_name: "项目负责人姓名",
            describe: "简介"
          },
          PLACEHOLDER: {
            actuator_id: "请选择执行机构",
            phone: "请输入项目负责人的联系电话",
            avatar_image: "请上传项目负责人头像",

            full_name_ug: "请输入项目负责人维吾尔文姓名",
            describe_ug: "请输入项目负责人维吾尔文简介，也可以留空",

            full_name_cn: "请输入项目负责人中文姓名",
            describe_cn: "请输入项目负责人中文简介，也可以留空",
          },
          FEEDBACK: {
            actuator_id: "执行机构为必选项",
            phone: "项目负责人联系电话是必填项，请不要留空。",
            avatar_image: "必须上传项目负责人头像",

            full_name: "项目负责人姓名不能为空"
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        }
      },
      ARTICLE_RAISE_FORM: {
        TIPS: {
          EDIT_SUCCESS: "编辑成功！",
          VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
        },
        NAV_ITEMS: {
          AUTH_ITEM: {
            TITLE: "发布者身份信息",
            DESC: "请信息发布者授权身份信息"
          },
          TITLE_ITEM: {
            TITLE: "标题和封面图",
            DESC: "求助信息标题和封面图片"
          },
          CONTENT_ITEM: {
            TITLE: "内容",
            DESC: "求助信息内容"
          },
          PATIENT_ITEM: {
            TITLE: "受助人信息",
            DESC: "受助人个人信息"
          },
          HOSPITAL_ITEM: {
            TITLE: "医院信息",
            DESC: "医院相关的信息"
          },
          BANNER_ITEM: {
            TITLE: "幻灯片",
            DESC: "求助信息页面最上方的幻灯片"
          },
          EVIDENCES_IMAGE_ITEM: {
            TITLE: "证明图片",
            DESC: "求助信息相关的证明图片"
          },
          PERSONAL_IMAGE_ITEM: {
            TITLE: "个人图片",
            DESC: "受助人相关的个人图片"
          },
          PUBLIC_INFORMATION_ITEM: {
            TITLE: "家庭信息",
            DESC: "受助人家庭相关信息"
          },
          MANAGER_ITEM: {
            TITLE: "机构信息",
            DESC: "本求助信息的发起机构以及相关的机构信息"
          },
          CONFIG_ITEM: {
            TITLE: "配置",
            DESC: "项目相关的配置信息"
          },
          PREVIEW_ITEM: {
            TITLE: "复审",
            DESC: "请仔细复查已填写的信息"
          },
        },
        AUTH_FORM: {
          HEADING_TITLE: "授权求助信息发布者身份信息",
        },
        TITLE_FORM: {
          HEADING_TITLE: "请设置求助信息标题以及封面图片",
          LABEL: {
            title: "标题",
            main_title: "主体标题",
            title_prefix: "标题前缀",
            describe: "简介",
            cover_image: "封面图",
            external_url: '外部链接',
          },
          PLACEHOLDER: {
            title: "请输入标题",
            main_title: "请输入主体标题，所谓主题就是受助目标",
            title_prefix: "请输入标题前缀，此内容只会在分享时出现在标题前面，也可以留空",
            describe: "请输入简介，也可以留空",
            cover_image: "请上传封面图，封面图用于分享，页面展示当中",
            external_url: '请输入外部链接',
          },
          FEEDBACK: {
            title: "标题不能为空",
            main_title: "主体标题不能为空",
            cover_image: "必须上传封面图",
            external_url: '请输入外部链接',
          },
          TIPS: {},
        },
        CONTENT_FORM: {
          HEADING_TITLE: "请设置求助信内容",
          LABEL: {
            content: "求助信内容",
          },
          PLACEHOLDER: {},
          FEEDBACK: {
            content: "求助信内容不能为空",
          },
          TIPS: {},
        },
        PATIENT_FORM: {
          HEADING_TITLE: "请设置受助人信息",
          LABEL: {
            id_card: "身份证号码",
            phone: "联系电话",
            gender: "性别",
            ug_name: "维吾尔文姓名",
            cn_name: "中文姓名　　",
            ug_address: "维吾尔文户籍地",
            cn_address: "中文户籍地　　",
          },
          PLACEHOLDER: {
            id_card: "请输入身份证号码",
            phone: "请输入联系电话",
            gender: "请选择性别",
            ug_name: "请输入维吾尔文姓名",
            cn_name: "请输入中文姓名",
            ug_address: "请输入维吾尔文户籍地",
            cn_address: "请输入中文户籍地",
          },
          FEEDBACK: {
            id_card: "身份证号码不能为空",
            phone: "联系电话不能为空",
            gender: "请选择性别",
            ug_name: "维吾尔文姓名不能为空",
            cn_name: "中文姓名不能为空",
          },
          TIPS: {},
        },
        HOSPITAL_FORM: {
          HEADING_TITLE: "请设置医院信息",
          LABEL: {
            patient_id: "住院号",
            hospital_id: "请选择医院",
            department_id: "请选择科室",
            address_ug: "维吾尔文病床号",
            address_cn: "中文病床号",
          },
          PLACEHOLDER: {
            patient_id: "请输入住院号",
            hospital_id: "请选择入住医院",
            department_id: "请选择入住科室",
            address_ug: "请输入维吾尔文病床号",
            address_cn: "请输入中文病床号",
          },
          FEEDBACK: {
            patient_id: "住院号不能为空",
            hospital_id: "入住医院为必选项",
            department_id: "入住科室为必选项",
            address_ug: "维吾尔文病床号不能为空",
            address_cn: "中文病床号不能为空",
          },
          TIPS: {},
        },
        BANNER_FORM: {
          HEADING_TITLE: "请设置幻灯片",
          LABEL: {
            banner_type: "幻灯片类型",
            image: "图片",
            video: "视频",
            image_type: "上传图片",
            video_type: "上传视频",
          },
          PLACEHOLDER: {
            banner_type: "请选择幻灯片类型",
            image: "幻灯片最多只能上传4张图片",
            video: "幻灯片视频最多只能上传一张视频",
          },
          FEEDBACK: {
            image: "至少需要上传一张图片",
            video: "必须上传一张视频",
          },
          TIPS: {},
        },
        EVIDENCES_FORM: {
          HEADING_TITLE: "请设置相关的证明图片",
          LABEL: {
            image: "证明图片"
          },
          PLACEHOLDER: {
            image: "请上传证明图片，最多可以上传12张图片"
          },
          FEEDBACK: {
            image: "必须上传证明图片"
          },
          TIPS: {},
        },
        PERSONAL_IMAGE_FORM: {
          HEADING_TITLE: "请设置受助人个人图片",
          LABEL: {
            image: "人个人图片"
          },
          PLACEHOLDER: {
            image: "请上传个人图片，最多能上传8张图片"
          },
          FEEDBACK: {
            image: "至少需要上传一张图片"
          },
          TIPS: {},
        },
        PUBLIC_INFORMATION_FORM: {
          HEADING_TITLE: "请设置受助人家庭情况",
          LABEL: {
            house_id: "房产情况",
            car_id: "车产情况",
            job_id: "工作情况",
            income: "年收入",
            source_income_id: "收入来源",
            debt: "债务情况",
            medical_expense: "治疗已花费用",
            amount_raised: "筹款金额",
          },
          PLACEHOLDER: {
            house_id: "请选择房产情况",
            car_id: "请选择车产情况",
            job_id: "请选择工作情况",
            income: "请选择年收入",
            source_income_id: "请选择收入来源",
            debt: "请选择债务情况",
            medical_expense: "请选择到目前为止所花治疗费用",
            amount_raised: "根据病情请选择本次筹款金额",
          },
          FEEDBACK: {
            house_id: "房产情况为必选项",
            car_id: "车产情况为必选项",
            job_id: "工作情况为必选项",
            income: "年收入为必选项",
            source_income_id: "收入来源为必选项",
            debt: "债务情况为必选项",
            medical_expense: "治疗所花费用为必选项",
            amount_raised: "筹款金额为必选项",
          },
          TIPS: {},
        },
        MANAGER_FORM: {
          HEADING_TITLE: "请设置相关机构信息",
          BUTTON: {
            CHOOSE: "点击选择"
          },
          LABEL: {
            regulator_id: "发起机构",
            actuator_id: "执行机构",
            project_leader_id: "项目负责人",
            donation_manager_id: "善款管理机构",
            merchant_id: "商户平台",
            record_number: "项目备案号",
          },
          PLACEHOLDER: {
            regulator_id: "请选择发起机构",
            actuator_id: "请选择执行机构",
            project_leader_id: "请选择项目负责人",
            donation_manager_id: "请选择善款管理机构",
            merchant_id: "请选择支付使用的商户平台",
            record_number: "请输入项目备案号",
          },
          FEEDBACK: {
            regulator_id: "发起机构为必选项",
            actuator_id: "执行机构为必选项",
            project_leader_id: "项目负责人为必选项",
            donation_manager_id: "善款管理机构为必选项",
            merchant_id: "支付使用的商户平台为必选项",
            record_number: "必须输入项目备案号",
          },
          TIPS: {},
        },
        CONFIG_FORM: {
          HEADING_TITLE: "请设置项目配置",
          LABEL: {
            part: "一人份金额",
            operator: "量词",
            gift: "物资名称",
            options: {
              total_fee: "帮助金额",
              label_text_ug: "选项维吾尔文标题",
              label_text_cn: "选项中文标题",
              addButton: "添加"
            },
          },
          PLACEHOLDER: {
            part: "请输入一人份的捐助金额",
            operator: "请输入物资量词",
            gift: "请输入物资名称",
            options: {
              total_fee: "请输入帮助金额",
              label_text: "请输入选项标题",
            },
          },
          FEEDBACK: {
            part: "金额不能为空",
            operator: "量词不能为空",
            gift: "物资名称不能为空",
          },
          DIVIDER: {
            options: "金额选项"
          },
          TIPS: {},
        },
        REVIEW: {
          PATIENT: {
            title: "受助人个人信息",
            id_card: "身份证号码",
            phone: "联系电话",
            gender: "性别",
            name: "姓名",
            address: "户籍地"
          },
          HOSPITAL: {
            title: "入住医院信息",
            patient_id: "住院号",
            hospital_id: "医院",
            department_id: "科室",
            address: "病床号",
          },
          BANNER: {
            title: "幻灯片"
          },
          EVIDENCE_IMAGE: {
            title: "证明图片"
          },
          PERSONAL_IMAGE: {
            title: "受助人个人图片"
          },
          PUBLIC_INFORMATION: {
            title: "受助人家庭情况",
            house_id: "房产情况",
            car_id: "车产情况",
            job_id: "工作情况",
            income: "年收入",
            source_income_id: "收入来源",
            debt: "债务情况",
            medical_expense: "治疗仪花费用",
            amount_raised: "筹款金额",
            symbol: "{price} 元"
          },
          MANAGER: {
            title: "机构相关信息",
            regulator_id: "发起机构",
            actuator_id: "执行机构",
            project_leader_id: "项目负责人",
            donation_manager_id: "善款管理机构",
            merchant_id: "支付使用的商户平台",
            record_number: "项目备案号",
          },
          EXTERNAL: {
            title: '外部链接相关的信息',
            raise_amount: '筹款金额',
            url: '外部筹款信息链接地址'
          }
        },
        COPY_FORM: {
          HEADING_TITLE: "",
          LABEL: {},
          PLACEHOLDER: {},
          FEEDBACK: {},
          TIPS: {},
        },
      },
      ARTICLE_NOTIFICATION_FORM: {
        FORM: {
          STEP: {
            UG: {
              TITLE: "维吾尔文内容",
              DESC: "通知维吾尔文内容"
            },
            CN: {
              TITLE: "中文内容",
              DESC: "通知中文内容"
            }
          },
          LABEL: {
            title: "标题",
            describe: "简介"
          },
          PLACEHOLDER: {
            title: "请输入标题",
            describe: "请输入通知简介"
          },
          FEEDBACK: {
            title: "标题为必填项",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          },
        },
      },
      MERCHANT_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "次要信息",
              DESC: "商户号次要信息"
            },
            TWO: {
              TITLE: "基本信息",
              DESC: "商户号基本信息"
            }
          },
          LABEL: {
            mch_id: "商户号",
            key: "Api秘钥",
            cert_path: "Api证书",
            key_path: "Api证书私钥",
            title_ug: "商户号维吾尔文名称",
            title_cn: "商户号中文名称",
            describe_ug: "维吾尔文简介",
            describe_cn: "中文简介",
            cert_uploader: "选择文件",
            generate: "生成",
          },
          PLACEHOLDER: {
            mch_id: "请输入商户号",
            key: "请输入或者生成Api秘钥",
            cert_path: "请上传Api证书",
            key_path: "请上传Api证书私钥",
            title_ug: "请输入商户号维吾尔文名称",
            title_cn: "请输入商户号中文名称",
            describe_ug: "请输入维吾尔文简介",
            describe_cn: "请输入中文简介",
          },
          FEEDBACK: {
            mch_id: "商户号是必填项",
            key: "Api秘钥是必填项",
            cert_path: "必须上传Api证书",
            key_path: "必须上传Api证书私钥",
            title_ug: "商户号维吾尔文名称是必填项",
            title_cn: "商户号中文名称是必填项",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          },
        },
      },
      MEDICAL_PAYMENT_HISTORY_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "医院打款记录维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "医院打款记录中文内容"
            }
          },
          LABEL: {
            describe: "打款记录简介",
            amount: "打款金额",
            service_fee: "服务费金额",
            ticket_image: "银行打款对账单图片",
            payment_at: "打款时间"
          },
          PLACEHOLDER: {
            describe_ug: "请输入打款记录简介维吾尔文简介",
            describe_cn: "请输入打款记录简介中文简介",
            amount: "请输入打款金额",
            service_fee: "请输入服务费金额",
            ticket_image: "请上传银行打款对账单图片",
            payment_at: "请选择打款时间",
          },
          FEEDBACK: {
            describe: "打款记录简介为必填项",
            amount: "打款金额为必填项",
            ticket_image: "银行打款对账单图片必须上传",
            payment_at: "请选择打款时间",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        }
      },
      PROGRESS_HISTORY_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "维吾尔文内容",
              DESC: "进展记录维吾尔文内容"
            },
            TWO: {
              TITLE: "中文文内容",
              DESC: "进展记录中文内容"
            }
          },
          LABEL: {
            title: "标题",
            describe: "简介",
            content: "进展记录详情内容",
            amount: "打款金额",
            total: "受助人数量",
            cover_image: "相册图片",
            ticket_image: "打款对账单图片",
          },
          PLACEHOLDER: {
            title_ug: "请输入维吾尔文标题",
            title_cn: "请输入中文标题",
            describe_ug: "请输入维吾尔文简介内容",
            describe_cn: "请输入中文简介内容",
            content_ug: "请输入进展记录维吾尔文详情内容",
            content_cn: "请输入进展记录中文详情内容",
            amount: "请输入打款金额",
            total: "请输入本次受助人数量",
            cover_image: "请上传相册图片",
            ticket_image: "请上传打款对账单图片",
          },
          FEEDBACK: {
            title: "标题为必填项",
            describe: "简介内容为必填项",
            content: "进展记录详情内容为必填项",
            amount: "打款金额为必填项",
            total: "本次受助人数量为必填项",
            cover_image: "相册图片必须上传",
            ticket_image: "打款对账单图片必须上传",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        }
      },
      NAVIGATION_FORM: {
        FORM: {
          LABEL: {
            VIEW_TYPE: {
              BANNER: "幻灯片",
              LANDSCAPE: "浮窗"
            },
            TARGET_OPTION: {
              article: "内容",
              mp: "小程序",
              external_link: "外链",
            },
            IMAGE_DESC: {
              banner: "幻灯片我们推荐上传横向照片，宽度最好控制在750像素。",
              landscape: "浮窗我们推荐上传纵向图片",
              navigation: "菜单图标我们推荐上传1:1的图片"
            },
            BELONGS: {
              any: "所有页面",
              home: "首页",
              show: "详情页",
              member: "用户中心",
            },
            FEEDBACK_LABEL: {
              str_1: "点击右边的按钮选择%s",
              str_2: "请输入%s",
              article: "内容",
              mp: "小程序页面路径",
              external_link: "网址"
            },
            TARGET_LABEL: {
              article: "请选择内容",
              mp: "小程序页面路径",
              external_link: "网址"
            },
            UPLOADER_LABEL: {
              banner: "幻灯片图片",
              navigation: "菜单图标",
              label: "选择"
            },
            OPTION_DESC: {
              str: "当点击图片时会跳转到%s",
              article: "已选内容详情页",
              mp: "指定的小程序页面",
              external_link: "外链页面",
            },
            VIEW_TYPE_TITLE: {
              banner: "幻灯片",
              navigation: "菜单",
              landscape: "浮窗",
            },
            TO_LABEL: {
              article: "选择内容",
              mp: "小程序页面路径",
              external_link: "网址",
            },
            BUTTON: {
              choose: "选择"
            },
            target: "目标",
            view_type: "类型",
            background: "标题颜色",
            title: "标题",
            belongs_t: "广告所属页面",
            article_choose_modal_title: "请选择内容",
          },
          PLACEHOLDER: {
            target_option_mp: "请输入小程序Appid",
            background: "请选择标题颜色，也可以留空",
            title: "请输入标题"
          },
          FEEDBACK: {
            target_option_mp: "小程序Appid是必填项",
            target: "请选择目标",
            title: "请输入标题"
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          },
        },
      },
      ADMIN_FORM: {
        FORM: {
          STEP: {
            ONE: {
              TITLE: "基本信息",
              DESC: "管理员基本信息"
            },
            TWO: {
              TITLE: "机构信息",
              DESC: "管理员所属机构信息"
            }
          },
          LABEL: {
            name: "账号",
            phone: "联系电话",
            password: "密码",
            password_confirmation: "密码",
            regulators: "机构",
            actuators: "执行机构",
            merchants: "商户平台",
            project_leaders: "项目负责人",
            nickname: "昵称",
            generate: "生成",
            super: "是否为超级管理员？",
            sub_account: "是否为子账号？",
            multiSelector: {
              placeholder: "请选择所属机构",
              selectLabel: "",
              selectedLabel: "已选",
              deselectLabel: "",
            },
            singleSelector: {
              placeholder: "请选择所属执行机构",
              selectLabel: "",
              selectedLabel: "已选",
              deselectLabel: "",
            },
            merchantSelector: {
              placeholder: "请选择商户号",
              selectLabel: "",
              selectedLabel: "已选",
              deselectLabel: "",
            },
            projectLeaderSelector: {
              placeholder: "请选择项目负责人",
              selectLabel: "",
              selectedLabel: "已选",
              deselectLabel: "",
            }
          },
          PLACEHOLDER: {
            name: "请输入管理员账号",
            phone: "请输入联系电话",
            password: "请输入初始密码",
            password_confirmation: "请再次输入初始密码",
            regulators: "请选择所属机构",
            actuators: "请选择所属执行机构",
            merchants: "请选择商户号",
            project_leaders: "请选择项目负责人",
            nickname: "请输入昵称"
          },
          FEEDBACK:{
            name: "管理员账号不能为空",
            phone: "联系电话不能为空",
            password: "初始密码不能为空",
            password_confirmation: "两次输入的密码需一致",
            regulators: "请选择所属机构",
            actuators: "请选择所属机执行机构构",
            merchants: "请选择商户号",
            project_leaders: "请选择项目负责人",
          },
          TIPS: {
            EDIT_SUCCESS: "编辑成功！",
            VALIDATION_TIPS: "请留意必填项！呈红色的都是必填项。"
          }
        }
      },
    },

    ITEMS: {
      ARTICLE: {
        RAISE_ITEM: {
          ACTIONS: {
            TEXT: "操作",
            EDIT: "编辑",
            DESCRIPTION: "内容详情",
            STATUS: "募捐状态",
            STATISTICS: "统计",
            ALLOCATION: "分配",
            COPY_LINK: "复制链接",
            COPY_H5_LINK: "复制H5链接",
            ALL_NOTIFICATION: "通知管理",
            ADD_NOTIFICATION: "新增通知",
            COMMENT: "评论管理",
            ALL_WITNESS: "证人证词管理",
            PAYMENT_HISTORY: "医院打款记录管理",
          },
          INFO: {
            DESC: "暂时没有简介，推荐加上简介内容",
            PENDING_REVIEW: "有新的编辑内容",
            PENDING_AUDIT: "未审核",
            EXTERNAL: "这是一条外部筹款信息"
          },
          FOOTER: {
            RAISE: "目标金额",
            TOTAL: "已筹金额",
            CASHING: "已提现",
            COMMENTS_COUNT: "条评论",
            NO_COMMENTS: "还没有评论",
            COMMENT_MANAGER: "管理评论",
            DONORS_COUNT: "名捐款者",
          },
          LABEL: {
            NO_TITLE: '暂时没有设置标题',
            NO_FULL_NAME: '未命名',
          },
        },
        PROJECT_ITEM: {
          ACTIONS: {
            TEXT: "操作",
            DESCRIPTION: "内容详情",
            STATUS: "项目募捐状态",
            STATISTICS: "统计",
            ALLOCATION: "分配",
            COPY_LINK: "复制链接",
            COPY_H5_LINK: "复制H5链接",
            ALL_NOTIFICATION: "通知管理",
            ADD_NOTIFICATION: "新增通知",
            COMMENT: "评论管理",
            PROGRESS_HISTORY: "进展记录管理",
          },
          INFO: {
            DESC: "暂时没有简介，推荐加上简介内容",
            PENDING_REVIEW: "有新的编辑内容",
            PENDING_AUDIT: "未审核",
            TITLE: "这是一条项目"
          },
          FOOTER: {
            RAISE: "目标金额",
            TOTAL: "已筹金额",
            GROUP: "分组",
            NO_GROUP: "还没有分组",
            GROUP_LIMIT_SYMBOL: "个组",
            COMMENTS_COUNT: "条评论",
            NO_COMMENTS: "还没有评论",
            COMMENT_MANAGER: "管理评论",
            DONORS_COUNT: "名捐款者",
          },
          LABEL: {
            NO_TITLE: '暂时没有设置标题',
            NO_FULL_NAME: '未命名',
          },
        },
        VERSION_ITEM: {
          ACTIONS: {
            EDIT: "标记",
            COPY: "创建副本并编辑",
            ONLINE: "上线",
          },
          INFO: {
            DESC: "暂时没有简介，推荐加上简介内容",
            CURRENT_ONLINE: "正在上线中",
            ARCHIVED: "已归档",
            PENDING_ONLINE: "等待审核上线",
          },
          LABEL: {
            NO_TITLE: '暂时没有设置标题',
            NO_FULL_NAME: '未命名',
            FULL_NAME: "姓名",
            PHONE: "联系电话",
            ID_CARD:"身份证号码",
            PATIENT_ADDRESS:"户籍地",
            HOSPITAL: "医院名称",
            DEPARTMENT: "科室名称",
            HOSPITAL_ADDRESS: "医院地址",
            RAISE_ADDRESS: "病床",
            PATIENT_ID: "住院号",
            RAISE: "目标金额",
            CREATED_AT: "创建时间",
          },
          TIPS: {
            ONLINE: "是否确定上线此版本的信息？",
            COPY: "需要对线上内容进行创建副本编辑吗？（线上内容不受编辑影响）",
          }
        }
      },
      USER: {
        ACCOUNT: {
          LABEL: {
            created_at: "注册时间",
            no_data: "暂时没有记录",
            copy_openid_success: "成功复制了当前社会化身份信息下的openid"
          }
        }
      },
      WIDGETS: {
        ARTICLE_STATISTICS: {
          TITLE: "总统计数据",
          LABEL: {
            total_fee_desc: "到现在为止筹款金额",
            $: "筹款金额"
          }
        }
      }
    },

    MODALS: {
      USER: {
        SWITCH_LOCK: {
          TITLE: "设置黑名单",
          BUTTON: {
            SUBMIT: "提交",
          },
          LABEL: {
            OUT_REASON_LABEL: "取消原因",
            OUT_REASON_TIPS: "正在准备取消黑名单，请填写取消原因，也可以留空。",
            IN_REASON_LABEL: "原因",
            IN_REASON_TIPS: "请填写加入黑名单原因。",
          }
        },
        ACCOUNT: {
          title: "社会化身份",
          BUTTON: {
            confirm: "知道了",
          },
          LABEL: {
            DESC: {
              miniprogram: "微信小程序身份",
              officialAccount: "公众号H5页面身份",
              app: "App身份"
            }
          }
        }
      },
      RAISE: {
        STATUS: {
          TITLE: "请选择募捐状态",
          OPTIONS: {
            ONE: {
              title: "正常",
              describe: "默认情况可以正常进行募捐",
            },
            TWO: {
              title: "募捐完毕",
              describe: "当本求助信所需的额度已经募捐完毕时，可以选择本选项进行停止募捐",
            },
            THREE: {
              title: "终止",
              describe: "募捐活动执行当中出现任何意外或者不可阻挡的事故时可以选择本选项进行终止募捐活动",
            },
          },
          LABEL: {
            articleModalTitle: "选择要跳转的内容",
            reason: "原因",
            target_id: "跳转内容"
          },
          PLACEHOLDER: {
            reason_ug: "请维吾尔文填写停止原因，或者留空",
            reason_cn: "请中文填写停止原因，或者留空",
            target_id: "如果需要跳转到其他的求助信请选择要跳转的内容"
          },
          FEEDBACK: {
            reason_ug: "已经填写中文原因的需要填写维吾尔文原因",
            reason_cn: "已经填写维吾尔文原因的需要填写中文原因",
          },
          BUTTON: {
            CHOOSE: "选择",
            CLEAR: "清空"
          }
        }
      },
      SUBJECT_CREATE_TYPE: {
        BUTTONS: {
          RAISE: "新增求助信息",
          PROJECT: "新增项目信息",
          EXTERNAL: "新增外链求助信息",
        },
        TITLE: "选择要新增的信息类目"
      }
    },

    MINIPROGRAM_SCAN_APP_CODE: {
      LABEL: {
        tips: {
          init: "使用微信扫码进行授权",
          scanned: "请按照提醒操作",
          completed: "授权成功",
          refresh: "小程序码已过期",
          unidentified: "无法确认身份信息，请重试",
        }
      },
      BUTTON: {
        refresh: "刷新",
        redo: "切换",
      }
    },

    THEME: {
      TOPBAR: {
        USER: {
          title: "个人资料",
          action: {
            edit: "资料编辑",
            exit: "退出"
          }
        }
      }
    },

    IMAGE_UPLOADER: {
      LABEL: {
        BUTTON: "选择图片",
        InvalidField: "图片格式有误",
        FileWaitingForSize: "正在检测图片大小",
        FileSizeNotAvailable: "图片可能已被删除",
        FileLoading: "加载中...",
        FileLoadError: "加载失败",
        FileProcessing: "正在上传...",
        FileProcessingComplete: "上传完毕",
        FileProcessingAborted: "上传被终止",
        FileProcessingError: "上传失败",
        FileProcessingRevertError: "失败",
        FileRemoveError: "删除失败",
        TapToCancel: "轻触取消",
        TapToRetry: "轻触重试",
        TapToUndo: "轻触重试",
        ButtonRemoveItem: "删除",
        ButtonAbortItemLoad: "取消",
        ButtonRetryItemLoad: "重试",
        ButtonAbortItemProcessing: "取消",
        ButtonRetryItemProcessing: "重试",
        ButtonProcessItem: "上传",
        ValidateSizeLabelImageSizeTooSmall: "图片尺寸太小",
        ValidateSizeLabelImageSizeTooBig: "图片尺寸太大",
        ValidateSizeLabelExpectedMinSize: "图片尺寸需小于指定的尺寸",
        ValidateSizeLabelExpectedMaxSize: "图片尺寸需大于指定的尺寸",
        minWidthTips: "这里要求的宽度指的是最小的宽度，按照实际情况请控制好宽度",
        minHeightTips: "这里要求的高度指的是最小的高度，按照实际情况请控制好高度",
        minWidth: "宽{width}像素",
        minHeight: "高{height}像素",
      }
    },
    SIMPLE_EDITOR: {
      TYPES: {
        text: "添加文字",
        image: "添加图片",
        video: "添加视频",
        voice: "添加声音",
      },
      LABEL: {
        text: "文字内容",
        image: "图片",
        video: "视频播放地址",
        voice: "声音播放地址",
      },
      PLACEHOLDER: {
        text: "请输入文字内容",
        video: "请输入视频播放地址",
        voice: "请输入声音播放地址",
      },
      DESCRIPTION: {
        image: "最多能上传5张图片",
      }
    },
    MULTI_UPLOADER: {
      LABEL: {
        RATE_LIMIT: "最多只能上传{limit}个文件"
      }
    },
  },

  SWEET_ALERT: {
    SUCCESS: {
      MESSAGE: "操作成功！",
      CONFIRM_TEXT: "确定"
    },
    WARNING: {
      MESSAGE: "请注意！",
      CONFIRM_TEXT: "确定"
    },
    ERROR: {
      MESSAGE: "发生了未知错误！",
      CONFIRM_TEXT: "确定"
    },
  },
  MSG_BOX: {
    TITLE: "确定操作码？",
    CONFIRM_TEXT: "确定",
    CANCEL_TEXT: "取消"
  }
};
