import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth.module";
import htmlClass from "./modules/htmlclass.module";
import config from "./modules/config.module";
import breadcrumbs from "./modules/breadcrumbs.module";
import navigation from "./modules/navigation.module";
import comment from  "./modules/comment.module";
import subscribeMessageStock from "./modules/subscribeMessage.stock.module";
import user from "./modules/user.module";
import setting from "./modules/setting.module";
import commonModule from "./modules/common/common.module";
import wechatNews from "./modules/common/wechat.news.module";
import domainToIP from "./modules/common/domain2ip.module";
import classify from "./modules/classify.module";
import admin from "./modules/admin.module";
import hospital from "./modules/hospital.module";
import hospitalDepartment from "./modules/hospitalDepartment.module";
import regulator from "./modules/regulator.module";
import actuator from "./modules/actuator.module";
import projectLeader from "./modules/projectLeader.module";
import article from "./modules/article.module";
import articleNotification from "./modules/article.notification.module";
import articleWitness from "./modules/article.witness.module";
import merchant from "./modules/merchant.module";
import articleMedicalPaymentHistory from "./modules/article.medical.payment.history.module";
import articleProgressHistory from "./modules/article.progress.history.module";
import miniprogramAuth from "./modules/miniprogram.auth.module";

Vue.use( Vuex );

const getters = {};

const store = new Vuex.Store( {
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    navigation,
    comment,
    subscribeMessageStock,
    user,
    setting,
    commonModule,
    wechatNews,
    domainToIP,
    classify,
    hospital,
    hospitalDepartment,
    regulator,
    actuator,
    projectLeader,
    article,
    articleNotification,
    articleWitness,
    merchant,
    articleMedicalPaymentHistory,
    articleProgressHistory,
    miniprogramAuth,
    admin,
  },
  getters
} );

export default store;
