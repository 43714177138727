<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// @import "assets/css/style.vue.rtl";

// Demo skins (demo1 only)
@import "assets/sass/global/integration/frameworks/vue/skins";
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/modules/config.module";
import { PURGE_AUTH, REFRESH_TOKEN } from "@/store/modules/auth.module";
export default {
  name: "VueBootStrap",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  onIdle(){
    this.handleIdle();
  },
  onActive(){},
  data(){
    return {
      idle: false
    }
  },
  methods: {
    handleIdle(){
      let {meta} = this.$route;
      if ( meta.requiresGuest || this.idle ) return;
      let message = `
        ئۇزۇن ۋاقىت مەشغۇلات قىلماي تۇرۇپ قالدىڭىز، بىخەتەرلىك ئۈچۈن ھېساباتىڭىز چىكىنىپ كەتكەن بولىشى مۇمكىن، يېڭىلاش كونۇپكىسىنى بېسىپ سىناپ بېقىڭ.
      `;
      this.$bvModal.msgBoxConfirm(message, {
        title: 'سىز بامۇ يا ؟',
        okVariant: 'danger',
        okTitle: 'يېڭىلاش',
        cancelTitle: 'چىكىنىش',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
      .then(response=>{
        if ( response ){
          this.handleRefresh();
        }else {
          this.handlePurge();
        }
      });
      this.idle = true;
    },
    handleRefresh(){
      this.$store.dispatch(REFRESH_TOKEN);
      this.idle = false;
    },
    handlePurge(){
      this.$store.commit(PURGE_AUTH);
      this.idle = false;
      this.$router.push({name: 'login'});
    }
  }
};
</script>
